import React, { useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { TipoAjuste } from './interfaces';
import { Button, Form, Input, SingleSelect } from '../../../../_core/_components';

type ModalProps = {
  onClose: () => void;
  onSubmit: (values: any) => void;
  values?: TipoAjuste;
};

const DEFAUL_BOOLEAN_OPTIONS = [
  { value: 'PERDAO', label: 'Perdão' },
  { value: 'PARCELAMENTO', label: 'Parcelamento' },
];

const Modal: React.FC<ModalProps> = ({ onClose, onSubmit, values }) => {
  const [id] = useState(values?.id ?? '');
  const [adjustment, setadjustmentment] = useState(values?.adjustment ?? '');
  const [type, setType] = useState(values?.type ?? 'PERDAO');

  const handleSubmit = () => onSubmit({ id, adjustment, type });

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          {values ? 'Editar' : 'Criar'} Tipo de Ajuste
        </span>
        <Grid>
          <Form
            items={[
              <Input label="Tipo de ajuste" placeholder="Tipo de ajuste" state={[adjustment, setadjustmentment]} />,
              <SingleSelect
                state={[DEFAUL_BOOLEAN_OPTIONS.find(e => e.value === type), option => setType(option.value as string)]}
                options={DEFAUL_BOOLEAN_OPTIONS}
                placeholder="tipo"
              />,
            ]}
            submitButton={<Button text="Salvar" onClick={handleSubmit} />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
