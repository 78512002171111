import React from 'react';
import ReactSelect, { CSSObjectWithLabel, SingleValue } from 'react-select';
import { Option } from './types';

interface Props {
  state: [Option | undefined, (option: Option) => void];
  placeholder?: string;
  options: Option[];
  disabled?: boolean;
  controlStyle?: CSSObjectWithLabel;
  containerStyles?: CSSObjectWithLabel;
}

export function SingleSelect({
  state: [value, setValue],
  placeholder = 'Selecione',
  options,
  disabled = false,
  controlStyle = {},
  containerStyles = {},
}: Props): JSX.Element {
  const onChange = (option: SingleValue<Option>) => {
    if (option) {
      setValue(option);
    }
  };

  return (
    <ReactSelect
      value={value}
      onChange={onChange}
      hideSelectedOptions={false}
      isMulti={false}
      isSearchable
      isClearable
      options={options}
      placeholder={placeholder}
      menuPortalTarget={document.body}
      styles={{
        container: base => ({
          ...base,
          ...containerStyles,
        }),
        control: (base, state) => ({
          ...base,
          height: 40,
          minHeight: 40,
          borderRadius: 30,
          boxShadow: state.isFocused ? '0 0 0 1px #31c6d3' : 'none',
          borderColor: state.isFocused ? '#31c6d3' : 'hsl(0, 0%, 80%)',
          '&:hover': {
            borderColor: state.isFocused ? '' : 'black',
          },
          ...controlStyle,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        valueContainer: base => ({ ...base, flexWrap: 'nowrap' }),
      }}
      noOptionsMessage={() => ''}
      isDisabled={disabled}
    />
  );
}
