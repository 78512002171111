import styled from 'styled-components';
import MaskedInput from 'react-maskedinput';
import { Mark } from './custom-checkbox/styles';

export const DialogContent = styled.div`
  input {
    background-color: #fff;
  }
`;

export const Title = styled.div`
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
`;

export const Header = styled.div`
  padding: 1.9rem 2.66rem;
  background-color: #00959c;

  > svg {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 1rem;
    cursor: pointer;
  }
`;

export const MainContent = styled.div`
  padding: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'documentacao status'
    'fases fases'
    'actions actions';
  grid-gap: 2.12rem;
`;

export const DocumentacaoSection = styled.div`
  grid-area: documentacao;
`;

export const StatusSection = styled.div`
  grid-area: status;
`;

export const FasesSection = styled.div`
  grid-area: fases;
`;

export const ActionsSection = styled.div`
  grid-area: actions;

  button {
    background-color: #00959c;
    border-radius: 10px;
  }
`;

export const Section = styled.div`
  border: 1px solid #00959c;
  position: relative;
  padding: 2.6rem 2rem;
  border-radius: 0.4rem;
  height: 100%;
  background-color: #fbfbfb;
`;
export const SectionTitle = styled.h3`
  background-color: #00959c;
  font-size: 1rem;
  color: #fff;
  position: absolute;
  padding: 0.27rem 1.2rem;
  top: -1rem;
  left: 1rem;
  border-radius: 0.4rem;
`;

export const MaskInput = styled(MaskedInput)`
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #7e93954d;
  width: 100%;
  padding: 0.3rem 0.8rem;
  font-size: 0.7rem;

  ::placeholder {
    color: #7e9395;
  }

  :disabled {
    cursor: not-allowed;
  }
`;

export const LoadingArea = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 9999;
`;

export const LeftContent = styled.div`
  flex: 50%;
  border-right: 1px solid #7e9395;
  padding: 0rem 2rem;
`;
export const FieldRow = styled.div`
  display: flex;
  margin: 1rem 0;

  & > div {
    flex: 1;
  }

  & > div:last-child {
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1.5rem;
  }
`;

export const FieldRowChamados = styled.div`
  display: flex;
  margin: 1rem 0;

  & > div {
    flex: 34%;
  }

  & > div:nth-child(2) {
    flex: 40%;

    & > div {
      min-width: unset;
    }
  }
`;

export const RightContent = styled.div`
  flex: 50%;
  padding: 0rem 2rem;
  margin-top: 10rem;
`;
export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`;

export const FieldArea = styled.div`
  margin: 0 0.5rem;
`;

export const SelectContent = styled.div`
  display: flex;
  gap: 0 1rem;
`;

export const SelectArea = styled.div`
  display: flex;
  justify-content: center;
`;

export const FieldSelect = styled.div`
  .select {
    margin: 5px 20px 0px 20px;
    max-width: 350px;
  }
`;

export const FieldAreaDoc = styled.div`
  max-width: 160px;
  color: #7e9395;
  font-weight: 600;
`;

export const FieldHeader = styled.div`
  color: #7e9395;

  font-weight: 600;
`;

export const DocRow = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const FasesTable = styled.table`
  width: 100%;

  td {
    padding: 0.1rem 1rem;
    min-width: 7rem;
  }
`;
export const TableHead = styled.thead`
  color: #00959c;
  font-weight: bold;
`;
export const TableBody = styled.tbody`
  ${Mark} {
    width: 1rem;
    height: 1rem;
  }

  .MuiInputBase-root {
    border-radius: 10px !important;

    input {
      padding: 0.3rem 0.8rem;
      font-size: 0.8rem;
    }
  }
`;

export const selectContainerStyles = {
  width: '13rem',
};

export const selectControlStyles = {
  fontSize: '0.8rem',
  width: '100%',
};
