import React, { useState, useEffect, useMemo } from 'react';
import GridOnIcon from '@material-ui/icons/GridOn';
import TableChartIcon from '@material-ui/icons/TableChart';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { trackJudgmentActions, trackJudgmentActionsTotals } from './services';
import Detalhamento from './detalhamento';
import FaseUpdateModal from './fase-update';
import CancelamentoModal from './cancelamento';
import DocumentationIcon from '../../../assets/documentation.png';
import VentoIcon from '../../../assets/vento.png';
import ListIcon from '../../../assets/list.png';
import JudgeIcon from '../../../assets/judge.png';
import GuiaIcon from '../../../assets/guia.png';
import BillIcon from '../../../assets/bill.png';
import {
  Container,
  LineTotalizador,
  AcoesPorFase,
  Acoes,
  TableWrapper,
  ActionsRow,
  ViewChanger,
  getGridIconStyle,
  getTableIconStyle,
  CardWrapper,
  Card,
  CardCol,
  CardLine,
  CardLabel,
  CardInfo,
  CardSeparator,
  CardValueInfo,
  Acao,
  FaseIcon,
  AcaoText,
  AcaoPercent,
  ContentWrapper,
} from './styles';
import { AjuizamentoResponse, AcompanhamentoAjuizamentoItem, AjuizamentoFases } from './types';
import { TableContainer, CustTable, TableRow, RowItem, HeaderItem, AcompanharButton, Pagination } from './table-styles';
import { toReal, truncateString } from '../../../_core/services/formaters';
import api from '../../../_core/api';
import { handleErrors, warning } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';
import { Button, Checkbox, Form, Input, Option, Select, SingleSelect } from '../../../_core/_components';

const FASE_ATUAL: Option[] = [
  { value: 0, label: 'TODOS' },
  { value: 1, label: 'DOCUMENTAÇÃO' },
  { value: 2, label: 'GUIA DE PAGAMENTO' },
  { value: 3, label: 'PO' },
  { value: 4, label: 'CHAMADO PARA PAGAMENTO' },
  { value: 5, label: 'COMPROVANTES DE PAGAMENTO' },
  { value: 6, label: 'CHAMADO PARA AJUIZAMENTO' },
  { value: 7, label: 'AÇÃO AJUIZADA' },
];

export const AcompanharAjuizamento: React.FC = () => {
  const { response: responseShoppings, isLoading: isLoadingShoppings } = useCache({
    key: 'getShoppings',
    fn: requests.get('getShoppings'),
  });
  const { response: responseOrigem, isLoading: isLoadingOrigem } = useCache({
    key: 'getOrigem',
    fn: requests.get('getOrigem'),
  });
  const { response: responseGruposCobranca, isLoading: isLoadingGruposCobranca } = useCache({
    key: 'getAjuizamentoGruposCobranca',
    fn: requests.get('getAjuizamentoGruposCobranca'),
  });
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [acoesPendentes, setAcoesPendentes] = useState(true);
  const [acoesAjuizadas, setAcoesAjuizadas] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDetails, setLoadingDetails] = useState(false);
  const [tipoCancelamento, setTipoCancelamento] = useState('desistencia');
  const [ajuizamentos, setAjuizamentos] = useState<AjuizamentoResponse | null>(null);
  const [ajuizamentoFases, setAjuizamentoFases] = useState<AjuizamentoFases | null>(null);
  const [isDetalhamentoOpen, setDetalhamentoOpen] = useState(false);
  const [isCancelamentoOpen, setCancelamentoOpen] = useState(false);
  const [isUpdateFaseOpen, setUpdateFaseOpen] = useState(false);
  const [ajuizamentoADetalhar, setajuizamentoADetalhar] = useState<AcompanhamentoAjuizamentoItem | undefined>();
  const [page, setPage] = React.useState(1);
  const [showPagination, setShowPagination] = useState(true);
  const [nbPage, setNbPage] = useState();

  /* Filtros Selecionados */
  const [selectedShopping, setselectedShopping] = useState<Option[]>([]);
  const [origem, setOrigem] = useState<Option>();
  const [billingGroup, setBillingGroup] = useState<Option>();
  const [sourceList, setSourceList] = useState<Option[]>([]);
  const [billingGroupsList, setBillingGroupsList] = useState<Option[]>([]);
  const [contractNumber, setContractNumber] = useState<string>('');
  const [idFase, setIdFase] = useState<number>(0);
  const [numeroChamado, setNumeroChamado] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [luc, setLuc] = useState<string>('');
  const [mesAnoCicloAjuizamento, setMesAnoCicloAjuizamento] = useState<Option>({
    value: 0,
    label: 'TODOS',
  });
  const [despejo, setDespejo] = useState(true);
  const [execucao, setExecucao] = useState(true);
  const [denunciaDespejo, setDenunciaDespejo] = useState(true);
  const [denunciaExecucao, setDenunciaExecucao] = useState(true);
  const [currentView, setCurrentView] = useState<'table' | 'card'>('card');

  useEffect(() => {
    setShowPagination(true);
  }, []);

  useEffect(() => {
    if (responseShoppings !== undefined) {
      const listShopping: Option[] = [];
      responseShoppings.data.map((item: any) => {
        listShopping.push({
          value: item.shoppingId,
          label: item.shoppingName,
        });
        return null;
      });
      listShopping.sort((item1: Option, item2: Option) => comparar(item1, item2));
      listShopping.unshift({
        value: 0,
        label: 'TODOS',
      });
      setShoppings(listShopping);
      setselectedShopping([listShopping[0]]);
    }
  }, [responseShoppings]);

  useEffect(() => {
    if (responseOrigem !== undefined) {
      const tmpSources: Option[] = [];
      responseOrigem.data.forEach((item: any) => {
        tmpSources.push({ label: item.sourceName, value: item.code });
      });
      tmpSources.unshift({
        value: 0,
        label: 'TODOS',
      });

      setSourceList(tmpSources);
    }
  }, [responseOrigem]);

  useEffect(() => {
    if (responseGruposCobranca !== undefined) {
      const tmpBillingGroups: Option[] = [];
      responseGruposCobranca.data.forEach((item: any) => {
        if (item.federationGroupName != null) {
          tmpBillingGroups.push({ label: item.federationGroupName, value: item.federationGroupId });
        }
      });
      tmpBillingGroups.unshift({
        value: 0,
        label: 'TODOS',
      });
      setBillingGroupsList(tmpBillingGroups);
    }
  }, [responseGruposCobranca]);

  const last12Months = () => {
    const datesArr = [];

    datesArr.push({
      value: 0,
      label: 'TODOS',
    });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 14; i++) {
      datesArr.push({
        value: i + 1,
        label: moment().subtract(i, 'month').format('MM/YYYY'),
      });
    }

    return datesArr;
  };

  const comparar = (item1: Option, item2: Option) => {
    if (item1.label > item2.label) return 1;
    if (item1.label < item2.label) return -1;
    return 0;
  };

  const handleChangePagination = (event: any, value: any) => {
    setPage(value);
    setLoading(true);
    pesquisar(true, value);
  };

  function montarShoppingName() {
    if (selectedShopping.length > 0 && selectedShopping[0].value !== 0) {
      let shoppingName: any = '';
      selectedShopping.forEach((shopping, index) => {
        if (index !== 0) {
          shoppingName = `${shoppingName},${shopping.label}`;
        } else {
          shoppingName += shopping.label;
        }
      });
      shoppingName = shoppingName.replaceAll(' ', '%20');
      return shoppingName;
    }
    return null;
  }

  const pesquisar = async (update = false, pgnb: number) => {
    try {
      setLoading(true);
      const newPage = pgnb || page;
      setPage(newPage);
      const tiposAcao = [];
      if (despejo) tiposAcao.push(1);
      if (execucao) tiposAcao.push(2);
      if (denunciaDespejo) tiposAcao.push(3);
      if (denunciaExecucao) tiposAcao.push(4);

      const params = {
        shoppingName: montarShoppingName(),
        source: (origem?.value !== 0 && origem?.value) || null,
        idsGruposCobranca: (billingGroup?.value !== 0 && billingGroup?.value) || null,
        contractNumber: contractNumber || null,
        numeroChamado: numeroChamado || null,
        idFase: idFase !== 0 ? idFase : null,
        cpfCnpj: cpfCnpj || null,
        luc: luc || null,
        mesAnoCicloAjuizamento: mesAnoCicloAjuizamento?.label,
        tiposAcao: tiposAcao.join(',') || null,
        acoesAjuizadas,
        size: 10,
        acoesPendentes,
        page: newPage,
      };
      const params2 = {
        shoppingName: montarShoppingName(),
        source: (origem?.value !== 0 && origem?.value) || null,
        idsGruposCobranca: (billingGroup?.value !== 0 && billingGroup?.value) || null,
        contractNumber: contractNumber || null,
        numeroChamado: numeroChamado || null,
        idFase: idFase !== 0 ? idFase : null,
        cpfCnpj: cpfCnpj || null,
        luc: luc || null,
        tiposAcao: tiposAcao.join(',') || null,
        acoesAjuizadas,
        size: 10,
        acoesPendentes,
        page: newPage,
      };

      if (params?.mesAnoCicloAjuizamento !== 'TODOS') {
        const response = await trackJudgmentActions(params);
        ajuizamentoFasesDetalhes(params);
        setAjuizamentos(response.data);
        setNbPage(response.data.pageInfo.totalPages);
      } else {
        const response = await trackJudgmentActions(params2);
        ajuizamentoFasesDetalhes(params2);
        setAjuizamentos(response.data);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const ajuizamentoFasesDetalhes = async (params: any) => {
    setAjuizamentoFases(null);
    setLoadingDetails(true);
    try {
      const { data } = await trackJudgmentActionsTotals(params);
      setAjuizamentoFases(data);
    } catch (error) {
      handleErrors(error);
    }
    setLoadingDetails(false);
  };

  const handleAjuizamentoExport = async () => {
    const tiposAcao = [];
    if (despejo) tiposAcao.push(1);
    if (execucao) tiposAcao.push(2);
    if (denunciaDespejo) tiposAcao.push(3);
    if (denunciaExecucao) tiposAcao.push(4);

    const params = {
      shoppingName: montarShoppingName(),
      source: (origem?.value !== 0 && origem?.value) || null,
      idsGruposCobranca: (billingGroup?.value !== 0 && billingGroup?.value) || null,
      contractNumber: contractNumber || null,
      numeroChamado: numeroChamado || null,
      idFase: idFase !== 0 ? idFase : null,
      cpfCnpj: cpfCnpj || null,
      luc: luc || null,
      tiposAcao: tiposAcao.join(',') || null,
      acoesAjuizadas,
      acoesPendentes,
    };

    const response = await api.get(`/judgment/track-judgment-actions/xls`, { responseType: 'arraybuffer', params });
    downloadXls(response);
  };

  const detalhesAjuizamento = (acao: AcompanhamentoAjuizamentoItem) => {
    setajuizamentoADetalhar(acao);
    setDetalhamentoOpen(true);
  };

  const openUpdateFase = () => {
    setDetalhamentoOpen(false);
    setUpdateFaseOpen(true);
  };

  const cancelarAcao = (typeCancelamento: string) => {
    setTipoCancelamento(typeCancelamento);
    setDetalhamentoOpen(false);
    setCancelamentoOpen(true);
  };

  const closeUpdateModal = () => {
    setUpdateFaseOpen(false);
    pesquisar(true, NaN);
  };

  const handleCheck = (typeAction: string) => {
    if (typeAction === 'acoesPendentes') {
      if (acoesAjuizadas === false && acoesPendentes === true) {
        setAcoesAjuizadas(!acoesAjuizadas);
      }
      setAcoesPendentes(!acoesPendentes);
    }
    if (typeAction === 'acoesAjuizadas') {
      if (acoesAjuizadas === true && acoesPendentes === false) {
        setAcoesPendentes(!acoesPendentes);
      }
      setAcoesAjuizadas(!acoesAjuizadas);
    }
    if (typeAction === 'despejo') {
      if (despejo === true && execucao === false && denunciaDespejo === false && denunciaExecucao === false) {
        warning('Não é possivel desativar todas as ações');
        setDespejo(true);
      } else {
        setDespejo(!despejo);
      }
    }
    if (typeAction === 'execucao') {
      if (despejo === false && execucao === true && denunciaDespejo === false && denunciaExecucao === false) {
        warning('Não é possivel desativar todas as ações');
        setExecucao(true);
      } else {
        setExecucao(!execucao);
      }
    }
    if (typeAction === 'denunciaDespejo') {
      if (despejo === false && execucao === false && denunciaDespejo === true && denunciaExecucao === false) {
        warning('Não é possivel desativar todas as ações');
        setDenunciaDespejo(true);
      } else {
        setDenunciaDespejo(!denunciaDespejo);
      }
    }
    if (typeAction === 'denunciaExecucao') {
      if (despejo === false && execucao === false && denunciaDespejo === false && denunciaExecucao === true) {
        warning('Não é possivel desativar todas as ações');
        setDenunciaExecucao(true);
      } else {
        setDenunciaExecucao(!denunciaExecucao);
      }
    }
  };

  const getAcaoIcon = (fase: string) => {
    switch (fase) {
      case 'Documentação':
        return DocumentationIcon;
      case 'Guia de Pagamento':
        return GuiaIcon;
      case 'PO':
        return VentoIcon;
      case 'Comprovantes de Pagamento':
        return BillIcon;
      case 'Chamado para Ajuizamento':
        return ListIcon;
      case 'Ação Ajuizada':
        return JudgeIcon;

      default:
        return JudgeIcon;
    }
  };

  const ajuizamentoDetails = useMemo(() => {
    if (isLoadingDetails) {
      return (
        <div
          style={{
            marginTop: '1rem',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    if (ajuizamentoFases === null) {
      return null;
    }

    return (
      <>
        <LineTotalizador>
          <span>Total de Contratos: {ajuizamentoFases.totalContratos}</span>
          <span>Total de Clientes: {ajuizamentoFases.totalClientes}</span>
          <span>Valor Total de Contratos: {toReal(ajuizamentoFases.valorTotal)}</span>
        </LineTotalizador>

        <AcoesPorFase>
          <h2>Ações por Fase</h2>
          <Acoes>
            {ajuizamentoFases.acoesPorFases.map(item => {
              return (
                <Acao>
                  <FaseIcon src={getAcaoIcon(item.fase)} />
                  <AcaoText>{item.fase}</AcaoText>
                  <AcaoPercent>{item.percentual}%</AcaoPercent>
                </Acao>
              );
            })}
          </Acoes>
        </AcoesPorFase>
      </>
    );
  }, [isLoadingDetails, ajuizamentoFases]);

  return (
    <>
      <Container>
        <Detalhamento
          isOpen={isDetalhamentoOpen}
          onCloseDetalhamento={() => setDetalhamentoOpen(false)}
          ajuizamentoADetalhar={ajuizamentoADetalhar}
          onClickUpdateFase={() => openUpdateFase()}
          onCancelamento={(typeCancelamento: string) => cancelarAcao(typeCancelamento)}
        />

        <FaseUpdateModal
          isOpen={isUpdateFaseOpen}
          onCloseFaseUpdate={() => closeUpdateModal()}
          ajuizamentoAAtualizar={ajuizamentoADetalhar}
        />

        <CancelamentoModal
          isOpen={isCancelamentoOpen}
          onCloseDesistencia={() => setCancelamentoOpen(false)}
          tipoCancelamento={tipoCancelamento}
        />

        <FullScreenLoading isEnabled={isLoading || isLoadingShoppings || isLoadingOrigem || isLoadingGruposCobranca} />

        <Form
          items={[
            <Select placeholder="Shoppings" options={shoppings} state={[selectedShopping, setselectedShopping]} />,
            <SingleSelect placeholder="Origem" options={sourceList} state={[origem, setOrigem]} />,
            <SingleSelect
              placeholder="Fase atual"
              options={FASE_ATUAL}
              state={[FASE_ATUAL.find(e => e.value === idFase), options => setIdFase(options.value as number)]}
            />,
            <Input
              placeholder="Número do Contrato"
              label="Número do Contrato"
              state={[contractNumber, setContractNumber]}
            />,
            <Input
              placeholder="Número do Chamado"
              label="Número do Chamado"
              state={[numeroChamado, setNumeroChamado]}
            />,
            <Input placeholder="CPF/CNPJ" label="CPF/CNPJ" state={[cpfCnpj, setCpfCnpj]} />,
            <SingleSelect
              options={last12Months()}
              state={[mesAnoCicloAjuizamento, setMesAnoCicloAjuizamento]}
              placeholder="Mês/Ano"
            />,
            <Input placeholder="LUC" label="LUC" state={[luc, setLuc]} />,
            <SingleSelect
              options={billingGroupsList}
              state={[billingGroup, setBillingGroup]}
              placeholder="Grupo de Cobrança"
            />,
            <Checkbox label="Ações Pendentes" onClick={() => handleCheck('acoesPendentes')} checked={acoesPendentes} />,
            <Checkbox label="Ações Ajuizadas" onClick={() => handleCheck('acoesAjuizadas')} checked={acoesAjuizadas} />,
            null,
            <Checkbox label="Despejo" onClick={() => handleCheck('despejo')} checked={despejo} />,
            <Checkbox label="Execução" onClick={() => handleCheck('execucao')} checked={execucao} />,
            <Checkbox
              label="Denúncia Despejo"
              onClick={() => handleCheck('denunciaDespejo')}
              checked={denunciaDespejo}
            />,
            <Checkbox
              label="Denúncia Execução"
              onClick={() => handleCheck('denunciaExecucao')}
              checked={denunciaExecucao}
            />,
          ]}
          submitButton={<Button text="Pesquisar" onClick={() => pesquisar(true, 1)} />}
        />
      </Container>

      {ajuizamentos && ajuizamentos.data.length > 0 && (
        <ContentWrapper>
          <ViewChanger>
            <GridOnIcon onClick={() => setCurrentView('card')} style={getGridIconStyle(currentView)} />
            <TableChartIcon onClick={() => setCurrentView('table')} style={getTableIconStyle(currentView)} />
          </ViewChanger>
          {currentView === 'table' && (
            <TableWrapper>
              <TableContainer>
                <CustTable>
                  <thead>
                    <tr>
                      <HeaderItem>Código Controle</HeaderItem>
                      <HeaderItem>Origem</HeaderItem>
                      <HeaderItem>Shopping</HeaderItem>
                      <HeaderItem>Nome Fantasia</HeaderItem>
                      <HeaderItem>LUC</HeaderItem>
                      <HeaderItem>Razão Social</HeaderItem>
                      <HeaderItem>CPF/CNPJ</HeaderItem>
                      <HeaderItem>Contrato</HeaderItem>
                      <HeaderItem>Saldo em Aberto Vencido para Critério</HeaderItem>
                      <HeaderItem>Saldo em Aberto Total</HeaderItem>
                      <HeaderItem>Saldo Devedor Corrigido</HeaderItem>
                      <HeaderItem>Tipo Ação</HeaderItem>
                      <HeaderItem>Tipo Dívida</HeaderItem>
                      <HeaderItem>Status do Processo</HeaderItem>
                      <HeaderItem>Fase Atual</HeaderItem>
                      <HeaderItem />
                      <HeaderItem>Prazo de Conclusao</HeaderItem>
                    </tr>
                  </thead>
                  <tbody>
                    {ajuizamentos?.data.map(acao => {
                      return (
                        <TableRow onClick={() => detalhesAjuizamento(acao)} key={acao.codigoControle}>
                          <RowItem>{acao.codigoControle}</RowItem>
                          <RowItem>{acao.origem}</RowItem>
                          <RowItem>{acao.shopping}</RowItem>
                          <RowItem>{acao.nomeFantasia}</RowItem>
                          <RowItem>{acao.luc}</RowItem>
                          <RowItem>
                            <Tooltip title={acao.razaoSocial}>
                              <span>{truncateString(acao.razaoSocial, 14)}</span>
                            </Tooltip>
                          </RowItem>
                          <RowItem>{acao.cpfCnpj}</RowItem>
                          <RowItem>{acao.numeroContrato}</RowItem>
                          <RowItem>{toReal(acao.saldoAbertoVencidoCriterio)}</RowItem>
                          <RowItem>{toReal(acao.saldoAbertoTotal)}</RowItem>
                          <RowItem>{toReal(acao.saldoDevedorCorrigido)}</RowItem>
                          <RowItem>{acao.tipoAcao}</RowItem>
                          <RowItem>{acao.tipoDivida}</RowItem>
                          <RowItem>{acao.statusAjuizamento}</RowItem>
                          <RowItem>{acao.faseAtual}</RowItem>
                          <RowItem>
                            <AcompanharButton onClick={() => detalhesAjuizamento(acao)}>ACOMPANHAR</AcompanharButton>
                          </RowItem>
                          <RowItem>{acao.prazoConclusao}</RowItem>
                        </TableRow>
                      );
                    })}
                  </tbody>
                </CustTable>
              </TableContainer>
            </TableWrapper>
          )}
          {currentView === 'card' && (
            <CardWrapper>
              {ajuizamentos?.data.map(acao => {
                return (
                  <Card>
                    <CardLine>
                      <CardCol>
                        <CardLabel>Cod. Controle</CardLabel>
                        <CardInfo bold>{acao.codigoControle}</CardInfo>
                      </CardCol>
                      <CardCol>
                        <CardLabel>Tipo de Dívida</CardLabel>
                        <CardInfo tipo={acao.tipoDivida}>{acao.tipoDivida}</CardInfo>
                      </CardCol>
                    </CardLine>
                    <CardSeparator />
                    <CardLine>
                      <CardCol>
                        <CardLabel>Origem</CardLabel>
                        <CardInfo>{acao.origem}</CardInfo>
                      </CardCol>
                      <CardCol>
                        <CardLabel>Shopping</CardLabel>
                        <CardInfo>{acao.shopping}</CardInfo>
                      </CardCol>
                    </CardLine>
                    <CardLine>
                      <CardCol>
                        <CardLabel>Nome Fantasia</CardLabel>
                        <CardInfo>{acao.nomeFantasia}</CardInfo>
                      </CardCol>
                      <CardCol>
                        <CardLabel>LUC</CardLabel>
                        <CardInfo>{acao.luc}</CardInfo>
                      </CardCol>
                    </CardLine>
                    <CardLine>
                      <CardCol>
                        <CardLabel>Razão Social</CardLabel>
                        <CardInfo>{truncateString(acao.razaoSocial, 14)}</CardInfo>
                      </CardCol>
                      <CardCol>
                        <CardLabel>CPF/CNPJ</CardLabel>
                        <CardInfo>{acao.cpfCnpj}</CardInfo>
                      </CardCol>
                    </CardLine>
                    <CardSeparator />
                    <CardLine>
                      <CardCol>
                        <CardLabel>Saldo em Aberto Venc. para Critério</CardLabel>
                        <CardValueInfo>{toReal(acao.saldoAbertoVencidoCriterio)}</CardValueInfo>
                      </CardCol>
                      <CardCol>
                        <CardLabel>
                          Saldo em Aberto Total&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </CardLabel>
                        <CardValueInfo>{toReal(acao.saldoAbertoTotal)}</CardValueInfo>
                      </CardCol>
                    </CardLine>
                    <AcompanharButton full onClick={() => detalhesAjuizamento(acao)}>
                      ACOMPANHAR
                    </AcompanharButton>
                  </Card>
                );
              })}
            </CardWrapper>
          )}
          {showPagination && (
            <>
              <ActionsRow>
                <Button text="Exportar" onClick={handleAjuizamentoExport} />
                <Pagination count={ajuizamentos?.pageInfo.totalPages} page={page} onChange={handleChangePagination} />
              </ActionsRow>
            </>
          )}
          {ajuizamentoDetails}
        </ContentWrapper>
      )}
    </>
  );
};
