/* eslint-disable react/prop-types */
import React from 'react';
import { Checkbox, Mark, Label } from './styles';

type Props = {
  active: boolean;
  label: string;
  click: Function;
  disabled?: boolean;
};

const CustomCheckbox: React.FC<Props> = ({ active, label, click }) => {
  return (
    <Checkbox onClick={() => click()}>
      <Mark className={active ? 'selected' : ''} />
      <Label>{label}</Label>
    </Checkbox>
  );
};

export default CustomCheckbox;
