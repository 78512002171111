import styled from 'styled-components';

export const Wrapper = styled.div`
  .loading {
    width: 150px;
    margin-top: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Container = styled.div`
  padding: 0 2rem;
  > h2 {
    color: #7e9395;

    margin-bottom: 1rem;
    font-weight: 600;
  }

  > p {
    color: #00959c;
  }
`;

export const Section = styled.div`
  margin-top: 3rem;
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: space-between;
`;
