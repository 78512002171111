import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import api from '../../../../_core/api';
import { MinimumRequirement } from './index';
import { ContractType } from '../tipos-contrato';
import { Action } from '../tipos-acao';
import { failure, success } from '../../../../_core/services/toast';
import { Button, Form, Input, Option, SingleSelect } from '../../../../_core/_components';
import { converterParaFloat, mascaraNumeracaoPtBr, mascaraNumeros } from '../../../../_core/masks';

type Props = {
  open: boolean;
  onClose: () => void;
  selectedRegister?: MinimumRequirement;
  contractTypeData: ContractType[];
  actionData: Action[];
};

const Modal: React.FC<Props> = ({ open, onClose, selectedRegister, contractTypeData, actionData }: Props) => {
  const [loading, setLoading] = useState(false);
  const [minTotal, setMinTotal] = useState<string>('');
  const [minMounth, setMinMounth] = useState<string>('');
  const [aging, setAging] = useState<string>('');
  const [contractType, setContractType] = useState<Option>();
  const [actionType, setActionType] = useState<Option>();
  const [orderPayment, setOrderPayment] = useState<string>('');

  useEffect(() => {
    if (selectedRegister) {
      setMinTotal(selectedRegister.minTotal.toString());
      setMinMounth(selectedRegister.minMounth.toString());
      setAging(selectedRegister.aging.toString());
      setOrderPayment(selectedRegister.orderPayment.toString());
      setContractType(getContractOptions().find(c => c.value === selectedRegister.contract)!);
      setActionType(getActionsOptions().find(c => c.value === selectedRegister.action)!);
    } else {
      resetModalState();
    }
  }, [selectedRegister]);

  const resetModalState = () => {
    setMinTotal('');
    setMinMounth('');
    setAging('');
    setOrderPayment('');
    setContractType(undefined);
    setActionType(undefined);
  };

  const getContractOptions = () => {
    return contractTypeData.map(c => ({
      value: c.id,
      label: c.contract,
    }));
  };

  const getActionsOptions = () => {
    return actionData.map(c => ({
      value: c.id,
      label: c.nome,
    }));
  };

  const save = async () => {
    setLoading(true);

    let payload = {};

    if (aging && orderPayment && minTotal && minMounth && actionType && contractType) {
      payload = {
        action: actionType.value,
        contract: contractType.value,
        aging: parseInt(aging, 10),
        orderPayment: parseInt(orderPayment, 10),
        minTotal: converterParaFloat(minTotal),
        minMounth: converterParaFloat(minMounth),
        ...(selectedRegister && { id: selectedRegister.id }),
      };
    }

    if (!selectedRegister) {
      try {
        await api.post('/judgment/requirement-minimum/add', payload);
        success('Criado com sucesso.');
        setLoading(false);
        resetModalState();
        onClose();
      } catch (e) {
        failure('Erro ao tentar criar registro.');
        setLoading(false);
      }
    } else {
      try {
        await api.put('/judgment/requirement-minimum/upd', payload);
        success('Atualizado com sucesso.');
        setLoading(false);
        resetModalState();
        onClose();
      } catch (e) {
        failure('Erro ao tentar atualizar registro.');
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar
        </span>
        <Grid>
          <Form
            items={[
              <SingleSelect
                state={[contractType, setContractType]}
                placeholder="Tipo de Contrato"
                options={getContractOptions()}
              />,
              <SingleSelect
                state={[actionType, setActionType]}
                placeholder="Tipo de Ação"
                options={getActionsOptions()}
              />,
              <Input
                pattern={mascaraNumeracaoPtBr}
                placeholder="Div. Min. Total"
                label="Div. Min. Total"
                state={[minTotal, setMinTotal]}
              />,
              <Input
                pattern={mascaraNumeracaoPtBr}
                placeholder="Div. Min. Mês"
                label="Div. Min. Mês"
                state={[minMounth, setMinMounth]}
              />,
              <Input pattern={mascaraNumeros} placeholder="Aging" label="Aging" state={[aging, setAging]} />,
              <Input
                pattern={mascaraNumeros}
                placeholder="Boletos abertos"
                label="Boletos abertos"
                state={[orderPayment, setOrderPayment]}
              />,
            ]}
            submitButton={<Button onClick={save} text="Salvar" disabled={loading} />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
