import React, { useState, useEffect, useMemo } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import * as S from '../styles';
import Modal from './modal';
import api from '../../../../_core/api';
import { toReal } from '../../../../_core/services/formaters';
import { ContractType } from '../tipos-contrato';
import { Action } from '../tipos-acao';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import Table from '../../../../_core/_components/table';
import { Button } from '../../../../_core/_components';

export type MinimumRequirement = {
  id: number;
  action: number;
  contract: number;
  aging: number;
  minTotal: number;
  minMounth: number;
  orderPayment: number;
};

const RequisitosMinimos: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [registerList, setRegisterList] = useState<MinimumRequirement[]>([]);
  const [contractTypeData, setContractTypeData] = useState<ContractType[]>([]);
  const [actionData, setActionData] = useState<Action[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [itemInAction, setItemInAction] = useState<MinimumRequirement>();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<MinimumRequirement[]>('/judgment/requirement-minimum');
      const { data: contractTypeResponse } = await api.get<ContractType[]>('/judgment/type-of-contract');
      const { data: actionsResponse } = await api.get<Action[]>('/judgment/type-of-action');
      setContractTypeData(contractTypeResponse);
      setActionData(actionsResponse);
      setRegisterList(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    getData();
    setModalOpen(false);
    setItemInAction(undefined);
  };

  const getActionType = (actionId: number) => {
    return actionData.find(a => a.id === actionId)?.nome;
  };

  const getContractType = (contractId: number) => {
    return contractTypeData.find(a => a.id === contractId)?.contract;
  };

  const handleEditClick = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };

  const renderedData = useMemo(() => {
    if (registerList.length > 0) {
      return registerList.map(item => ({
        id: item.id,
        contract: getContractType(item.contract),
        action: getActionType(item.action),
        aging: item.aging,
        minTotal: toReal(item.minTotal),
        minMounth: toReal(item.minMounth),
        orderPayment: item.orderPayment,
        actions: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.id.toString()}
            onClick={(e: any) => {
              setAnchorEl(e.currentTarget);
              const register = registerList.find(i => i.id === item.id);
              setItemInAction(register);
            }}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [registerList]);

  return (
    <S.ContentContainer>
      <FullScreenLoading isEnabled={loading} />
      <Modal
        contractTypeData={contractTypeData}
        actionData={actionData}
        selectedRegister={itemInAction}
        open={isModalOpen}
        onClose={() => handleCloseModal()}
      />
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField />
        </S.SearchBar>
        <Button text="Criar novo" onClick={() => setModalOpen(true)} />
      </S.TopBar>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleEditClick}>Editar</MenuItem>
      </Menu>
      {renderedData.length > 0 && (
        <Table
          columns={[
            { label: 'Tipo de Contrato', key: 'contract' },
            { label: 'Tipo de Ação', key: 'action' },
            { label: 'Aging', key: 'aging' },
            { label: 'Div Min Total', key: 'minTotal' },
            { label: 'Div Min Mês', key: 'minMounth' },
            { label: 'Boletos Abertos', key: 'orderPayment' },
            { label: '', key: 'actions' },
          ]}
          data={renderedData}
        />
      )}
    </S.ContentContainer>
  );
};

export default RequisitosMinimos;
