import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { AuthContext } from '../../../_main/contexts/auth';
import ConfirmationModal from '../../../_core/_components/confirmation-modal';
import { Container, Section } from './styles';
import api from '../../../_core/api';
import { Boleto, BoletoShoppingResponse } from './types';
import Table from '../../../_core/_components/table';
import { handleErrors, success, warning } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';
import { Button, Checkbox, Form, Input, Option, Select } from '../../../_core/_components';
import { mascaraNumerosVirgula } from '../../../_core/masks';

type TShopping = {
  shoppingId: number;
  shoppingName: string;
};

export const BaixaManual: React.FC = () => {
  const { id, profile } = useContext(AuthContext);
  const { response: responseShoppings, isLoading } = useCache({
    key: 'getShoppings',
    fn: requests.get('getShoppings'),
  });
  const [loading, setLoading] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [data, setData] = useState<Boleto[]>([]);
  const [selectedData, setSelectedData] = useState<Boleto[]>([]);
  const [shoppingList, setShoppingList] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [numeroBoleto, setNumeroBoleto] = useState<string>('');
  const [luc, setLuc] = useState<string>('');
  const [nomeFantasia, setNomeFantasia] = useState<string>('');
  const [customerInSerasa, setCustomerInSerasa] = useState(false);
  const [customerInOutSerasa, setCustomerInOutSerasa] = useState(false);
  const [customerNeverSerasa, setCustomerNeverSerasa] = useState(false);

  useEffect(() => {
    if (responseShoppings !== undefined) {
      const listShopping: Option[] = [];
      responseShoppings.data.map((item: TShopping) => {
        listShopping.push({
          value: item.shoppingId,
          label: item.shoppingName,
        });
        return null;
      });
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppingList(listShopping);
      setSelectedShoppings(listShopping);
    }
  }, [responseShoppings]);

  const getFilters = async () => {
    try {
      setSelectedData([]);
      setData([]);
      setLoading(true);
      const response = await api.post<BoletoShoppingResponse>(
        '/workflow/serasa/serasa-manual/buscar/boletos-shopping',
        {
          idShopping: selectedShoppings.map(item => item.value),
          idUsuario: id,
          clientesNegativado: customerInSerasa,
          clientesForamNegativado: customerInOutSerasa,
          clientesNuncaNegativado: customerNeverSerasa,
          numBoletos: numeroBoleto.length > 0 ? numeroBoleto.split(',') : undefined,
          tipo: 'BAIXA_MANUAL',
          marca: nomeFantasia.length > 0 ? nomeFantasia : undefined,
          luc: luc.length > 0 ? luc : undefined,
        },
      );
      if (_.isEmpty(response.data.content)) {
        warning('Não obteve resultados.');
        return;
      }
      setData(response.data.content);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const submitBaixaManual = async () => {
    setIsOpenConfirmation(false);
    setLoading(true);
    try {
      const boletos = selectedData.map((item, i) => ({
        idBoleto: item.idBoleto,
        loja: {
          idSerasaLoja: item.serasaLoja.idSerasaLoja,
          negativar: true,
        },
        fiadores: item.fiadores.map((e: any) => ({
          idSerasaBoletoFiador: e.idSerasaBoletoFiador,
          negativar: true,
        })),
      }));
      const response = await api.post('/workflow/serasa/serasa-manual/baixa-manual', {
        idUsuario: id,
        boletos,
      });
      success(response.data.message);
      getFilters();
    } catch (error) {
      setLoading(false);
      handleErrors(error);
    }
  };

  const clickRow = (element: any) => {
    const boleto = data.find(e => e.idBoleto === element.idBoleto)!;
    const isSelected = checkBoletoSelection(boleto);

    if (!isSelected) {
      setSelectedData([...selectedData, boleto]);
    } else {
      setSelectedData(selectedData.filter(s => s.idBoleto !== boleto.idBoleto));
    }
  };

  const checkBoletoSelection = (boleto: Boleto) => {
    return !!selectedData.find(s => boleto.idBoleto === s.idBoleto);
  };

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      const fiadoresOptions: Option[][] = [];
      data.forEach(item => {
        fiadoresOptions.push(
          item.fiadores.map((fiador: any) => ({
            value: fiador.cpfcnpjFiador,
            label: fiador.nomeFiador,
          })),
        );
      });
      return data.map((item, i) => ({
        toggle: <Checkbox checked={checkBoletoSelection(item)} onClick={() => clickRow(item)} />,
        idBoleto: item.idBoleto,
        nmShopping: item.nmShopping,
        nomFantasia: item.nomFantasia,
        luc: item.luc,
        datVencimento: item.datVencimento,
        numBoleto: item.numBoleto,
        valTotalVencido: item.valTotalVencido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        fiadores: <Select state={[fiadoresOptions[i], () => null]} options={fiadoresOptions[i]} />,
      }));
    }
    return [];
  }, [data, selectedData]);

  const bToggleAll = useMemo(() => {
    const allBoletosIds = data.map(e => e.idBoleto);
    const selectedIds = selectedData.map(e => e.idBoleto);
    return allBoletosIds.every(e => selectedIds.includes(e));
  }, [selectedData, data]);

  const onToggleAll = () => {
    if (bToggleAll) {
      setSelectedData([]);
    } else {
      setSelectedData(data);
    }
  };

  const download = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        `/workflow/serasa/serasa-manual/download/boletos-shopping`,
        {
          idShopping: selectedShoppings.map(item => item.value),
          idUsuario: id,
          clientesNegativado: customerInSerasa,
          clientesForamNegativado: customerInOutSerasa,
          clientesNuncaNegativado: customerNeverSerasa,
          numBoletos: numeroBoleto.length > 0 ? numeroBoleto.split(',') : undefined,
          tipo: 'BAIXA_MANUAL',
          marca: nomeFantasia.length > 0 ? nomeFantasia : undefined,
          luc: luc.length > 0 ? luc : undefined,
        },
        {
          responseType: 'arraybuffer',
        },
      );
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading || isLoading} />
      <Container>
        <Form
          items={[
            <Select
              state={[selectedShoppings, setSelectedShoppings]}
              options={shoppingList}
              disabled={profile.includes('SHOPPING')}
            />,
            <Input label="Nome fantasia" state={[nomeFantasia, setNomeFantasia]} />,
            <Input label="LUC" state={[luc, setLuc]} />,
            <Input label="Número do boleto" state={[numeroBoleto, setNumeroBoleto]} pattern={mascaraNumerosVirgula} />,
            null,
            null,
            <Checkbox
              checked={customerInSerasa}
              onClick={() => setCustomerInSerasa(!customerInSerasa)}
              label="Clientes que já estão no Serasa atualmente"
            />,
            <Checkbox
              checked={customerInOutSerasa}
              onClick={() => setCustomerInOutSerasa(!customerInOutSerasa)}
              label="Clientes que já estiveram no Serasa anteriormente"
            />,
            <Checkbox
              checked={customerNeverSerasa}
              onClick={() => setCustomerNeverSerasa(!customerNeverSerasa)}
              label="Clientes que nunca estiveram no Serasa"
            />,
          ]}
          submitButton={<Button onClick={getFilters} text="Pesquisar" />}
        />
        {renderedData.length > 0 && (
          <>
            <Table
              columns={[
                { label: <Checkbox type="secondary" checked={bToggleAll} onClick={onToggleAll} />, key: 'toggle' },
                { label: 'Nome do Shopping', key: 'nmShopping', orderable: true },
                { label: 'Nome fantasia', key: 'nomFantasia', orderable: true },
                { label: 'LUC', key: 'luc', orderable: true },
                { label: 'Vencimento do boleto', key: 'datVencimento', orderable: true },
                { label: 'Número do boleto', key: 'numBoleto', orderable: true },
                { label: 'Valor do Boleto', key: 'valTotalVencido', orderable: true },
                { label: 'Fiadores', key: 'fiadores' },
              ]}
              data={renderedData}
            />
            <Section>
              <Button
                onClick={() => setIsOpenConfirmation(true)}
                text="SUBMETER RECOMENDAÇÃO"
                disabled={selectedData.length === 0}
              />
              <Button text="Exportar" onClick={download} />
            </Section>
          </>
        )}
      </Container>
      <ConfirmationModal
        open={isOpenConfirmation}
        text="Deseja submeter os itens selecionados?"
        handleCancel={() => setIsOpenConfirmation(false)}
        handleOk={submitBaixaManual}
      />
    </>
  );
};
