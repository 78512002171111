import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import MoreIcon from '@material-ui/icons/MoreVert';
import { IconButton } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import {
  Container,
  Section,
  TableContainer,
  CustTable,
  TableRow,
  RowItem,
  HeaderItem,
  PaginationWrapper,
  Orderable,
} from './styles';
import api from '../../../_core/api';
import Modal from './modal';
import { AuthContext } from '../../../_main/contexts/auth';
import { failure, handleErrors, success, warning } from '../../../_core/services/toast';
import ArrowDown from '../../../assets/downArrow';
import { downloadXls } from '../../../_core/services/download';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';
import { Form, Select, Button, Input } from '../../../_core/_components';

const ROWS_PER_PAGE = 20;

type IShopping = {
  shoppingId: number;
  shoppingName: string;
};

type Response = {
  datVencimento: string;
  fiadores: any[];
  flagNegativar: boolean;
  idBoleto: number;
  idShopping: number;
  luc: string;
  nmShopping: string;
  nomFantasia: string;
  numBoleto: number;
  numChamado: string;
  nomeAnexo: string;
  qtdNegativacao: 0;
  quantidadeExcecao: 0;
  serasaJustificatica: { idSerasaJustificatica: number; txtJustificativa: string };
  statusWorkflowSerasa: { ativo: boolean; codigo: string; id: number; nome: string };
  serasaLoja: {
    idSerasaLoja: number;
    grupoCobranca: string;
    portfolio: string;
    nomRazaoSocial: string;
  };
  txtObservacao: string;
  valTotalVencido: number;
};

const DATA_MULTISELECT = [
  { value: 0, label: 'NÃO' },
  { value: 1, label: 'SIM' },
];

export type Values = {
  id: number;
  negativado: boolean;
  fiadores: any[];
  justificativa: number;
  observacao: string;
  nrChamado: string;
  anexo: string;
};

type Sort = 'desc' | 'asc' | undefined;

interface SortOptions {
  key: string;
  type: Sort;
}

export const ValidarAprovacoes: React.FC = () => {
  const { id, profile, shoppings: profileShoppings } = useContext(AuthContext);
  const { response: responseShoppings, isLoading: isLoadingShoppings } = useCache({
    key: 'getShoppings',
    fn: requests.get('getShoppings'),
  });
  const { response: responseJustificativas, isLoading: isLoadingJustificativas } = useCache({
    key: 'getJustificativas',
    fn: requests.get('getJustificativas'),
  });
  const [justificativas, setJustificativas] = useState<any[]>([]);
  const [shoppings, setShoppings] = useState<any[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [idBoleto, setIdBoleto] = useState(-1);
  const [marca, setMarca] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [luc, setLuc] = useState('');
  const [nrBoleto, setNrBoleto] = useState('');
  const [customerInSerasa] = useState(false);
  const [customerInOutSerasa] = useState(false);
  const [customerNeverSerasa] = useState(false);
  const [data, setData] = useState<Response[]>([]);
  const [page, setPage] = useState(1);
  const [values, setValues] = useState<Values[]>([]);
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    key: '',
    type: undefined,
  });

  useEffect(() => {
    const listShopping: any[] = [];
    if (responseShoppings !== undefined) {
      responseShoppings.data.map((item: IShopping) => {
        listShopping.push({
          value: item.shoppingId,
          label: item.shoppingName,
        });
        return null;
      });
      listShopping.sort((item1: any, item2: any) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppings(listShopping);
      if (profile.includes('SHOPPING') && profileShoppings) {
        const arrProfileShoppings = profileShoppings.map(e => ({
          id: e.id,
          label: e.name,
        }));
        setSelectedShoppings(arrProfileShoppings);
      } else {
        setSelectedShoppings(listShopping);
      }
    }
  }, [responseShoppings]);

  useEffect(() => {
    if (responseJustificativas !== undefined) {
      const listJustificativas: any[] = [];
      responseJustificativas.data.content.map((item: any) => {
        listJustificativas.push({
          value: item.idSerasaJustificatica,
          label: item.txtJustificativa,
          campoObrigatorio: item.campoObrigatorio,
        });
        return null;
      });
      setJustificativas(listJustificativas);
    }
  }, [responseJustificativas]);

  const getFiadores = (vals: any) => {
    if (vals.fiadores) {
      const fiadores = vals.fiadores.map((f: any, i: any) => ({
        id: i,
        label: f.nomeFiador,
      }));
      return fiadores;
    }
    return [];
  };

  const open = useMemo(() => idBoleto !== -1, [idBoleto]);

  const dataPaginated = useMemo(() => {
    const orderableArr = sortOptions.type ? _.orderBy(data, sortOptions.key, sortOptions.type) : data;
    let arr: Response[] = [];
    arr = orderableArr.slice((page - 1) * ROWS_PER_PAGE, (page - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE);
    return arr;
  }, [data, page, sortOptions]);

  const getFilters = async (updatePage = true) => {
    if (updatePage) {
      setPage(1);
    }
    setLoading(true);
    setData([]);
    try {
      const response = await api.post(`/workflow/serasa/serasa-negativacao/buscar/validar-boletos`, {
        idShopping: selectedShoppings.map(item => item.value),
        idUsuario: id,
        clientesNegativado: customerInSerasa,
        clientesForamNegativado: customerInOutSerasa,
        clientesNuncaNegativado: customerNeverSerasa,
        luc: luc.length > 0 ? luc : undefined,
        numBoleto: nrBoleto.length > 0 ? nrBoleto : undefined,
        numCpfcnpj: cnpj.length > 0 ? cnpj : undefined,
        status: ['PENDENTE_APROVACAO_EXCECAO_KA'],
      });
      if (_.isEmpty(response.data.content)) {
        warning('Não obteve resultados.');
      } else {
        setValues(
          response.data.content.map((item: Response) => ({
            id: item.idBoleto,
            negativado: item.flagNegativar,
            fiadores: item.fiadores.map(fiador => ({
              id: fiador.usuarioCadastro.id,
              label: fiador.usuarioCadastro.nome,
            })),
            justificativa: item.serasaJustificatica?.idSerasaJustificatica ?? 999,
            observacao: item.txtObservacao ?? '',
            nrChamado: item.numChamado ?? '',
            valTotalVencido: item.valTotalVencido,
            anexo: item.nomeAnexo ?? '',
          })),
        );
        setData(response.data.content);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onModalSubmit = async (params: any) => {
    setLoading(true);
    try {
      const arr = [...values];
      const index = arr.findIndex(e => e.id === idBoleto);
      arr[index] = { ...arr[index], ...params };
      if (params.file) {
        const formData = new FormData();
        formData.append('file', params.file);
        formData.append('idBoleto', arr[index].id.toString());
        formData.append('idJustificativa', arr[index].justificativa.toString());
        formData.append('idUsuario', id.toString());
        formData.append('numChamado', arr[index].nrChamado);
        formData.append('txtObservacao', arr[index].observacao);
        await api.post('/workflow/serasa/serasa-upload/historico/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        const payload = {
          idBoleto: arr[index].id,
          idJustificativa: arr[index].justificativa,
          idUsuario: id,
          numChamado: arr[index].nrChamado,
          txtObservacao: arr[index].observacao,
        };
        await api.post('/workflow/serasa/serasa-workflow/salvar-historico', payload);
      }
      setIdBoleto(-1);
      await getFilters(false);
      success('Salvo com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onChangeNegativados = async (boletoId: number) => {
    const i = values.findIndex(e => e.id === boletoId);
    const arr = [...values];
    arr[i].negativado = !arr[i].negativado;
    const item = data[i];
    const boleto = {
      idBoleto: item.idBoleto,
      loja: {
        idSerasaLoja: item.serasaLoja.idSerasaLoja,
        negativar: arr[i].negativado,
      },
      fiadores: item.fiadores.map(e => ({
        idSerasaBoletoFiador: e.idSerasaBoletoFiador,
        negativar: arr[i].negativado,
      })),
    };
    await api.post('/workflow/serasa/serasa-negativacao/atualizar-negativacao', {
      idUsuario: id,
      boleto,
    });
    setValues(arr);
  };

  const submit = async () => {
    let disabled = false;
    let boletoId = 0;
    values.forEach(e => {
      if (!e.negativado && (!e.justificativa || e.justificativa === 999)) {
        disabled = true;
        boletoId = e.id;
      }
    });
    if (disabled) {
      const boleto = data.find(e => e.idBoleto === boletoId);
      return failure(`O boleto ${boleto?.numBoleto} precisa de uma justificativa.`);
    }
    setLoading(true);
    try {
      const boletos = data.map((item, i) => ({
        idBoleto: item.idBoleto,
        loja: {
          idSerasaLoja: item.serasaLoja.idSerasaLoja,
          negativar: values[i].negativado,
        },
        fiadores: item.fiadores.map(e => ({
          idSerasaBoletoFiador: e.idSerasaBoletoFiador,
          negativar: values[i].negativado,
        })),
      }));
      await api.post('/workflow/serasa/serasa-negativacao/submeter-negativacao', {
        idUsuario: id,
        boletos,
      });
      await getFilters();
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
    return null;
  };

  const limparObservacoes = async () => {
    setLoading(true);
    setIdBoleto(-1);
    try {
      await api.post('/workflow/serasa/serasa-workflow/historico/limpar', {
        idBoleto,
        idUsuario: id,
      });
      await getFilters();
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const flatNegativar = (boletoId: number) => {
    const item = values.find(e => e.id === boletoId);
    const arr: any[] = [];
    if (item?.negativado) {
      arr.push(DATA_MULTISELECT[1]);
    } else {
      arr.push(DATA_MULTISELECT[0]);
    }
    return arr;
  };

  const extraRowRender = (item: Response) => {
    if (item.serasaJustificatica || item.numChamado || item.txtObservacao) {
      return true;
    }

    return false;
  };

  const download = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        `/workflow/serasa/serasa-negativacao/download/boletos`,
        {
          idShopping: selectedShoppings.map(item => item.value),
          idUsuario: id,
          clientesNegativado: customerInSerasa,
          clientesForamNegativado: customerInOutSerasa,
          clientesNuncaNegativado: customerNeverSerasa,
          luc: luc.length > 0 ? luc : undefined,
          numBoleto: nrBoleto.length > 0 ? nrBoleto : undefined,
          numCpfcnpj: cnpj.length > 0 ? cnpj : undefined,
          status: ['PENDENTE_APROVACAO_EXCECAO_KA'],
        },
        {
          responseType: 'arraybuffer',
        },
      );
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onClickOrderable = (key: string) => {
    // Já clickou antes
    if (key === sortOptions.key) {
      switch (sortOptions.type) {
        case 'asc':
          setSortOptions({
            key,
            type: 'desc',
          });
          break;
        case 'desc':
          setSortOptions({
            key: '',
            type: undefined,
          });
          break;
        default:
          setSortOptions({
            key,
            type: 'asc',
          });
          break;
      }
      return;
    }
    // Primeiro Click
    setSortOptions({
      key,
      type: 'asc',
    });
  };

  const checkArrowDirection = (key: string) => {
    if (sortOptions.key === key) return sortOptions.type;
    return undefined;
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading || isLoadingShoppings || isLoadingJustificativas} />
      <Container>
        <Form
          items={[
            <Select placeholder="Shoppings" state={[selectedShoppings, setSelectedShoppings]} options={shoppings} />,
            <Input label="CNPJ/CPF" state={[cnpj, setCnpj]} />,
            <Input label="LUC" state={[luc, setLuc]} />,
            <Input label="Marca" state={[marca, setMarca]} />,
            <Input label="Número do boleto" state={[nrBoleto, setNrBoleto]} />,
          ]}
          submitButton={<Button onClick={getFilters} text="Pesquisar" />}
        />

        {dataPaginated.length > 0 && (
          <>
            <Section style={{ display: 'flex' }}>
              <Button text="Exportar" onClick={download} />
            </Section>
            <TableContainer>
              <CustTable>
                <thead>
                  <tr>
                    <HeaderItem>
                      <Orderable
                        onClick={() => onClickOrderable('nmShopping')}
                        arrowDirection={checkArrowDirection('nmShopping')}
                      >
                        Nome do Shopping
                        <ArrowDown click={null} color="white" />
                      </Orderable>
                    </HeaderItem>
                    <HeaderItem>
                      <Orderable
                        onClick={() => onClickOrderable('nomFantasia')}
                        arrowDirection={checkArrowDirection('nomFantasia')}
                      >
                        Nome fantasia
                        <ArrowDown click={null} color="white" />
                      </Orderable>
                    </HeaderItem>
                    <HeaderItem>
                      <Orderable
                        onClick={() => onClickOrderable('serasaLoja.nomRazaoSocial')}
                        arrowDirection={checkArrowDirection('serasaLoja.nomRazaoSocial')}
                      >
                        Razão Social
                        <ArrowDown click={null} color="white" />
                      </Orderable>
                    </HeaderItem>
                    {profile === 'COBRANÇA CORPORATIVA' && (
                      <>
                        <HeaderItem>
                          <Orderable
                            onClick={() => onClickOrderable('serasaLoja.grupoCobranca')}
                            arrowDirection={checkArrowDirection('serasaLoja.grupoCobranca')}
                          >
                            Grupo de cobrança
                            <ArrowDown click={null} color="white" />
                          </Orderable>
                        </HeaderItem>
                        <HeaderItem>
                          <Orderable
                            onClick={() => onClickOrderable('serasaLoja.portfolio')}
                            arrowDirection={checkArrowDirection('serasaLoja.portfolio')}
                          >
                            Portfolio
                            <ArrowDown click={null} color="white" />
                          </Orderable>
                        </HeaderItem>
                      </>
                    )}
                    <HeaderItem>
                      <Orderable onClick={() => onClickOrderable('luc')} arrowDirection={checkArrowDirection('luc')}>
                        LUC
                        <ArrowDown click={null} color="white" />
                      </Orderable>
                    </HeaderItem>
                    <HeaderItem>
                      <Orderable
                        onClick={() => onClickOrderable('datVencimento')}
                        arrowDirection={checkArrowDirection('datVencimento')}
                      >
                        Vencimento do boleto
                        <ArrowDown click={null} color="white" />
                      </Orderable>
                    </HeaderItem>
                    <HeaderItem>
                      <Orderable
                        onClick={() => onClickOrderable('numBoleto')}
                        arrowDirection={checkArrowDirection('numBoleto')}
                      >
                        Número do boleto
                        <ArrowDown click={null} color="white" />
                      </Orderable>
                    </HeaderItem>
                    <HeaderItem>
                      <Orderable
                        onClick={() => onClickOrderable('valTotalVencido')}
                        arrowDirection={checkArrowDirection('valTotalVencido')}
                      >
                        Valor do Boleto
                        <ArrowDown click={null} color="white" />
                      </Orderable>
                    </HeaderItem>
                    <HeaderItem>
                      <Orderable
                        onClick={() => onClickOrderable('qtdNegativacao')}
                        arrowDirection={checkArrowDirection('qtdNegativacao')}
                      >
                        Qtd de negativações
                        <ArrowDown click={null} color="white" />
                      </Orderable>
                    </HeaderItem>
                    <HeaderItem>
                      <Orderable
                        onClick={() => onClickOrderable('quantidadeExcecao')}
                        arrowDirection={checkArrowDirection('quantidadeExcecao')}
                      >
                        Qtd de exceções
                        <ArrowDown click={null} color="white" />
                      </Orderable>
                    </HeaderItem>
                    <HeaderItem>Fiadores</HeaderItem>
                    <HeaderItem>Negativar</HeaderItem>
                    <HeaderItem>Add</HeaderItem>
                  </tr>
                </thead>
                <tbody>
                  {dataPaginated.map(item => {
                    return (
                      <>
                        <TableRow key={item.idBoleto}>
                          <RowItem>{item.nmShopping}</RowItem>
                          <RowItem>{item.nomFantasia}</RowItem>
                          <RowItem>{item.serasaLoja.nomRazaoSocial}</RowItem>
                          {profile === 'COBRANÇA CORPORATIVA' && (
                            <>
                              <RowItem>{item.serasaLoja.grupoCobranca}</RowItem>
                              <RowItem>{item.serasaLoja.portfolio}</RowItem>
                            </>
                          )}
                          <RowItem>{item.luc}</RowItem>
                          <RowItem>{item.datVencimento}</RowItem>
                          <RowItem>{item.numBoleto}</RowItem>
                          <RowItem>
                            {item.valTotalVencido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                          </RowItem>
                          <RowItem>{item.qtdNegativacao}</RowItem>
                          <RowItem>{item.quantidadeExcecao}</RowItem>
                          <RowItem>
                            <Select state={[getFiadores(item), () => null]} options={getFiadores(item)} />
                          </RowItem>
                          <RowItem>
                            <Select
                              state={[flatNegativar(item.idBoleto), () => onChangeNegativados(item.idBoleto)]}
                              options={DATA_MULTISELECT}
                            />
                          </RowItem>
                          <RowItem>
                            <IconButton
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              color="primary"
                              component="span"
                              onClick={() => setIdBoleto(item.idBoleto)}
                            >
                              <MoreIcon />
                            </IconButton>
                          </RowItem>
                        </TableRow>
                        <TableRow className="noted" mod>
                          {extraRowRender(item) && (
                            <>
                              <RowItem colSpan={6}>
                                <b>Justificativa: </b>
                                {item.serasaJustificatica?.txtJustificativa}
                              </RowItem>
                              <RowItem colSpan={3}>
                                <b>Nr. do chamado: </b>
                                {item.numChamado}
                              </RowItem>
                              <RowItem colSpan={3}>
                                <b>Observação: </b>
                                {item.txtObservacao}
                              </RowItem>
                            </>
                          )}
                        </TableRow>
                      </>
                    );
                  })}
                </tbody>
              </CustTable>
            </TableContainer>
            <PaginationWrapper>
              <Pagination
                count={Math.ceil(data.length / 20)}
                page={page}
                onChange={(e, i) => setPage(i)}
                variant="outlined"
                shape="rounded"
                siblingCount={2}
              />
            </PaginationWrapper>
            <Section>
              <Button text="Aprovar e submeter negativação" onClick={submit} />
            </Section>
          </>
        )}
      </Container>
      {open && (
        <Modal
          onClose={() => setIdBoleto(-1)}
          onSubmit={onModalSubmit}
          values={values.find(e => e.id === idBoleto)!}
          justificativas={justificativas}
          limparObservacoes={limparObservacoes}
        />
      )}
    </>
  );
};
