import styled from 'styled-components';

export const Input = styled.input`
  background-color: #fff;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 32px;
  border: 1px solid #7e93954d;
  width: 100%;

  ::placeholder {
    color: #7e9395;
  }

  :disabled {
    background: #ebebeb;
    cursor: not-allowed;
  }
`;
