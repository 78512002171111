import React from 'react';
import { Drawer, Toolbar, makeStyles, createStyles } from '@material-ui/core';
import { DrawerContent } from './drawer-content';

const drawerWidth = 270;

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'hidden',
      marginTop: '3rem',

      '&:hover': {
        overflow: 'auto',
      },
    },
  }),
);

export function PermanentDrawer(): JSX.Element {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <DrawerContent />
      </div>
    </Drawer>
  );
}
