import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import ConfirmationModal from '../../../_core/_components/confirmation-modal';
import { AuthContext } from '../../../_main/contexts/auth';
import api from '../../../_core/api';
import Modal from './modal';
import { handleErrors, success, warning } from '../../../_core/services/toast';
import { Button, Form, Input, Option, Table } from '../../../_core/_components';

type Values = {
  flagAtivo?: boolean;
  flagIntegracao?: boolean;
  idGrupoCobranca?: number;
  nome: string;
  portifolio: string;
};

export const CadastroGrupoCobranca: React.FC = () => {
  const { id } = useContext(AuthContext);
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [grupoCobranca, setGrupoCobranca] = useState('');
  const [values, setValues] = useState<Values[]>([]);
  const [modal, setModal] = useState<Values>();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(-1);

  useEffect(() => {
    getShoppings();
    search();
  }, []);

  const getShoppings = async () => {
    try {
      const listShopping: any[] = [];
      const response = await api.get('/empresas/shoppings/simple-umpaged');
      response.data.map((item: any) => {
        listShopping.push({
          value: item.id,
          label: item.nome,
        });
        return null;
      });
      listShopping.sort((item1: any, item2: any) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppings(listShopping);
    } catch (error) {
      handleErrors(error);
    }
  };

  const search = async () => {
    setLoading(true);
    try {
      const { data } = await api.post('/grupo-cobranca/grupo/buscar', {
        nomeGrupoCobranca: grupoCobranca.length > 0 ? grupoCobranca : undefined,
        idUsuario: id,
      });
      if (_.isEmpty(data.content)) {
        warning('Não obteve resultados.');
      } else {
        setValues(data.content);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onChange = (index: number, type: 'nome' | 'portifolio', value: string) => {
    const arr = [...values];
    arr[index][type] = value;
    setValues(arr);
  };

  const add = () => {
    const arr = [...values];
    arr.unshift({ nome: '', portifolio: '' });
    setValues(arr);
  };

  const remove = async () => {
    const index = isConfirmationOpen;
    setLoading(true);
    setIsConfirmationOpen(-1);
    try {
      if (values[index].idGrupoCobranca) {
        await api.post('/grupo-cobranca/grupo/excluir', {
          idGrupoCobranca: values[index].idGrupoCobranca!,
        });
      }
      const arr = [...values];
      arr.splice(index, 1);
      setValues(arr);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const save = async (index: number) => {
    setLoading(true);
    try {
      await api.post('/grupo-cobranca/grupo/salvar', {
        idGrupoCobranca: values[index].idGrupoCobranca,
        nomeGrupoCobranca: values[index].nome.trim(),
        nomePortifolio: values[index].portifolio.trim(),
        idUsuario: id,
      });
      const { data } = await api.post('/grupo-cobranca/grupo/buscar', {
        nomeGrupoCobranca: grupoCobranca.length > 0 ? grupoCobranca : undefined,
        idUsuario: id,
      });
      setValues(data.content);
      success('Salvo com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const isAddDisabled = useMemo(() => {
    return values.some(item => item.idGrupoCobranca === undefined);
  }, [values]);

  const renderedData = useMemo(() => {
    if (values.length > 0) {
      return values.map((item, i) => ({
        nome: (
          <TextField
            value={item.nome}
            onChange={(e: any) => onChange(i, 'nome', e.target.value)}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              style: {
                borderRadius: 30,
              },
            }}
          />
        ),
        portifolio: (
          <TextField
            value={item.portifolio}
            onChange={(e: any) => onChange(i, 'portifolio', e.target.value)}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              style: {
                borderRadius: 30,
              },
            }}
          />
        ),
        salvar: <Button text="Salvar" onClick={() => save(i)} />,
        editar: <Button text="Editar" onClick={() => setModal(item)} />,
        excluir: (
          <IconButton onClick={() => setIsConfirmationOpen(i)}>
            <DeleteIcon />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [values]);

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <Form
        items={[
          <Input state={[grupoCobranca, setGrupoCobranca]} label="Grupo de cobrança" />,
          <Button text="Adicionar" onClick={add} disabled={isAddDisabled} />,
        ]}
        submitButton={<Button text="Pesquisar" onClick={search} />}
      />
      {renderedData.length > 0 && (
        <Table
          columns={[
            { label: 'Nome grupo', key: 'nome' },
            { label: 'Portfólio / Carteira', key: 'portifolio' },
            { label: 'Salvar', key: 'salvar' },
            { label: 'Editar', key: 'editar' },
            { label: 'Excluir', key: 'excluir' },
          ]}
          data={renderedData}
        />
      )}
      {modal && (
        <Modal onClose={() => setModal(undefined)} shoppings={shoppings} idGrupoCobranca={modal.idGrupoCobranca!} />
      )}
      <ConfirmationModal
        open={isConfirmationOpen >= 0}
        text="Deseja excluir o registro?"
        handleCancel={() => setIsConfirmationOpen(-1)}
        handleOk={remove}
      />
    </>
  );
};
