import React, { createContext, useReducer, useEffect, useContext } from 'react';
import api from '../../_core/api';
import { AuthContext } from './auth';

type CALCULO_INAD_DTO = {
  name: string;
  value: string;
};

type PropsParametrosCalculoInadimplenciaContext = {
  state: InitialStateType;
  dispatch: React.Dispatch<any>;
};

type InitialStateType = {
  date: string;
  processingDate: string;
  adjustmentReceiptStartDate: string;
  adjustmentReceiptEndDate: string;
  netReceiptDate: string;
  previousMonthNetReceiptDate: string;
  minimumNumberOfDays: string;
};

const initialState = {
  date: '',
  processingDate: '',
  adjustmentReceiptStartDate: '',
  adjustmentReceiptEndDate: '',
  netReceiptDate: '',
  previousMonthNetReceiptDate: '',
  minimumNumberOfDays: '',
};

function reducer(state: InitialStateType, action: { type: string; payload: any }) {
  switch (action.type) {
    case 'setState':
      return {
        date: action.payload.DATA_REFERENCIA,
        processingDate: action.payload.DATA_PROCESSAMENTO,
        adjustmentReceiptStartDate: action.payload.DATA_INICIO_FATURAMENTO,
        adjustmentReceiptEndDate: action.payload.DATA_FIM_FATURAMENTO,
        netReceiptDate: action.payload.DATA_RECEB_LIQ_MES,
        previousMonthNetReceiptDate: action.payload.DATA_RECEB_LIQ_MES_ANT,
        minimumNumberOfDays: action.payload.QTD_MIN_DIAS,
      };
    case 'setStateDefault':
      return {
        ...action.payload,
      };
    case 'setDate':
      return { ...state, date: action.payload };
    case 'setProcessingDate':
      return { ...state, processingDate: action.payload };
    case 'setAdjustmentReceiptStartDate':
      return { ...state, adjustmentReceiptStartDate: action.payload };
    case 'setAdjustmentReceiptEndDate':
      return { ...state, adjustmentReceiptEndDate: action.payload };
    case 'setNetReceiptDate':
      return { ...state, netReceiptDate: action.payload };
    case 'setPreviousMonthNetReceiptDate':
      return { ...state, previousMonthNetReceiptDate: action.payload };
    case 'setMinimumNumberOfDays':
      return { ...state, minimumNumberOfDays: action.payload };
    default:
      throw new Error();
  }
}

const DEFAULT_VALUE = {
  state: initialState,
  dispatch: () => null,
};
const ParametrosCalculoInadimplenciaContext = createContext<PropsParametrosCalculoInadimplenciaContext>(DEFAULT_VALUE);

const ParametrosCalculoInadimplenciaProvider: React.FC = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (token) {
      const getParameters = async () => {
        const response = await api.get('spredsheet/parameter/feature-name/CALCULO_INAD');
        let output: any = {};
        response.data.forEach((e: CALCULO_INAD_DTO) => {
          const { name, value } = e;
          output[name] = value;
        });
        dispatch({ type: 'setState', payload: output });
      };
      getParameters();
    }
  }, [token]);

  return (
    <ParametrosCalculoInadimplenciaContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ParametrosCalculoInadimplenciaContext.Provider>
  );
};

export { ParametrosCalculoInadimplenciaProvider };
export default ParametrosCalculoInadimplenciaContext;
