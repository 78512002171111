import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import * as S from '../styles';
import api from '../../../../_core/api';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, Input } from '../../../../_core/_components';
import { handleErrors } from '../../../../_core/services/toast';
import { INITIAL_STATE, Params, reducer } from './reducer';

const CONTAINER_STYLE = {
  alignItems: 'center',
  backgroundColor: '#f2f7f7',
  borderRadius: '5px',
  marginBottom: '1rem',
  padding: '0.5rem',
};

const OutrosParametros: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [params, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    const getValues = async () => {
      setLoading(true);
      try {
        const { data } = await api.get<Params>('/parametros/ajuizamento/outrosParametros/getOutrosParametros');
        dispatch({ type: 'SET_PARAMS', payload: data });
      } catch (error) {
        handleErrors(error);
      }
      setLoading(false);
    };
    getValues();
  }, []);

  const updateValues = async () => {
    setLoading(true);
    try {
      await api.post('/parametros/ajuizamento/outrosParametros/atualizarOutrosParametros', params);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const isDisabled = useMemo(() => {
    return ![
      params.flagConsideraBoletoAdm,
      params.flagAjzCcontratoInativo,
      params.flagAjzContratoClienteCorporativo,
      params.flagAjzContratoAcaoDespejo,
      params.flagAjzContratoAcaoExecucao,
    ].every(e => e !== -1);
  }, [params]);

  return (
    <S.ContentContainer>
      <FullScreenLoading isEnabled={loading} />
      <Grid container style={CONTAINER_STYLE}>
        <Grid item xs={6}>
          Considerar boletos administrativos
        </Grid>
        <Grid item xs={6}>
          <div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={params.flagConsideraBoletoAdm.toString()}
                onChange={e => dispatch({ type: 'SET_BOLETO_ADM', payload: parseInt(e.target.value, 10) })}
              >
                <FormControlLabel value="1" control={<Radio color="primary" />} label="Sim" />
                <FormControlLabel value="0" control={<Radio color="primary" />} label="Não" />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <Grid container style={CONTAINER_STYLE}>
        <Grid item xs={6}>
          Ajuizar contratos inativos
        </Grid>
        <Grid item xs={6}>
          <div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={params.flagAjzCcontratoInativo.toString()}
                onChange={e => dispatch({ type: 'SET_CONT_INATIVO', payload: parseInt(e.target.value, 10) })}
              >
                <FormControlLabel value="1" control={<Radio color="primary" />} label="Sim" />
                <FormControlLabel value="0" control={<Radio color="primary" />} label="Não" />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <Grid container style={CONTAINER_STYLE}>
        <Grid item xs={6}>
          Ajuizar contratos de clientes corporativos
        </Grid>
        <Grid item xs={6}>
          <div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={params.flagAjzContratoClienteCorporativo.toString()}
                onChange={e => dispatch({ type: 'SET_CONT_CLINTE_CORP', payload: parseInt(e.target.value, 10) })}
              >
                <FormControlLabel value="1" control={<Radio color="primary" />} label="Sim" />
                <FormControlLabel value="0" control={<Radio color="primary" />} label="Não" />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <Grid container style={CONTAINER_STYLE}>
        <Grid item xs={6}>
          Ajuizar contratos com ação de despejo
        </Grid>
        <Grid item xs={6}>
          <div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={params.flagAjzContratoAcaoDespejo.toString()}
                onChange={e => dispatch({ type: 'SET_CONT_ACAO_DESP', payload: parseInt(e.target.value, 10) })}
              >
                <FormControlLabel value="1" control={<Radio color="primary" />} label="Sim" />
                <FormControlLabel value="0" control={<Radio color="primary" />} label="Não" />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <Grid container style={CONTAINER_STYLE}>
        <Grid item xs={6}>
          Ajuizar contratos com ação de execução
        </Grid>
        <Grid item xs={6}>
          <div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={params.flagAjzContratoAcaoExecucao.toString()}
                onChange={e => dispatch({ type: 'SET_CONT_ACAO_EXE', payload: parseInt(e.target.value, 10) })}
              >
                <FormControlLabel value="1" control={<Radio color="primary" />} label="Sim" />
                <FormControlLabel value="0" control={<Radio color="primary" />} label="Não" />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <Grid container style={CONTAINER_STYLE}>
        <Grid item xs={6}>
          Prazo aprovação Shopping (Workflow)
        </Grid>
        <Grid item xs={6}>
          <Input
            state={[
              params.prazoAprovShoppingWorkflow,
              (value: React.SetStateAction<string>) => dispatch({ type: 'SET_PRAZO', payload: value }),
            ]}
          />
        </Grid>
      </Grid>
      <Button
        text="Salvar"
        onClick={updateValues}
        disabled={isDisabled || params.prazoAprovShoppingWorkflow.length === 0}
      />
    </S.ContentContainer>
  );
};

export default OutrosParametros;
