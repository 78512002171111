import React, { useMemo, useState } from 'react';
import { Container, SideMenu, ItemMenu } from './styles';
import RequisitosMinimos from './requisitos-minimos';
import TiposDeContrato from './tipos-contrato';
import TiposDeAcao from './tipos-acao';
import TiposDeRubrica from './tipos-rubrica';
import ExcecaoCPF from './excecao-cpf';
import OutrosParametros from './outros-parametros';
import AssociacaoTipoAcao from './associacao-tipo-acao';
import ContaContabil from './conta-contabil';

const MENU_LIST = [
  'Requisitos Mínimos',
  'Outros Parâmetros',
  'Associação para Tipo Ação',
  'Conta Contábil',
  'Exceção CPF/CNPJ',
  'Tipo(s) de Contrato',
  'Tipo(s) de Ação',
  'Tipo(s) de Rubrica',
];

export const CadastroAjuizamento: React.FC = () => {
  const [menu, setMenu] = useState(0);

  const menuItem = useMemo(() => {
    switch (menu) {
      case 0:
        return <RequisitosMinimos />;
      case 1:
        return <OutrosParametros />;
      case 2:
        return <AssociacaoTipoAcao />;
      case 3:
        return <ContaContabil />;
      case 4:
        return <ExcecaoCPF />;
      case 5:
        return <TiposDeContrato />;
      case 6:
        return <TiposDeAcao />;
      case 7:
        return <TiposDeRubrica />;
      case 8:
        return null;
      case 9:
        return null;
      default:
        return null;
    }
  }, [menu]);

  return (
    <Container>
      <SideMenu>
        {MENU_LIST.map((item: any, i: number) => (
          <ItemMenu key={MENU_LIST[i]} onClick={() => setMenu(i)} active={i === menu}>
            {item}
          </ItemMenu>
        ))}
      </SideMenu>

      {menuItem}
    </Container>
  );
};
