/* eslint-disable prefer-const */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactSelect, { ValueContainerProps, components } from 'react-select';
import { Option } from './types';

interface Props {
  state: [Option[], React.Dispatch<React.SetStateAction<Option[]>>];
  placeholder?: string;
  options: Option[];
  disabled?: boolean;
}

const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
  let [values, input] = children as any;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? '' : 's';
    values = `${values.length} item${plural} selecionado${plural}`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

export function Select({
  state: [value, setValue],
  placeholder = 'Selecione',
  options,
  disabled = false,
}: Props): JSX.Element {
  const onChange = (option: readonly Option[]) => {
    setValue(option.map(e => e));
  };

  return (
    <ReactSelect
      value={value}
      onChange={onChange}
      hideSelectedOptions={false}
      isMulti
      isSearchable
      isClearable
      options={options}
      placeholder={placeholder}
      menuPortalTarget={document.body}
      closeMenuOnSelect={false}
      styles={{
        control: (base, state) => ({
          ...base,
          height: 40,
          minHeight: 40,
          borderRadius: 30,
          boxShadow: state.isFocused ? '0 0 0 1px #31c6d3' : 'none',
          borderColor: state.isFocused ? '#31c6d3' : 'hsl(0, 0%, 80%)',
          '&:hover': {
            borderColor: state.isFocused ? '' : 'black',
          },
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        valueContainer: base => ({ ...base, flexWrap: 'nowrap' }),
        multiValueRemove: base => ({ ...base, display: 'none' }),
        multiValue: base => ({ ...base, paddingRight: 3 }),
      }}
      noOptionsMessage={() => ''}
      components={{ ValueContainer }}
      isDisabled={disabled}
    />
  );
}
