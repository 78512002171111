import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import { AxiosResponse } from 'axios';
import Tooltip from '@material-ui/core/Tooltip';
import * as S from './styles';
import { trackJudgmentActionsDetails, submitAtualizacao } from '../services';
import { AcompanhamentoAjuizamentoItem, AjuizamentoDetalhado } from '../types';
import LoadingIcon from '../../../../assets/loader.jsx';
import TickIcon from '../../../../assets/tick.jsx';
import CloseIcon from '../../../../assets/close.jsx';
import { toReal } from '../../../../_core/services/formaters';
import CurrencyInput from '../cancelamento/currency-input';
import CheckImg from '../../../../assets/Success.png';
import SaveImg from '../../../../assets/Diskete.png';
import { warning } from '../../../../_core/services/toast';
import { Button } from '../../../../_core/_components';

type Props = {
  isOpen: boolean;
  onCloseDetalhamento: Function;
  ajuizamentoADetalhar?: AcompanhamentoAjuizamentoItem;
  onClickUpdateFase: Function;
  onCancelamento: Function;
};

const Detalhamento: React.FC<Props> = ({
  isOpen,
  onCloseDetalhamento,
  ajuizamentoADetalhar,
  onClickUpdateFase,
  onCancelamento,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [ajuizamento, setAjuizamento] = useState<AjuizamentoDetalhado>();
  const [saldoDevedorCorrigido, setSaldoDevedorCorrigido] = useState(0); // aqui eu sempre vou armazenar o valor multiplicado por 100, pois o componente de currency não aceita floats.
  const [loadingSaldoDevedor, setLoadingSaldoDevedor] = useState(false);

  async function asyncWrapper(codigo: number) {
    setLoading(true);
    const res: AxiosResponse<AjuizamentoDetalhado> = await trackJudgmentActionsDetails(codigo);
    setAjuizamento(res.data);
    setSaldoDevedorCorrigido(res.data.saldoDevedorCorrigido * 100);
    setLoading(false);
  }

  useEffect(() => {
    if (isOpen && ajuizamentoADetalhar) {
      asyncWrapper(ajuizamentoADetalhar.codigoControle);
    }
  }, [isOpen]);

  const handleSaldoDevedorSubmition = async () => {
    setLoadingSaldoDevedor(true);
    const payload = {
      fases: ajuizamento?.fases,
      saldoDevedorCorrigido: saldoDevedorCorrigido / 100,
    };
    try {
      await submitAtualizacao(ajuizamento!.idAcaoAjuizamento, payload);
      const newAjuizamento: AjuizamentoDetalhado = {
        ...ajuizamento!,
        saldoDevedorCorrigido: saldoDevedorCorrigido / 100,
      };
      setAjuizamento(newAjuizamento);
      setLoadingSaldoDevedor(false);
    } catch (e) {
      warning('Não foi possível atualizar');
      setLoadingSaldoDevedor(false);
    }
  };

  const onChangeSaldoDevedor = (n: number) => {
    setSaldoDevedorCorrigido(n);
  };

  const handleDetalharClick = () => {
    if (ajuizamento?.idStatus === 11) {
      alert('Desistencia da ação');
      return;
    }
    onClickUpdateFase();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={isOpen}
      onClose={() => onCloseDetalhamento()}
      aria-labelledby="max-width-dialog-title"
    >
      {isLoading && (
        <S.LoadingArea>
          <LoadingIcon />
        </S.LoadingArea>
      )}

      <S.DialogContent>
        <S.Header>
          <S.Title>Controle de Ações Ajuizadas - Por Contrato</S.Title>
          {/* @ts-ignore */}
          <CloseIcon click={() => onCloseDetalhamento()} color="#fff" />
        </S.Header>
        <S.MainContent>
          <S.PrincipalSection>
            <S.Section>
              <S.SectionTitle>Principal</S.SectionTitle>
              <S.Fields>
                <S.RazaoField>
                  <S.FieldLabel>Razão Social</S.FieldLabel>
                  <S.FieldValue>{ajuizamento?.razaoSocial}</S.FieldValue>
                </S.RazaoField>
                <S.FieldFlex>
                  <S.FieldLabel>Origem</S.FieldLabel>
                  <S.FieldValue>{ajuizamento?.origem}</S.FieldValue>
                </S.FieldFlex>
                <S.FieldFlex>
                  <S.FieldLabel>Shopping</S.FieldLabel>
                  <S.FieldValue>{ajuizamento?.nomeShopping}</S.FieldValue>
                </S.FieldFlex>

                <S.FieldFlex>
                  <S.FieldLabel>Nome Fantasia</S.FieldLabel>
                  <S.FieldValue>{ajuizamento?.nomeFantasia}</S.FieldValue>
                </S.FieldFlex>
                <S.FieldFlex>
                  <S.FieldLabel>LUC</S.FieldLabel>
                  <S.FieldValue>{ajuizamento?.luc}</S.FieldValue>
                </S.FieldFlex>
                <S.FieldFlex>
                  <S.FieldLabel>Número Contato</S.FieldLabel>
                  <S.FieldValue>{ajuizamento?.numeroContrato}</S.FieldValue>
                </S.FieldFlex>
                <S.FieldFlex>
                  <S.FieldLabel>CPF/CNPJ</S.FieldLabel>
                  <S.FieldValue>{ajuizamento?.cpfCnpj}</S.FieldValue>
                </S.FieldFlex>
              </S.Fields>
            </S.Section>
          </S.PrincipalSection>
          <S.FasesSection>
            <S.Section>
              <S.SectionTitle>Fases</S.SectionTitle>
              <S.DetalhamentoFases>
                <S.FasesItems>
                  {ajuizamento?.fases.map(fase => {
                    return (
                      <S.FasesItem>
                        {fase.faseConcluida ? <TickIcon /> : <S.FaseBlank />}
                        <S.FaseDados>
                          <S.FaseName>{fase.nomeFase}</S.FaseName>
                          <S.FaseData>
                            <Tooltip title="Data de criação">
                              <span>{fase.dataConclusao ? moment(fase.dataConclusao).format('DD/MM/YYYY') : '-'}</span>
                            </Tooltip>
                          </S.FaseData>
                        </S.FaseDados>
                      </S.FasesItem>
                    );
                  })}
                </S.FasesItems>
                <S.FaseStatusSection>
                  <S.FasesStatus>
                    <S.FaseData>Status do processo</S.FaseData>
                    <S.FaseName>{ajuizamento?.status}</S.FaseName>
                  </S.FasesStatus>
                  <S.DetalhamentoAction>
                    <S.DetalhamentoButton onClick={() => handleDetalharClick()}>DETALHAR</S.DetalhamentoButton>
                  </S.DetalhamentoAction>
                </S.FaseStatusSection>
              </S.DetalhamentoFases>
            </S.Section>
          </S.FasesSection>
          <S.SaldoSection>
            <S.Section>
              <S.SectionTitle>Saldo</S.SectionTitle>
              <S.Fields>
                <S.FieldSaldo>
                  <S.FieldLabel>Valor do saldo em aberto</S.FieldLabel>
                  <S.SaldoValue>{toReal(ajuizamento?.saldoAbertoTotal)}</S.SaldoValue>
                </S.FieldSaldo>
                <S.FieldSaldo>
                  <S.FieldLabel>Saldo Devedor Corrigido</S.FieldLabel>
                  <S.Toggle>
                    <CurrencyInput value={saldoDevedorCorrigido || 0} onValueChange={onChangeSaldoDevedor} />
                    {!loadingSaldoDevedor &&
                      (saldoDevedorCorrigido / 100 !== ajuizamento?.saldoDevedorCorrigido ? (
                        <S.SaveIcon onClick={handleSaldoDevedorSubmition} src={SaveImg} alt="check icon" />
                      ) : (
                        <S.CheckIcon onClick={() => false} src={CheckImg} alt="ok icon" />
                      ))}

                    {loadingSaldoDevedor && <LoadingIcon />}
                  </S.Toggle>
                </S.FieldSaldo>
              </S.Fields>
            </S.Section>
          </S.SaldoSection>

          <S.ActionsSection>
            <S.ActionsLabel>
              {ajuizamento?.idStatus === 11 ? 'Ação já cancelada' : 'Informar Cancelamento de Ação'}
            </S.ActionsLabel>
            {ajuizamento?.idStatus !== 11 && (
              <S.ActionsButtons>
                <Button onClick={() => onCancelamento('desistencia')} text="Desistência da Ação" />
                <Button onClick={() => onCancelamento('excecao')} text="Inclusão como Exceção" />
              </S.ActionsButtons>
            )}
          </S.ActionsSection>
        </S.MainContent>
      </S.DialogContent>
    </Dialog>
  );
};

export default Detalhamento;
