import api from '../../../_core/api';

export const trackJudgmentActions = (params: any) => {
  return api.get('/judgment/track-judgment-actions', {
    params,
  });
};

export const trackJudgmentActionsDetails = (codigoControle: number) => {
  return api.get(`/judgment/track-judgment-actions/${codigoControle}`);
};

export const trackJudgmentActionsTotals = (params: any) =>
  api.get('/judgment/track-judgment-actions-totais', { params });

export const getShoppings = () => {
  return api.get('/charge-legal-information/shoppings');
};

export const submitAtualizacao = (codigoControle: number, payload: any) => {
  return api.put(`/judgment/track-judgment-actions/${codigoControle}`, payload);
};

export const submitDesistencia = (codigoControle: number, payload: any) => {
  return api.put(`/judgment/track-judgment-actions/${codigoControle}/renunciation`, payload);
};

export const submitExcecao = (codigoControle: number, payload: any) => {
  return api.put(`/judgment/track-judgment-actions/${codigoControle}/exception`, payload);
};

export const getTypeOfCredity = () => {
  return api.get(`/filters/type-of-credit`);
};

export const getActionStatus = () => {
  return api.get(`/filters/action-status`);
};

export const getSources = async (): Promise<any> => {
  const response = await api.get('/filters/source');
  return response;
};
