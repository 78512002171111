import React, { useMemo, useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { KeyAccounts } from './interfaces';
import { Form, Input, Button, Option, SingleSelect } from '../../../../_core/_components';

type ModalProps = {
  options: string[];
  onClose: () => void;
  onSubmit: (values: any) => void;
  values?: KeyAccounts;
};

const IS_KA_OPTIONS = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
];

const Modal: React.FC<ModalProps> = ({ onClose, onSubmit, values, options }) => {
  const [id] = useState(values?.id ?? '');
  const [group, setGroup] = useState(values?.group ?? '');
  const [brand, setBrand] = useState(values?.brand ?? '');
  const [isKeyAccount, setIsKeyAccount] = useState(values?.isKeyAccount ?? true);

  const handleSubmit = () => onSubmit({ id, group, brand, isKeyAccount });

  const isValid = useMemo(() => group && brand, [group, brand]);

  const brandSelect = useMemo(() => {
    const arr: Option[] = options.map((e, i) => ({ value: i, label: e }));
    const selectedValue = arr.find(e => e.label === brand);
    return <SingleSelect placeholder="Marca" state={[selectedValue, option => setBrand(option.label)]} options={arr} />;
  }, [brand]);

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          {values ? 'Editar' : 'Criar'} KA
        </span>
        <Grid>
          <Form
            items={[
              brandSelect,
              <Input label="Grupo" placeholder="Grupo" state={[group, setGroup]} />,
              <SingleSelect
                placeholder="KA"
                state={[
                  IS_KA_OPTIONS.find(e => e.value === isKeyAccount),
                  option => setIsKeyAccount(option.value as boolean),
                ]}
                options={IS_KA_OPTIONS}
              />,
            ]}
            submitButton={<Button disabled={!isValid} text="Salvar" onClick={handleSubmit} />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
