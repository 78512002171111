export type ActionsTypes =
  | 'SET_PARAMS'
  | 'SET_BOLETO_ADM'
  | 'SET_CONT_INATIVO'
  | 'SET_CONT_CLINTE_CORP'
  | 'SET_CONT_ACAO_DESP'
  | 'SET_CONT_ACAO_EXE'
  | 'SET_PRAZO';

export interface Params {
  flagConsideraBoletoAdm: number;
  flagAjzCcontratoInativo: number;
  flagAjzContratoClienteCorporativo: number;
  flagAjzContratoAcaoDespejo: number;
  flagAjzContratoAcaoExecucao: number;
  prazoAprovShoppingWorkflow: string;
}

export const INITIAL_STATE = {
  flagConsideraBoletoAdm: -1,
  flagAjzCcontratoInativo: -1,
  flagAjzContratoClienteCorporativo: -1,
  flagAjzContratoAcaoDespejo: -1,
  flagAjzContratoAcaoExecucao: -1,
  prazoAprovShoppingWorkflow: '',
};

export function reducer(state: Params, { type, payload }: { type: ActionsTypes; payload: any }): any {
  switch (type) {
    case 'SET_PARAMS':
      return {
        ...payload,
      };
    case 'SET_BOLETO_ADM':
      return {
        ...state,
        flagConsideraBoletoAdm: payload,
      };
    case 'SET_CONT_INATIVO':
      return {
        ...state,
        flagAjzCcontratoInativo: payload,
      };
    case 'SET_CONT_CLINTE_CORP':
      return {
        ...state,
        flagAjzContratoClienteCorporativo: payload,
      };
    case 'SET_CONT_ACAO_DESP':
      return {
        ...state,
        flagAjzContratoAcaoDespejo: payload,
      };
    case 'SET_CONT_ACAO_EXE':
      return {
        ...state,
        flagAjzContratoAcaoExecucao: payload,
      };
    case 'SET_PRAZO':
      return {
        ...state,
        prazoAprovShoppingWorkflow: payload,
      };
    default:
      return state;
  }
}
