import React, { useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FileSaver from 'file-saver';
import api from '../../../_core/api';
import {
  BodyModalContainer,
  Button,
  Title,
  SecondaryButton,
  SubmitContainer,
  UploadFileRow,
  UploadLabel,
  TitleAnexo,
  Mandatory,
  UploadIconImg,
  ModalTitle,
} from './styles';
import { Values } from './types';
import UpIcon from '../../../assets/uploadIcon.png';
import AlertIcon from '../../../assets/alertIcon';
import { handleErrors } from '../../../_core/services/toast';
import { verificaTamanhoEmBytes } from '../../../_core/services/permissions';
import { Input, SingleSelect } from '../../../_core/_components';

type ModalProps = {
  onClose: () => void;
  onSubmit: (values: any) => void;
  limparObservacoes: () => Promise<void>;
  values: Values;
  justificativas: any[];
};

const Modal: React.FC<ModalProps> = ({ onClose, onSubmit, limparObservacoes, values, justificativas }: ModalProps) => {
  const ref = useRef<any>();
  const [, setLoading] = useState(false);
  const [justificativa, setJustificativa] = useState(values.justificativa);
  const [observacao, setObservacao] = useState(values.observacao);
  const [nrChamado, setNrChamado] = useState(values.nrChamado);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileSizeError, setFileSizeError] = useState(false);

  const disabled = useMemo(() => values.justificativa !== 999, [values]);

  const camposObrigatorios = useMemo(() => {
    if (justificativa) {
      const { campoObrigatorio } = justificativas.find((e: any) => e.value === justificativa);

      if (campoObrigatorio === 'NAO_OBRIGATORIO') {
        return '';
      }
      if (campoObrigatorio === 'CHAMADO' && !nrChamado) {
        return '*Número do chamado é obrigatório.';
      }
      if (campoObrigatorio === 'CHAMADO_OU_ANEXO' && !nrChamado && file === undefined && values.anexo === '') {
        return '*Número do chamado ou Anexo são obrigatórios.';
      }
      if (campoObrigatorio === 'ANEXO' && file === undefined && values.anexo === '') {
        return '*Anexo é obrigatório.';
      }

      return '';
    }
    return '';
  }, [justificativa, nrChamado, file, values]);

  const isValid = useMemo(() => {
    if (justificativa && justificativa !== 999) {
      const { campoObrigatorio } = justificativas.find((e: any) => e.value === justificativa);
      switch (campoObrigatorio) {
        case 'NAO_OBRIGATORIO':
          return true && justificativa;
        case 'CHAMADO':
          return !!nrChamado;
        case 'CHAMADO_OU_ANEXO':
          return !!nrChamado || file !== undefined;
        case 'ANEXO':
          return file !== undefined;
        default:
          return true && justificativa;
      }
    }
    return true;
  }, [justificativa, nrChamado, file]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const blob = e.target.files ? e.target.files[0] : undefined;
    if (blob === undefined) return;
    if (verificaTamanhoEmBytes(blob!.size)) {
      setFileSizeError(true);
      setFile(undefined);
      return;
    }
    setFileSizeError(false);
    setFile(blob);
  };

  const onClickReset = () => {
    ref.current.value = '';
    setFile(undefined);
  };

  const onClickAnexo = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/workflow/serasa/serasa-upload/historico/download/${values.id}`, {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data]);
      FileSaver.saveAs(blob, values.anexo);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };
  console.log(!isValid, disabled, !fileSizeError);
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <ModalTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </ModalTitle>
      <DialogContent>
        <BodyModalContainer>
          <Title
            onClick={() => window.open(`${process.env.PUBLIC_URL}/assets/description-justificativas.png`, '_blank')}
          >
            JUSTIFICATIVA
            <span data-tip="Clique para mais detalhes sobre as justificativas">
              <AlertIcon />
            </span>
            <ReactTooltip place="bottom" type="dark" effect="float" />
          </Title>
          <SingleSelect
            state={[
              justificativas.find(e => e.value === justificativa),
              option => setJustificativa(option.value as number),
            ]}
            options={justificativas}
            disabled={disabled}
          />
          <Input label="OBSERVAÇÃO" state={[observacao, setObservacao]} disabled={disabled} />
          <Input label="NR. CHAMADO / NEG CRM" state={[nrChamado, setNrChamado]} disabled={disabled} />
          <Title>Anexo</Title>
          {values.anexo && (
            <TitleAnexo onClick={onClickAnexo}>
              Anexo atual:
              {values.anexo}
            </TitleAnexo>
          )}
          <UploadLabel htmlFor="selecao-arquivo-input" id="selecao-arquivo-label">
            Selecionar um arquivo
            <UploadIconImg src={UpIcon} />
          </UploadLabel>
          <input id="selecao-arquivo-input" type="file" onChange={onFileChange} ref={ref} hidden disabled={disabled} />
          {fileSizeError && (
            <UploadFileRow>
              <Title>O arquivo não pode exceder 1MB.</Title>
            </UploadFileRow>
          )}
          {file && !fileSizeError && (
            <UploadFileRow>
              <Title>{file?.name}</Title>
              <Title id="remove-span" onClick={onClickReset}>
                x
              </Title>
            </UploadFileRow>
          )}

          <Mandatory>{camposObrigatorios}</Mandatory>

          <SubmitContainer>
            <SecondaryButton onClick={limparObservacoes}>Limpar observações</SecondaryButton>
            <Button
              disabled={!isValid || disabled || fileSizeError}
              onClick={() => onSubmit({ justificativa, observacao, file, nrChamado })}
            >
              Salvar
            </Button>
          </SubmitContainer>
        </BodyModalContainer>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
