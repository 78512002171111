import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { AxiosResponse } from 'axios';
import * as S from './styles';

import CloseIcon from '../../../../assets/close.jsx';
import CustomCheckbox from './custom-checkbox';
import { trackJudgmentActionsDetails, submitAtualizacao, getTypeOfCredity, getActionStatus } from '../services';
import { AcompanhamentoAjuizamentoItem, AjuizamentoDetalhado } from '../types';
import LoadingIcon from '../../../../assets/loader.jsx';
import {
  STATUS_OPTIONS,
  GUIAS_PAGAMENTO,
  NUMERO_PO,
  NUMERO_CHAMADO_PGTO,
  COMPROVANTES_PAGAMENTOS,
  CHAMADOS_ABERTOS,
} from './initialValues';
import { Button, SingleSelect, Option, Select, Input } from '../../../../_core/_components';
import { mascaraNumeracaoPtBr } from '../../../../_core/masks';
import { handleErrors, success } from '../../../../_core/services/toast';

type Props = {
  isOpen: boolean;
  onCloseFaseUpdate: Function;
  ajuizamentoAAtualizar?: AcompanhamentoAjuizamentoItem;
};

interface ICredityAndAction {
  id: number;
  nome: string;
}

const FaseUpdate: React.FC<Props> = ({ isOpen, onCloseFaseUpdate, ajuizamentoAAtualizar }) => {
  const [isLoading, setLoading] = useState(true);
  const [documentacaoRecebida, setDocumentacaoRecebida] = useState(false);
  const [ajuizamento, setAjuizamento] = useState<AjuizamentoDetalhado>();
  const [guiasPagamento, setGuiasPagamento] = useState(GUIAS_PAGAMENTO);
  const [numeroPO, setNumeroPO] = useState(NUMERO_PO);
  const [numeroChamadoPagamento, setNumeroChamadoPagamento] = useState(NUMERO_CHAMADO_PGTO);
  const [comprovantesPagamentos, setComprovantesPagamentos] = useState(COMPROVANTES_PAGAMENTOS);
  const [numeroChamadoDoc, setNumeroChamadoDoc] = useState('');
  const [chamadosAbertos, setChamadosAbertos] = useState(CHAMADOS_ABERTOS);
  const [typeOfCredity, setTypeOfCredit] = useState<Option[]>([]);
  const [actionStatus, setActionStatus] = useState<Option[]>([]);
  const [statusAcao, setStatusAcao] = useState<Option[]>([]);
  const [tipoDeCreditoOptions, setTipoDeCreditoOptions] = useState<Option[]>([]);
  const [botaoSubmitDisabled, setBotaoSubmitDisabled] = useState(true);
  const [idFase, setIdFase] = useState(0);

  function formatDate(data: string, formato: string) {
    if (formato === 'pt-br') {
      return data.substr(0, 10).split('-').reverse().join('/');
    }
    return data.substr(0, 10).split('/').reverse().join('-');
  }

  function formatarData(data: string) {
    // 05/02/2020 para 2020-02-05
    try {
      if (data.length == 10) {
        return `${data.substring(6, 10)}-${data.substring(3, 5)}-${data.substring(0, 2)}`;
      }
      return data;
    } catch (error) {
      return '';
    }
  }

  function zerarCampos() {
    setChamadosAbertos(CHAMADOS_ABERTOS);
    setNumeroChamadoDoc('');
    setComprovantesPagamentos(COMPROVANTES_PAGAMENTOS);
    setNumeroChamadoPagamento(NUMERO_CHAMADO_PGTO);
    setNumeroPO(NUMERO_PO);
    setGuiasPagamento(GUIAS_PAGAMENTO);
    setDocumentacaoRecebida(false);
    setTypeOfCredit([]);
    setStatusAcao([]);
  }

  function fecharModal() {
    onCloseFaseUpdate();
    zerarCampos();
  }

  async function asyncWrapper(codigo: number) {
    setLoading(true);
    const res: AxiosResponse<AjuizamentoDetalhado> = await trackJudgmentActionsDetails(codigo);
    setAjuizamento(res.data);
    atribuiParamsSalvos(res.data);
    setLoading(false);
    setDocumentacaoRecebida(res.data.fases[0].documentacaoRecebida);
    setNumeroChamadoDoc(res.data.fases[0].numeroChamadoDocumentacao);
  }

  useEffect(() => {
    if (isOpen && ajuizamentoAAtualizar) {
      asyncWrapper(ajuizamentoAAtualizar.codigoControle);
    }
  }, [isOpen]);

  async function asyncTypeOfCredity() {
    const res: AxiosResponse<ICredityAndAction[]> = await getTypeOfCredity();
    const tempTypeOfCredity: Option[] = [];
    res.data.forEach((creditType: ICredityAndAction) => {
      tempTypeOfCredity.push({
        value: creditType.id,
        label: creditType.nome,
      });
    });
    setTipoDeCreditoOptions(tempTypeOfCredity);
  }
  async function asyncActionStatus() {
    const res: AxiosResponse<ICredityAndAction[]> = await getActionStatus();
    const tempActionStatus: Option[] = [];
    res.data.forEach((actStats: ICredityAndAction) => {
      tempActionStatus.push({
        value: actStats.id,
        label: actStats.nome,
      });
    });
    setActionStatus(tempActionStatus);
  }

  useEffect(() => {
    asyncTypeOfCredity();
    asyncActionStatus();
  }, []);

  const atribuiParamsSalvos = (ajz: AjuizamentoDetalhado) => {
    /* Atribui guias de pagamento */
    setGuiasPagamento(GUIAS_PAGAMENTO);
    const guias = ajz?.fases.find(fase => fase.idFase === 2)?.guiasPagamento;

    if (guias) {
      const newGuias = guiasPagamento.map((guia, index) => {
        return { valorGuia: guias[index] || '' };
      });
      setGuiasPagamento(newGuias);
    }

    const idStatusAcao = ajz?.idStatusAcao;
    if (idStatusAcao) {
      setStatusAcao(actionStatus.filter(actStatus => actStatus.value == idStatusAcao));
    }

    const chamados = ajz.fases.find(fase => fase.idFase === 6)?.chamados;
    if (chamados) {
      const idEspecieCredito = chamados.find((chamado: any) => chamado.id)?.idEspecieCredito;
      if (idEspecieCredito) {
        setTypeOfCredit(typeOfCredity.filter(credType => credType.value == idEspecieCredito));
      }
    }

    /* Atribui PO */
    setNumeroPO(NUMERO_PO);
    const POs = ajz?.fases.find(fase => fase.idFase === 3)?.numeroPO;

    if (POs) {
      const newPOs = numeroPO.map((nPO, index) => {
        // @ts-ignore
        if (POs[index]) return { numero: POs[index].numero };
        return { numero: '' };
      });

      setNumeroPO(newPOs);
    }

    /* Atribui Chamado para pagamento */
    setNumeroChamadoPagamento(NUMERO_CHAMADO_PGTO);
    const chamdosPGMT = ajz?.fases.find(fase => fase.idFase === 4)?.numeroChamadoPagamento;

    if (chamdosPGMT) {
      const newChamados = numeroChamadoPagamento.map((guia, index) => {
        return { numeroChamado: chamdosPGMT[index] || '' };
      });
      setNumeroChamadoPagamento(newChamados);
    }

    /* Atribui comprovante Chamado para pagamento */
    setComprovantesPagamentos(COMPROVANTES_PAGAMENTOS);
    const comprovantesPGMT = ajz?.fases.find(fase => fase.idFase === 5)?.comprovantesPagamentos;

    if (comprovantesPGMT) {
      const newChamados = numeroChamadoPagamento.map((guia, index) => {
        return { comprovantes: comprovantesPGMT[index] || false };
      });
      setComprovantesPagamentos(newChamados);
    }

    /* Atribui Chamados abertos */
    setNumeroChamadoDoc('');
    setChamadosAbertos(CHAMADOS_ABERTOS);
    const newChamados = ajz?.fases.find(fase => fase.idFase === 6)?.chamados;
    if (newChamados) {
      const updatedChamados = chamadosAbertos.map((currChamado, index) => {
        return newChamados[index] || currChamado;
      });
      const tempChamados = updatedChamados.map((chamado: any) => {
        return { ...chamado };
      });
      setChamadosAbertos(tempChamados);
    }
  };

  const changeGuia = (e = '', idx: number) => {
    const newGuias = guiasPagamento.map((guia, index) => {
      if (idx === index) {
        return { valorGuia: e };
      }
      return guia;
    });
    setGuiasPagamento(newGuias);
  };

  const changePO = (e: string, idx: number) => {
    const newValues = numeroPO.map((guia, index) => {
      if (idx === index) {
        return { numero: e };
      }
      return guia;
    });
    setNumeroPO(newValues);
  };

  const changeNumeroChamadoPgto = (e: string, idx: number) => {
    const newValues = numeroChamadoPagamento.map((guia, index) => {
      if (idx === index) {
        return { numeroChamado: e };
      }
      return guia;
    });
    setNumeroChamadoPagamento(newValues);
  };

  const changeComprovantePagamento = (idx: number) => {
    if (numeroChamadoPagamento[idx].numeroChamado) {
      const newValues = comprovantesPagamentos.map((guia, index) => {
        if (idx === index) {
          return { comprovantes: !guia.comprovantes };
        }
        return guia;
      });

      setComprovantesPagamentos(newValues);
    }
  };

  const updateChamadoValue = (value: any, idx: number, atribute: string) => {
    const newValues = chamadosAbertos.map((chamado, index) => {
      if (index === idx) {
        if (atribute == 'dataAjuizamento') {
          return {
            ...chamado,
            [atribute]: formatarData(value),
          };
        }
        return {
          ...chamado,
          [atribute]: value,
        };
      }
      return chamado;
    });

    setChamadosAbertos(newValues);
  };

  function getChamadosAbertos() {
    const chamadoTemp = chamadosAbertos
      .filter(chamado => chamado.numeroChamadoAjuizamento)
      .map(chamadoAberto => {
        return { ...chamadoAberto, idEspecieCredito: typeOfCredity[0]?.value };
      });
    return chamadoTemp;
  }

  function temFaseId5() {
    if (ajuizamento?.fases.find(fase => fase.idFase == 5)) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (testarCamposPreenchidos()) {
      setBotaoSubmitDisabled(false);
    } else {
      setBotaoSubmitDisabled(true);
    }
  }, [
    documentacaoRecebida,
    guiasPagamento,
    numeroPO,
    numeroChamadoPagamento,
    comprovantesPagamentos,
    chamadosAbertos,
    statusAcao,
    typeOfCredity,
  ]);

  function testarCamposPreenchidos() {
    const list = [];
    if (documentacaoRecebida) {
      if (temFaseId5()) {
        const guiasTemp = guiasPagamento.slice(0, ajuizamento?.fases[0].qtdRegistros).filter((guia, idx) => {
          if (guia.valorGuia) {
            return guia;
          }
        });
        if (guiasTemp.length == ajuizamento?.fases[0].qtdRegistros || guiasTemp.length == 0) {
          list.push(true);
        } else {
          list.push(false);
        }

        const numeroPOTemp = guiasPagamento.slice(0, ajuizamento?.fases[0].qtdRegistros).filter((guia, idx) => {
          if (numeroPO[idx].numero) {
            return numeroPO[idx];
          }
        });
        if (numeroPOTemp.length == ajuizamento?.fases[0].qtdRegistros || numeroPOTemp.length == 0) {
          list.push(true);
        } else {
          list.push(false);
        }

        const chamadoPagamentoTemp = guiasPagamento.slice(0, ajuizamento?.fases[0].qtdRegistros).filter((guia, idx) => {
          if (numeroChamadoPagamento[idx].numeroChamado) {
            return numeroChamadoPagamento[idx];
          }
        });
        if (chamadoPagamentoTemp.length == ajuizamento?.fases[0].qtdRegistros || chamadoPagamentoTemp.length == 0) {
          list.push(true);
        } else {
          list.push(false);
        }

        const comprovantesPagamentosTemp = guiasPagamento
          .slice(0, ajuizamento?.fases[0].qtdRegistros)
          .filter((guia, idx) => {
            if (comprovantesPagamentos[idx].comprovantes) {
              return comprovantesPagamentos[idx];
            }
          });
        if (
          comprovantesPagamentosTemp.length == ajuizamento?.fases[0].qtdRegistros ||
          comprovantesPagamentosTemp.length == 0
        ) {
          list.push(true);
        } else {
          list.push(false);
        }
      }

      const numeroChamadoAjuizamentoTemp = chamadosAbertos
        .slice(0, ajuizamento?.fases[0]?.qtdRegistros)
        .filter((chamado, idx) => {
          if (chamado.numeroChamadoAjuizamento) {
            return chamado;
          }
        });
      if (
        numeroChamadoAjuizamentoTemp.length == ajuizamento?.fases[0].qtdRegistros ||
        numeroChamadoAjuizamentoTemp.length == 0
      ) {
        list.push(true);
      } else {
        list.push(false);
      }

      const selectedChamadoTemp = chamadosAbertos
        .slice(0, ajuizamento?.fases[0]?.qtdRegistros)
        .filter((chamado, idx) => {
          if (chamado.statusChamadoAjuizamento) {
            return chamado;
          }
        });
      if (selectedChamadoTemp.length == ajuizamento?.fases[0].qtdRegistros || selectedChamadoTemp.length == 0) {
        list.push(true);
      } else {
        list.push(false);
      }

      const protocoloTemp = chamadosAbertos.slice(0, ajuizamento?.fases[0]?.qtdRegistros).filter((chamado, idx) => {
        if (chamado.protocolo) {
          return chamado;
        }
      });
      if (protocoloTemp.length == ajuizamento?.fases[0].qtdRegistros || protocoloTemp.length == 0) {
        list.push(true);
      } else {
        list.push(false);
      }

      if (
        getDataAjuizamentosPreenchido().length == ajuizamento?.fases[0].qtdRegistros ||
        getDataAjuizamentosPreenchido().length == 0
      ) {
        list.push(true);
      } else {
        list.push(false);
      }

      if (getDataAjuizamentosPreenchido().length == ajuizamento?.fases[0].qtdRegistros) {
        if (statusAcao[0]?.value) {
          list.push(true);
        } else {
          list.push(false);
        }
      }
      if (getDataAjuizamentosPreenchido().length == ajuizamento?.fases[0].qtdRegistros) {
        if (typeOfCredity[0]?.value) {
          list.push(true);
        } else {
          list.push(false);
        }
      }

      if (list.filter((item: boolean) => item == false).length > 0) {
        return false;
      }
      return true;
    }
    return false;
  }

  function getDataAjuizamentosPreenchido() {
    return chamadosAbertos.slice(0, ajuizamento?.fases[0]?.qtdRegistros).filter((chamado, idx) => {
      if (chamado.dataAjuizamento) {
        return chamado;
      }
    });
  }

  function dataAjuizamentoEstaFullPreenchido() {
    if (getDataAjuizamentosPreenchido().length == ajuizamento?.fases[0].qtdRegistros) {
      return true;
    }
    return false;
  }

  function chamadosResolvidosFullPreenchido() {
    const chamadosResolvidos = chamadosAbertos.slice(0, ajuizamento?.fases[0]?.qtdRegistros).filter((chamado, idx) => {
      if (chamado.statusChamadoAjuizamento === 'Resolvido') {
        return chamado;
      }
    });
    if (chamadosResolvidos.length === ajuizamento?.fases[0].qtdRegistros) {
      return true;
    }
    return false;
  }

  const getFaseAlterada = (idFase: number) => {
    switch (idFase) {
      case 1:
        return testarDocumentacaoRecebidaAlterada();
      case 2:
        return testarGuiaPagamentoAlterado();
      case 3:
        return testarNumeroPOAlterado();
      case 4:
        return testarChamadoPagamentoAlterado();
      case 5:
        return testarComprovantesPagamentosAlterado();
      case 6:
        return testarChamadosAlterado();
      case 7:
        return testarUltimaFaseAlterada();
      default:
        return true;
    }
  };

  const testarList = (list: any[]) => {
    return true;
  };

  const testarDocumentacaoRecebidaAlterada = () => {
    return ajuizamento?.fases.find(fase => fase.idFase === 1)?.documentacaoRecebida !== documentacaoRecebida;
  };

  const testarGuiaPagamentoAlterado = () => {
    try {
      const list = guiasPagamento?.slice(0, ajuizamento?.fases[0].qtdRegistros).map((guia: any, index: number) => {
        return guia.valorGuia != ajuizamento?.fases?.find(fase => fase.idFase === 2)?.guiasPagamento[index];
      });
      return testarList(list);
    } catch (e) {
      const list = guiasPagamento?.slice(0, ajuizamento?.fases[0].qtdRegistros).map((guia: any, index: number) => {
        return guia.valorGuia != GUIAS_PAGAMENTO[index].valorGuia;
      });
      return testarList(list);
    }
  };

  const testarNumeroPOAlterado = () => {
    try {
      const list = numeroPO.slice(0, ajuizamento?.fases[0].qtdRegistros).map((PO: any, index: number) => {
        return PO.numero != ajuizamento?.fases?.find(fase => fase.idFase === 3)?.numeroPO[index]?.numero;
      });
      return testarList(list);
    } catch (e) {
      const list = numeroPO.slice(0, ajuizamento?.fases[0].qtdRegistros).map((PO: any, index: number) => {
        return PO.numero != NUMERO_PO[index].numero;
      });
      return testarList(list);
    }
  };

  const testarChamadoPagamentoAlterado = () => {
    try {
      const list = numeroChamadoPagamento
        ?.slice(0, ajuizamento?.fases[0].qtdRegistros)
        .map((chamadoPagamento: any, index: number) => {
          return (
            chamadoPagamento.numeroChamado !=
            ajuizamento?.fases?.find(fase => fase.idFase === 4)?.numeroChamadoPagamento[index]
          );
        });
      return testarList(list);
    } catch (e) {
      const list = numeroChamadoPagamento
        ?.slice(0, ajuizamento?.fases[0].qtdRegistros)
        .map((chamadoPagamento: any, index: number) => {
          return chamadoPagamento.numeroChamado != NUMERO_CHAMADO_PGTO[index].numeroChamado;
        });
      return testarList(list);
    }
  };

  const testarComprovantesPagamentosAlterado = () => {
    try {
      const list = comprovantesPagamentos
        ?.slice(0, ajuizamento?.fases[0].qtdRegistros)
        .map((comprovantes: any, index: number) => {
          return (
            comprovantes.comprovantes !=
            ajuizamento?.fases?.find(fase => fase.idFase === 5)?.comprovantesPagamentos[index]
          );
        });
      return testarList(list);
    } catch (e) {
      const list = comprovantesPagamentos
        ?.slice(0, ajuizamento?.fases[0].qtdRegistros)
        .map((comprovantes: any, index: number) => {
          return comprovantes.comprovantes != COMPROVANTES_PAGAMENTOS[index].comprovantes;
        });
      return testarList(list);
    }
  };

  const testarChamadosAlterado = () => {
    try {
      if (ajuizamento?.fases?.find(fase => fase.idFase === 6)?.chamados.length > 0) {
        const list = chamadosAbertos
          ?.slice(0, ajuizamento?.fases[0].qtdRegistros)
          .map((chamado: any, index: number) => {
            return (
              chamado.numeroChamadoAjuizamento !=
                ajuizamento?.fases?.find(fase => fase.idFase === 6)?.chamados[index]?.numeroChamadoAjuizamento ||
              chamado.statusChamadoAjuizamento !=
                ajuizamento?.fases?.find(fase => fase.idFase === 6)?.chamados[index]?.statusChamadoAjuizamento ||
              chamado.protocolo != ajuizamento?.fases?.find(fase => fase.idFase === 6)?.chamados[index]?.protocolo ||
              chamado.dataAjuizamento !=
                ajuizamento?.fases?.find(fase => fase.idFase === 6)?.chamados[index]?.dataAjuizamento
            );
          });
        return testarList(list);
      }
      const list = chamadosAbertos?.slice(0, ajuizamento?.fases[0].qtdRegistros).map((chamado: any, index: number) => {
        return (
          chamado.numeroChamadoAjuizamento != CHAMADOS_ABERTOS[index].numeroChamadoAjuizamento ||
          chamado.statusChamadoAjuizamento != CHAMADOS_ABERTOS[index].statusChamadoAjuizamento ||
          chamado.protocolo != CHAMADOS_ABERTOS[index].protocolo ||
          chamado.dataAjuizamento != CHAMADOS_ABERTOS[index].dataAjuizamento
        );
      });
      return testarList(list);
    } catch (e) {
      const list = chamadosAbertos?.slice(0, ajuizamento?.fases[0].qtdRegistros).map((chamado: any, index: number) => {
        return (
          chamado.numeroChamadoAjuizamento != CHAMADOS_ABERTOS[index].numeroChamadoAjuizamento ||
          chamado.statusChamadoAjuizamento != CHAMADOS_ABERTOS[index].statusChamadoAjuizamento ||
          chamado.protocolo != CHAMADOS_ABERTOS[index].protocolo ||
          chamado.dataAjuizamento != CHAMADOS_ABERTOS[index].dataAjuizamento
        );
      });
      return testarList(list);
    }
  };

  const testarUltimaFaseAlterada = () => {
    const list: boolean[] = [];
    try {
      if (
        ajuizamento?.fases?.find(fase => fase.idFase === 6)?.chamados[0]?.idEspecieCredito != typeOfCredity[0]?.value
      ) {
        list.push(true);
      } else {
        list.push(false);
      }
    } catch (e) {
      if (typeOfCredity[0]?.value) {
        list.push(true);
      } else {
        list.push(false);
      }
    }

    if (ajuizamento?.idStatusAcao != statusAcao[0]?.value) {
      list.push(true);
    } else {
      list.push(false);
    }

    if (list.filter((item: boolean) => item === false).length > 0) {
      return false;
    }
    return true;
  };

  const getFloatValue = (value: string | number) => {
    if (typeof value === 'string') {
      const removeThousends = value.replaceAll('.', '');
      return parseFloat(removeThousends.replace(',', '.'));
    }

    return value;
  };

  const salvarAtualizacao = async () => {
    try {
      if (!testarCamposPreenchidos()) {
        throw Error('Erro ao validar formulário');
      }
      setLoading(true);
      const fases: any = [];
      for (let idArray = 0; idArray < (ajuizamento?.fases.length ? ajuizamento?.fases.length : 0); idArray++) {
        if (ajuizamento?.fases[idArray].idFase === 7) {
          setIdFase(6);
        } else {
          const idfs = ajuizamento?.fases[idArray].idFase || 0;
          setIdFase(idfs);
        }
        fases.push({
          idFase: ajuizamento?.fases[idArray].idFase,
          nomeFase: ajuizamento?.fases[idArray].nomeFase,
          numeroChamadoDocumentacao: numeroChamadoDoc,
          faseConcluida: true,
          idAcaoAjuizamento: ajuizamento?.idAcaoAjuizamento,
          faseAtual: false,
          documentacaoRecebida,
          guiasPagamento: guiasPagamento
            .filter(guia => guia.valorGuia)
            .map(guia => getFloatValue(guia.valorGuia))
            .slice(0, ajuizamento?.fases[0].qtdRegistros),
          numeroPO: numeroPO.filter(po => po.numero).slice(0, ajuizamento?.fases[0].qtdRegistros),
          numeroChamadoPagamento: numeroChamadoPagamento
            .filter(nChamado => nChamado.numeroChamado)
            .slice(0, ajuizamento?.fases[0].qtdRegistros)
            .map(nchamado => nchamado.numeroChamado),
          comprovantesPagamentos: comprovantesPagamentos
            .filter(comp => comp.comprovantes != null)
            .slice(0, ajuizamento?.fases[0].qtdRegistros)
            .map(comp => comp.comprovantes),
          chamados: getChamadosAbertos(),
          alterado: getFaseAlterada(idFase || 0),
        });
      }
      const payload = {
        idStatusAcao: statusAcao[0]?.value,
        fases,
      };
      await submitAtualizacao(ajuizamentoAAtualizar!.codigoControle, payload);
      success('Salvo com sucesso');
      fecharModal();
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const getSelectedChamado = (idx: number) => {
    if (chamadosAbertos[idx]) {
      return STATUS_OPTIONS.find(status => {
        return status.label === chamadosAbertos[idx].statusChamadoAjuizamento;
      });
    }
    return undefined;
  };

  const chamadosSliced = chamadosAbertos.slice(0, ajuizamento?.fases[0]?.qtdRegistros);

  const unifiedData = guiasPagamento
    .slice(0, ajuizamento?.fases.find(fase => fase.idFase === 1)?.qtdRegistros)
    .map((guia, idx) => {
      return {
        ...guia,
        ...chamadosSliced[idx],
      };
    });

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={isOpen}
      onClose={() => fecharModal()}
      aria-labelledby="max-width-dialog-title"
    >
      {isLoading && (
        <S.LoadingArea>
          <LoadingIcon />
        </S.LoadingArea>
      )}

      <S.DialogContent>
        <S.Header>
          <S.Title>Controle de Ações Ajuizadas - Por Contrato</S.Title>
          {/* @ts-ignore */}
          <CloseIcon click={() => fecharModal()} color="#fff" />
        </S.Header>
        <S.MainContent>
          <S.DocumentacaoSection>
            <S.Section>
              <S.SectionTitle>Documentação</S.SectionTitle>
              <S.DocRow>
                {ajuizamento?.fases[0]?.documentacaoRecebida ? (
                  <CustomCheckbox
                    active
                    label="Documentação Recebida"
                    click={() => {
                      return false;
                    }}
                  />
                ) : (
                  <CustomCheckbox
                    active={documentacaoRecebida}
                    label="Documentação Recebida"
                    click={() => setDocumentacaoRecebida(!documentacaoRecebida)}
                  />
                )}

                <S.FieldAreaDoc>
                  <label>Número do chamado</label>
                  <Input
                    state={[numeroChamadoDoc, setNumeroChamadoDoc]}
                    disabled={
                      ajuizamento?.fases.find(fase => fase.idFase === 1)?.documentacaoRecebida || !documentacaoRecebida
                    }
                  />
                </S.FieldAreaDoc>
              </S.DocRow>
            </S.Section>
          </S.DocumentacaoSection>
          <S.StatusSection>
            <S.Section>
              <S.SectionTitle>Status/Tipo</S.SectionTitle>
              <S.SelectContent>
                <S.SelectArea>
                  <S.FieldSelect>
                    <S.FieldHeader>Status da Ação</S.FieldHeader>
                    <Select
                      state={[statusAcao, setStatusAcao]}
                      options={actionStatus}
                      disabled={!dataAjuizamentoEstaFullPreenchido()}
                    />
                  </S.FieldSelect>
                </S.SelectArea>
                <S.SelectArea>
                  <S.FieldSelect>
                    <S.FieldHeader>Tipo de Crédito</S.FieldHeader>
                    <Select
                      state={[typeOfCredity, setTypeOfCredit]}
                      options={tipoDeCreditoOptions}
                      disabled={!dataAjuizamentoEstaFullPreenchido()}
                    />
                  </S.FieldSelect>
                </S.SelectArea>
              </S.SelectContent>
            </S.Section>
          </S.StatusSection>
          <S.FasesSection>
            <S.Section>
              <S.SectionTitle>Fases</S.SectionTitle>
              <S.FasesTable>
                <S.TableHead>
                  <tr>
                    <td>Guia pgto. (R$)</td>
                    <td>Num. PO</td>
                    <td>Num. Chamado pgto.</td>
                    <td>Comp PG</td>
                    <td>Nº. Chamado ajuizamento</td>
                    <td>Status chamado ajuizamento</td>
                    <td>Número protocolo</td>
                    <td>Data ajuizamento</td>
                  </tr>
                </S.TableHead>
                <S.TableBody>
                  {unifiedData.map((dado, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          {ajuizamento?.fases.find(fase => fase.idFase === 2)?.faseConcluida ? (
                            <Input
                              pattern={mascaraNumeracaoPtBr}
                              disabled
                              state={[dado.valorGuia, value => changeGuia(value.toString(), idx)]}
                            />
                          ) : (
                            <Input
                              pattern={mascaraNumeracaoPtBr}
                              state={[dado.valorGuia, value => changeGuia(value.toString(), idx)]}
                              disabled={!documentacaoRecebida || !temFaseId5()}
                            />
                          )}
                        </td>
                        <td>
                          {ajuizamento?.fases.find(fase => fase.idFase === 3)?.faseConcluida ? (
                            <Input disabled state={[numeroPO[idx].numero, e => changePO(e.toString(), idx)]} />
                          ) : (
                            <Input
                              disabled={!dado.valorGuia}
                              state={[numeroPO[idx].numero, e => changePO(e.toString(), idx)]}
                            />
                          )}
                        </td>
                        <td>
                          {ajuizamento?.fases.find(fase => fase.idFase === 4)?.faseConcluida ? (
                            <Input
                              disabled
                              state={[
                                numeroChamadoPagamento[idx].numeroChamado,
                                e => changeNumeroChamadoPgto(e.toString(), idx),
                              ]}
                            />
                          ) : (
                            <Input
                              disabled={!numeroPO[idx].numero}
                              state={[
                                numeroChamadoPagamento[idx].numeroChamado,
                                e => changeNumeroChamadoPgto(e.toString(), idx),
                              ]}
                            />
                          )}
                        </td>
                        <td>
                          {ajuizamento?.fases.find(fase => fase.idFase === 5)?.faseConcluida ? (
                            <CustomCheckbox
                              active={comprovantesPagamentos[idx].comprovantes}
                              label=""
                              click={() => {
                                return false;
                              }}
                            />
                          ) : (
                            <CustomCheckbox
                              active={comprovantesPagamentos[idx].comprovantes}
                              label=""
                              click={() => changeComprovantePagamento(idx)}
                            />
                          )}
                        </td>
                        <td>
                          {ajuizamento?.fases.find(fase => fase.idFase === 6)?.faseConcluida ? (
                            <Input
                              disabled
                              state={[
                                dado.numeroChamadoAjuizamento,
                                value => updateChamadoValue(value, idx, 'numeroChamadoAjuizamento'),
                              ]}
                            />
                          ) : (
                            <Input
                              disabled={
                                (!comprovantesPagamentos[idx].comprovantes && temFaseId5()) || !documentacaoRecebida
                              }
                              state={[
                                dado.numeroChamadoAjuizamento,
                                value => updateChamadoValue(value, idx, 'numeroChamadoAjuizamento'),
                              ]}
                            />
                          )}
                        </td>
                        <td>
                          {ajuizamento?.fases.find(fase => fase.idFase === 6)?.faseConcluida ? (
                            <SingleSelect
                              containerStyles={S.selectContainerStyles}
                              controlStyle={S.selectControlStyles}
                              disabled
                              state={[undefined, () => null]}
                              options={STATUS_OPTIONS}
                            />
                          ) : (
                            <SingleSelect
                              containerStyles={S.selectContainerStyles}
                              controlStyle={S.selectControlStyles}
                              state={[
                                getSelectedChamado(idx),
                                option => updateChamadoValue(option.label, idx, 'statusChamadoAjuizamento'),
                              ]}
                              disabled={!dado.numeroChamadoAjuizamento}
                              options={STATUS_OPTIONS}
                            />
                          )}
                        </td>
                        <td>
                          {ajuizamento?.fases.find(fase => fase.idFase === 6)?.faseConcluida ? (
                            <Input
                              disabled
                              state={[dado.protocolo, value => updateChamadoValue(value, idx, 'protocolo')]}
                            />
                          ) : (
                            <Input
                              disabled={!dado.statusChamadoAjuizamento || !chamadosResolvidosFullPreenchido()}
                              state={[dado.protocolo, value => updateChamadoValue(value, idx, 'protocolo')]}
                            />
                          )}
                        </td>
                        <td>
                          {ajuizamento?.fases.find(fase => fase.idFase === 6)?.faseConcluida ? (
                            <S.MaskInput
                              mask="11/11/1111"
                              disabled
                              placeholder="DD/MM/YYYY"
                              value={dado.dataAjuizamento && formatDate(dado.dataAjuizamento, 'pt-br')}
                              onChange={e => updateChamadoValue(e.target.value, idx, 'dataAjuizamento')}
                            />
                          ) : (
                            <S.MaskInput
                              mask="11/11/1111"
                              disabled={!dado.protocolo}
                              placeholder="DD/MM/YYYY"
                              value={dado.dataAjuizamento && formatDate(dado.dataAjuizamento, 'pt-br')}
                              onChange={e => updateChamadoValue(e.target.value, idx, 'dataAjuizamento')}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </S.TableBody>
              </S.FasesTable>
            </S.Section>
          </S.FasesSection>
          <S.ActionsSection>
            <Button onClick={salvarAtualizacao} text="SALVAR INFORMAÇÕES" />
          </S.ActionsSection>
        </S.MainContent>
      </S.DialogContent>

      {/* <DialogContent>
        <Title>Detalhamento do Processo de Ajuizamento</Title>
        <MainContent>
          <LeftContent>

            <FieldRow>
              <FieldHeader>Guia pgto.(R$)</FieldHeader>
              <FieldHeader>Número PO</FieldHeader>
              <FieldHeader>Número Chamado pgto.</FieldHeader>
              <FieldHeader>Comp. PG</FieldHeader>
            </FieldRow>

            {guiasPagamento
              .slice(0, ajuizamento?.fases.find(fase => fase.idFase === 1)?.qtdRegistros)
              .map((guia, idx) => {
                return (
                  <FieldRow key={idx}>
                    <FieldArea>
                      {ajuizamento?.fases.find(fase => fase.idFase === 2)?.faseConcluida ? (
                        <Input
                          pattern={mascaraNumeracaoPtBr}
                          disabled
                          state={[guia.valorGuia, value => changeGuia(value.toString(), idx)]}
                        />
                      ) : (
                        <Input
                          pattern={mascaraNumeracaoPtBr}
                          state={[guia.valorGuia, value => changeGuia(value.toString(), idx)]}
                          disabled={!documentacaoRecebida || !temFaseId5()}
                        />
                      )}
                    </FieldArea>
                    <FieldArea>
                      {ajuizamento?.fases.find(fase => fase.idFase === 3)?.faseConcluida ? (
                        <Input disabled state={[numeroPO[idx].numero, e => changePO(e.toString(), idx)]} />
                      ) : (
                        <Input
                          disabled={!guia.valorGuia}
                          state={[numeroPO[idx].numero, e => changePO(e.toString(), idx)]}
                        />
                      )}
                    </FieldArea>
                    <FieldArea>
                      {ajuizamento?.fases.find(fase => fase.idFase === 4)?.faseConcluida ? (
                        <Input
                          disabled
                          state={[
                            numeroChamadoPagamento[idx].numeroChamado,
                            e => changeNumeroChamadoPgto(e.toString(), idx),
                          ]}
                        />
                      ) : (
                        <Input
                          disabled={!numeroPO[idx].numero}
                          state={[
                            numeroChamadoPagamento[idx].numeroChamado,
                            e => changeNumeroChamadoPgto(e.toString(), idx),
                          ]}
                        />
                      )}
                    </FieldArea>
                    <FieldArea>
                      {ajuizamento?.fases.find(fase => fase.idFase === 5)?.faseConcluida ? (
                        <CustomCheckbox
                          active={comprovantesPagamentos[idx].comprovantes}
                          label=""
                          click={() => {
                            return false;
                          }}
                        />
                      ) : (
                        <CustomCheckbox
                          active={comprovantesPagamentos[idx].comprovantes}
                          label=""
                          click={() => changeComprovantePagamento(idx)}
                        />
                      )}
                    </FieldArea>
                  </FieldRow>
                );
              })}
          </LeftContent>
          <RightContent>
            <FieldRowChamados>
              <FieldHeader>Nº. Chamado ajuizamento</FieldHeader>
              <FieldHeader>Status chamado ajuizamento</FieldHeader>
              <FieldHeader>Número protocolo</FieldHeader>
              <FieldHeader>Data ajuizamento</FieldHeader>
            </FieldRowChamados>

            {chamadosAbertos.slice(0, ajuizamento?.fases[0]?.qtdRegistros).map((chamado, idx) => {
              return (
                <FieldRowChamados>
                  <FieldArea>
                    {ajuizamento?.fases.find(fase => fase.idFase === 6)?.faseConcluida ? (
                      <Input
                        disabled
                        state={[
                          chamado.numeroChamadoAjuizamento,
                          value => updateChamadoValue(value, idx, 'numeroChamadoAjuizamento'),
                        ]}
                      />
                    ) : (
                      <Input
                        disabled={(!comprovantesPagamentos[idx].comprovantes && temFaseId5()) || !documentacaoRecebida}
                        state={[
                          chamado.numeroChamadoAjuizamento,
                          value => updateChamadoValue(value, idx, 'numeroChamadoAjuizamento'),
                        ]}
                      />
                    )}
                  </FieldArea>
                  <FieldArea>
                    {ajuizamento?.fases.find(fase => fase.idFase === 6)?.faseConcluida ? (
                      <SingleSelect disabled state={[undefined, () => null]} options={STATUS_OPTIONS} />
                    ) : (
                      <SingleSelect
                        state={[
                          getSelectedChamado(idx),
                          option => updateChamadoValue(option.label, idx, 'statusChamadoAjuizamento'),
                        ]}
                        disabled={!chamado.numeroChamadoAjuizamento}
                        options={STATUS_OPTIONS}
                      />
                    )}
                  </FieldArea>
                  <FieldArea>
                    {ajuizamento?.fases.find(fase => fase.idFase === 6)?.faseConcluida ? (
                      <Input
                        disabled
                        state={[chamado.protocolo, value => updateChamadoValue(value, idx, 'protocolo')]}
                      />
                    ) : (
                      <Input
                        disabled={!chamado.statusChamadoAjuizamento || !chamadosResolvidosFullPreenchido()}
                        state={[chamado.protocolo, value => updateChamadoValue(value, idx, 'protocolo')]}
                      />
                    )}
                  </FieldArea>
                  <FieldArea>
                    {ajuizamento?.fases.find(fase => fase.idFase === 6)?.faseConcluida ? (
                      <MaskInput
                        mask="11/11/1111"
                        disabled
                        placeholder="DD/MM/YYYY"
                        value={chamado.dataAjuizamento && formatDate(chamado.dataAjuizamento, 'pt-br')}
                        onChange={e => updateChamadoValue(e.target.value, idx, 'dataAjuizamento')}
                      />
                    ) : (
                      <MaskInput
                        mask="11/11/1111"
                        disabled={!chamado.protocolo}
                        placeholder="DD/MM/YYYY"
                        value={chamado.dataAjuizamento && formatDate(chamado.dataAjuizamento, 'pt-br')}
                        onChange={e => updateChamadoValue(e.target.value, idx, 'dataAjuizamento')}
                      />
                    )}
                  </FieldArea>
                </FieldRowChamados>
              );
            })}
          </RightContent>
        </MainContent>
        <SelectContent>
          <SelectArea>
            <FieldSelect>
              <FieldHeader>Status da Ação</FieldHeader>
              <Select
                state={[statusAcao, setStatusAcao]}
                options={actionStatus}
                disabled={!dataAjuizamentoEstaFullPreenchido()}
              />
            </FieldSelect>
          </SelectArea>
          <SelectArea>
            <FieldSelect>
              <FieldHeader>Tipo de Crédito</FieldHeader>
              <Select
                state={[tipoDeCredito, setTipoDeCredito]}
                options={typeOfCredity}
                disabled={!dataAjuizamentoEstaFullPreenchido()}
              />
            </FieldSelect>
          </SelectArea>
        </SelectContent>
        <Actions>
          <Button onClick={salvarAtualizacao} text="SALVAR INFORMAÇÕES" />
        </Actions>
      </DialogContent> */}
    </Dialog>
  );
};

export default FaseUpdate;
