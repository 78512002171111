import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import MoreIcon from '@material-ui/icons/MoreVert';
import { IconButton } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useLocation } from 'react-router-dom';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import * as S from './styles';
import * as T from './types';
import api from '../../../_core/api';
import Modal from './modal';
import { AuthContext } from '../../../_main/contexts/auth';
import { Confirmation } from './confimation';
import { failure, handleErrors, success, warning } from '../../../_core/services/toast';
import ArrowDown from '../../../assets/downArrow';
import { downloadXls } from '../../../_core/services/download';
import { requests } from '../../../requests';
import { useCache } from '../../../_core/cache';
import { Button, Form, Input, Option, Select, SingleSelect } from '../../../_core/_components';

type Sort = 'desc' | 'asc' | undefined;

interface SortOptions {
  key: string;
  type: Sort;
}

export const AprovarNegativacao: React.FC = () => {
  const { response: responseShoppings, isLoading: isLoadingShoppings } = useCache({
    key: 'getShoppings',
    fn: requests.get('getShoppings'),
  });
  const { response: responseGruposCobranca, isLoading: isLoadingGruposCobranca } = useCache({
    key: 'getGruposCobranca',
    fn: requests.get('getGruposCobranca'),
  });
  const { response: responsePortfolios, isLoading: isLoadingPortfolios } = useCache({
    key: 'getPortfolios',
    fn: requests.get('getPortfolios'),
  });
  const { response: responseJustificativas, isLoading: isLoadingJustificativas } = useCache({
    key: 'getJustificativas',
    fn: requests.get('getJustificativas'),
  });
  const location = useLocation();
  const { id, profile, shoppings: profileShoppings } = useContext(AuthContext);
  const [justificativas, setJustificativas] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [idBoleto, setIdBoleto] = useState(-1);
  const [marca, setMarca] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [luc, setLuc] = useState('');
  const [nrBoleto, setNrBoleto] = useState('');
  const [shoppingsList, setShoppingsList] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [portfolio, setPortfolio] = useState<Option[]>([]);
  const [portifolioList, setPortifolioList] = useState<Option[]>([]);
  const [grupoCobranca, setGrupoCobranca] = useState<Option[]>([]);
  const [grupoCobrancaList, setGrupoCobrancaList] = useState<Option[]>([]);
  const [customerInSerasa] = useState(false);
  const [customerInOutSerasa] = useState(false);
  const [customerNeverSerasa] = useState(false);
  const [data, setData] = useState<T.Response[]>([]);
  const [page, setPage] = useState(1);
  const [values, setValues] = useState<T.Values[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    key: '',
    type: undefined,
  });

  const title = location.pathname.includes('analisar') ? 'Analisar' : 'Aprovar';

  useEffect(() => {
    if (['COBRANÇA SHOPPING', 'APROVADOR SHOPPING', 'RÉGUA DE COBRANÇA'].includes(profile) && profileShoppings) {
      const arrProfileShoppings = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      arrProfileShoppings.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppingsList(arrProfileShoppings);
      setSelectedShoppings(arrProfileShoppings);
    } else {
      setShoppingsList([]);
      setSelectedShoppings([]);
    }
  }, [profileShoppings]);

  useEffect(() => {
    if (responseGruposCobranca !== undefined) {
      const listGrupoCobranca: Option[] = [];
      responseGruposCobranca.data.content.forEach((item: any) => {
        listGrupoCobranca.push({
          value: item.idGrupoCobranca,
          label: item.nome,
        });
      });
      setGrupoCobrancaList(listGrupoCobranca);
      setGrupoCobranca(listGrupoCobranca);
    }
  }, [responseGruposCobranca]);

  useEffect(() => {
    if (responsePortfolios !== undefined) {
      const listPortfolios: Option[] = [];
      responsePortfolios.data.content.map((item: string) => {
        listPortfolios.push({
          value: item,
          label: item,
        });
        return null;
      });
      setPortfolio(listPortfolios);
      setPortifolioList(listPortfolios);
    }
  }, [responsePortfolios]);

  useEffect(() => {
    if (responseJustificativas !== undefined) {
      const listJustificativas: any[] = [];
      responseJustificativas.data.content.map((item: any) => {
        listJustificativas.push({
          value: item.idSerasaJustificatica,
          label: item.txtJustificativa,
          campoObrigatorio: item.campoObrigatorio,
        });
        return null;
      });
      listJustificativas.push({
        value: 999,
        label: 'Selecione',
        campoObrigatorio: '',
      });

      setJustificativas(listJustificativas);
    }
  }, [responseJustificativas]);

  const getFiadores = (vals: any) => {
    if (vals.fiadores) {
      const fiadores = vals.fiadores.map((f: any, i: any) => ({
        value: i,
        label: f.nomeFiador,
      }));
      return fiadores;
    }
    return [];
  };

  const open = useMemo(() => idBoleto !== -1, [idBoleto]);

  const dataPaginated = useMemo(() => {
    const orderableArr = sortOptions.type ? _.orderBy(data, sortOptions.key, sortOptions.type) : data;
    let arr: T.Response[] = [];
    arr = orderableArr.slice((page - 1) * T.ROWS_PER_PAGE, (page - 1) * T.ROWS_PER_PAGE + T.ROWS_PER_PAGE);
    return arr;
  }, [data, page, sortOptions]);

  const getAnalisarStatus = (): string[] => {
    switch (profile) {
      case 'RÉGUA DE COBRANÇA':
        return ['PENDENTE_RECOMENDACAO_EQUIPE_SHOPPING', 'PENDENTE_RECOMENDACAO_EQUIPE_CORPORATIVA'];
      case 'COBRANÇA SHOPPING':
        return ['PENDENTE_RECOMENDACAO_EQUIPE_SHOPPING'];
      case 'COBRANÇA CORPORATIVA':
        return ['PENDENTE_RECOMENDACAO_EQUIPE_CORPORATIVA'];
      case 'APROVADOR SHOPPING':
        return ['PENDENTE_RECOMENDACAO_EQUIPE_SHOPPING'];
      case 'APROVADOR HOLDING':
      case 'HOLDING':
        return ['PENDENTE_RECOMENDACAO_EQUIPE_CORPORATIVA'];
      default:
        return [];
    }
  };

  const getAprovarStatus = (): string[] => {
    switch (profile) {
      case 'RÉGUA DE COBRANÇA':
        return ['PENDENTE_APROVACAO_SUPERINTENDENTE_SHOPPING', 'PENDENTE_APROVACAO_SUPERINTENDENTE_HOLDING'];
      case 'APROVADOR SHOPPING':
        return ['PENDENTE_APROVACAO_SUPERINTENDENTE_SHOPPING'];
      case 'APROVADOR HOLDING':
      case 'HOLDING':
        return ['PENDENTE_APROVACAO_SUPERINTENDENTE_HOLDING'];
      default:
        return [];
    }
  };

  const getStatus = () => {
    if (title === 'Analisar') {
      return getAnalisarStatus();
    }
    return getAprovarStatus();
  };

  const getGrupoCobranca = () => {
    if (grupoCobranca && grupoCobranca.length > 0) {
      return grupoCobranca.map(e => e.value);
    }

    return undefined;
  };

  const getFilters = async (updatePage = true) => {
    if (updatePage) {
      setPage(1);
    }
    setLoading(true);
    setData([]);

    const paramAdicionais = ['COBRANÇA CORPORATIVA', 'APROVADOR HOLDING', 'HOLDING'].includes(profile);
    try {
      const response = await api.post(`/workflow/serasa/serasa-negativacao/buscar/boletos-shopping`, {
        idShopping: paramAdicionais
          ? [2, 5, 6, 7, 8, 9, 10, 11, 12, 13, 16, 17, 19, 20, 21, 22, 23, 27, 28, 29, 30, 31, 32, 15]
          : selectedShoppings.map(item => item.value),
        idUsuario: id,
        clientesNegativado: customerInSerasa,
        clientesForamNegativado: customerInOutSerasa,
        clientesNuncaNegativado: customerNeverSerasa,
        marca: marca.length > 0 ? marca : undefined,
        luc: luc.length > 0 ? luc : undefined,
        numBoleto: nrBoleto.length > 0 ? nrBoleto : undefined,
        numCpfcnpj: cnpj.length > 0 ? cnpj : undefined,
        portifolio: paramAdicionais ? portfolio.map(e => e.label) : undefined,
        grupoCobranca: paramAdicionais ? getGrupoCobranca() : undefined,
        status: getStatus(),
      });
      if (_.isEmpty(response.data.content)) {
        warning('Não obteve resultados.');
      } else {
        setValues(
          response.data.content.map((item: T.Response) => ({
            id: item.idBoleto,
            negativado: item.flagNegativar,
            fiadores: item.fiadores.map(fiador => ({
              id: fiador.usuarioCadastro.id,
              label: fiador.usuarioCadastro.nome,
            })),
            valTotalVencido: item.valTotalVencido,
            justificativa: item.serasaJustificatica?.idSerasaJustificatica ?? 999,
            observacao: item.txtObservacao ?? '',
            nrChamado: item.numChamado ?? '',
            anexo: item.nomeAnexo ?? '',
          })),
        );
        setData(response.data.content);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onModalSubmit = async (params: any) => {
    setLoading(true);
    try {
      const arr = [...values];
      const index = arr.findIndex(e => e.id === idBoleto);
      arr[index] = { ...arr[index], ...params };
      if (params.file) {
        const formData = new FormData();
        formData.append('file', params.file);
        formData.append('idBoleto', arr[index].id.toString());
        formData.append('idJustificativa', arr[index].justificativa.toString());
        formData.append('idUsuario', id.toString());
        formData.append('numChamado', arr[index].nrChamado);
        formData.append('txtObservacao', arr[index].observacao);
        await api.post('/workflow/serasa/serasa-upload/historico/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        const payload = {
          idBoleto: arr[index].id,
          idJustificativa: arr[index].justificativa,
          idUsuario: id,
          numChamado: arr[index].nrChamado,
          txtObservacao: arr[index].observacao,
        };
        await api.post('/workflow/serasa/serasa-workflow/salvar-historico', payload);
      }
      setIdBoleto(-1);
      await getFilters(false);
      success('Salvo com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onChangeNegativados = async (boletoId: number) => {
    const i = values.findIndex(e => e.id === boletoId);
    const arr = [...values];
    arr[i].negativado = !arr[i].negativado;
    const item = data[i];
    const boleto = {
      idBoleto: item.idBoleto,
      loja: {
        idSerasaLoja: item.serasaLoja.idSerasaLoja,
        negativar: arr[i].negativado,
      },
      fiadores: item.fiadores.map(e => ({
        idSerasaBoletoFiador: e.idSerasaBoletoFiador,
        negativar: arr[i].negativado,
      })),
    };
    await api.post('/workflow/serasa/serasa-negativacao/atualizar-negativacao', {
      idUsuario: id,
      boleto,
    });
    setValues(arr);
  };

  const submitNegative = async () => {
    let disabled = false;
    let boletoId = 0;
    values.forEach(e => {
      if (!e.negativado && (!e.justificativa || e.justificativa === 999)) {
        disabled = true;
        boletoId = e.id;
      }
    });
    if (disabled) {
      const boleto = data.find(e => e.idBoleto === boletoId);
      return failure(`O boleto ${boleto?.numBoleto} precisa de uma justificativa.`);
    }
    setLoading(true);
    try {
      const boletos = data.map((item, i) => ({
        idBoleto: item.idBoleto,
        loja: {
          idSerasaLoja: item.serasaLoja.idSerasaLoja,
          negativar: values[i].negativado,
        },
        fiadores: item.fiadores.map(e => ({
          idSerasaBoletoFiador: e.idSerasaBoletoFiador,
          negativar: values[i].negativado,
        })),
      }));
      await api.post('/workflow/serasa/serasa-negativacao/submeter-recomendacao', {
        idUsuario: id,
        boletos,
      });
      await getFilters();
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
    setConfirmOpen(false);
    return null;
  };

  const flatNegativar = (boletoId: number): Option => {
    const item = values.find(e => e.id === boletoId);
    if (item?.negativado) {
      return T.DATA_MULTISELECT[1];
    }
    return T.DATA_MULTISELECT[0];
  };

  const limparObservacoes = async () => {
    setLoading(true);
    setIdBoleto(-1);
    try {
      await api.post('/workflow/serasa/serasa-workflow/historico/limpar', {
        idBoleto,
        idUsuario: id,
      });
      await getFilters(false);
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const extraRowRender = (item: T.Response) => {
    if (item.serasaJustificatica || item.numChamado || item.txtObservacao) {
      return true;
    }

    return false;
  };

  const download = async () => {
    setLoading(true);
    const paramAdicionais = ['COBRANÇA CORPORATIVA', 'APROVADOR HOLDING', 'HOLDING'].includes(profile);
    try {
      const response = await api.post(
        `/workflow/serasa/serasa-negativacao/download/boletos`,
        {
          idShopping: paramAdicionais
            ? [2, 5, 6, 7, 8, 9, 10, 11, 12, 13, 16, 17, 19, 20, 21, 22, 23, 27, 28, 29, 30, 31, 32, 15]
            : selectedShoppings.map(item => item.value),
          idUsuario: id,
          clientesNegativado: customerInSerasa,
          clientesForamNegativado: customerInOutSerasa,
          clientesNuncaNegativado: customerNeverSerasa,
          marca: marca.length > 0 ? marca : undefined,
          luc: luc.length > 0 ? luc : undefined,
          numBoleto: nrBoleto.length > 0 ? nrBoleto : undefined,
          numCpfcnpj: cnpj.length > 0 ? cnpj : undefined,
          portifolio: paramAdicionais ? portfolio.map(e => e.label) : undefined,
          grupoCobranca: paramAdicionais ? grupoCobranca.map(e => e.value) : undefined,
          status: getStatus(),
        },
        {
          responseType: 'arraybuffer',
        },
      );
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onClickOrderable = (key: string) => {
    // Já clickou antes
    if (key === sortOptions.key) {
      switch (sortOptions.type) {
        case 'asc':
          setSortOptions({
            key,
            type: 'desc',
          });
          break;
        case 'desc':
          setSortOptions({
            key: '',
            type: undefined,
          });
          break;
        default:
          setSortOptions({
            key,
            type: 'asc',
          });
          break;
      }
      return;
    }
    // Primeiro Click
    setSortOptions({
      key,
      type: 'asc',
    });
  };

  const checkArrowDirection = (key: string) => {
    if (sortOptions.key === key) return sortOptions.type;
    return undefined;
  };

  const formItems = () => {
    const items = [
      <Input label="Marca" state={[marca, setMarca]} />,
      <Input label="CNPJ/CPF" state={[cnpj, setCnpj]} />,
      <Input label="LUC" state={[luc, setLuc]} />,
      <Input label="Número do boleto" state={[nrBoleto, setNrBoleto]} />,
    ];
    if (['HOLDING', 'APROVADOR HOLDING', 'COBRANÇA CORPORATIVA'].includes(profile)) {
      items.push(<Select state={[portfolio, setPortfolio]} options={portifolioList} placeholder="Portfólios" />);
      items.push(
        <Select
          state={[grupoCobranca, setGrupoCobranca]}
          options={grupoCobrancaList}
          placeholder="Grupos de cobrança"
        />,
      );
    } else {
      items.push(
        <Select
          placeholder="Shoppings"
          state={[selectedShoppings, setSelectedShoppings]}
          options={shoppingsList}
          disabled={['COBRANÇA SHOPPING', 'APROVADOR SHOPPING'].includes(profile)}
        />,
      );
    }
    return items;
  };

  return (
    <>
      <FullScreenLoading
        isEnabled={
          loading || isLoadingGruposCobranca || isLoadingJustificativas || isLoadingPortfolios || isLoadingShoppings
        }
      />
      <S.Container>
        <Form items={formItems()} submitButton={<Button onClick={getFilters} text="Pesquisar" />} />
        {dataPaginated.length > 0 && (
          <>
            <S.TableContainer>
              <S.CustTable>
                <thead>
                  <tr>
                    <S.HeaderItem>
                      <S.Orderable
                        onClick={() => onClickOrderable('nmShopping')}
                        arrowDirection={checkArrowDirection('nmShopping')}
                      >
                        Nome do Shopping
                        <ArrowDown click={null} color="white" />
                      </S.Orderable>
                    </S.HeaderItem>
                    <S.HeaderItem>
                      <S.Orderable
                        onClick={() => onClickOrderable('nomFantasia')}
                        arrowDirection={checkArrowDirection('nomFantasia')}
                      >
                        Nome fantasia
                        <ArrowDown click={null} color="white" />
                      </S.Orderable>
                    </S.HeaderItem>
                    <S.HeaderItem>
                      <S.Orderable
                        onClick={() => onClickOrderable('serasaLoja.nomRazaoSocial')}
                        arrowDirection={checkArrowDirection('serasaLoja.nomRazaoSocial')}
                      >
                        Razão Social
                        <ArrowDown click={null} color="white" />
                      </S.Orderable>
                    </S.HeaderItem>
                    {['HOLDING', 'APROVADOR HOLDING', 'COBRANÇA CORPORATIVA'].includes(profile) && (
                      <>
                        <S.HeaderItem>
                          <S.Orderable
                            onClick={() => onClickOrderable('serasaLoja.grupoCobranca')}
                            arrowDirection={checkArrowDirection('serasaLoja.grupoCobranca')}
                          >
                            Grupo de cobrança
                            <ArrowDown click={null} color="white" />
                          </S.Orderable>
                        </S.HeaderItem>
                        <S.HeaderItem>
                          <S.Orderable
                            onClick={() => onClickOrderable('serasaLoja.portfolio')}
                            arrowDirection={checkArrowDirection('serasaLoja.portfolio')}
                          >
                            Portfolio
                            <ArrowDown click={null} color="white" />
                          </S.Orderable>
                        </S.HeaderItem>
                      </>
                    )}
                    <S.HeaderItem>
                      <S.Orderable onClick={() => onClickOrderable('luc')} arrowDirection={checkArrowDirection('luc')}>
                        LUC
                        <ArrowDown click={null} color="white" />
                      </S.Orderable>
                    </S.HeaderItem>
                    <S.HeaderItem>
                      <S.Orderable
                        onClick={() => onClickOrderable('datVencimento')}
                        arrowDirection={checkArrowDirection('datVencimento')}
                      >
                        Vencimento do boleto
                        <ArrowDown click={null} color="white" />
                      </S.Orderable>
                    </S.HeaderItem>
                    <S.HeaderItem>
                      <S.Orderable
                        onClick={() => onClickOrderable('numBoleto')}
                        arrowDirection={checkArrowDirection('numBoleto')}
                      >
                        Número do boleto
                        <ArrowDown click={null} color="white" />
                      </S.Orderable>
                    </S.HeaderItem>
                    <S.HeaderItem>
                      <S.Orderable
                        onClick={() => onClickOrderable('valTotalVencido')}
                        arrowDirection={checkArrowDirection('valTotalVencido')}
                      >
                        Valor do Boleto
                        <ArrowDown click={null} color="white" />
                      </S.Orderable>
                    </S.HeaderItem>
                    <S.HeaderItem>
                      <S.Orderable
                        onClick={() => onClickOrderable('qtdNegativacao')}
                        arrowDirection={checkArrowDirection('qtdNegativacao')}
                      >
                        Qtd de negativações
                        <ArrowDown click={null} color="white" />
                      </S.Orderable>
                    </S.HeaderItem>
                    <S.HeaderItem>
                      <S.Orderable
                        onClick={() => onClickOrderable('quantidadeExcecao')}
                        arrowDirection={checkArrowDirection('quantidadeExcecao')}
                      >
                        Qtd de exceções
                        <ArrowDown click={null} color="white" />
                      </S.Orderable>
                    </S.HeaderItem>
                    <S.HeaderItem>Fiadores</S.HeaderItem>
                    <S.HeaderItem>Negativar</S.HeaderItem>
                    <S.HeaderItem>Add</S.HeaderItem>
                  </tr>
                </thead>
                <tbody>
                  {dataPaginated.map((item, i) => {
                    return (
                      <>
                        <S.TableRow key={`${item.numBoleto} -- ${item.serasaLoja.nomRazaoSocial}`}>
                          <S.RowItem>{item.nmShopping}</S.RowItem>
                          <S.RowItem>{item.nomFantasia}</S.RowItem>
                          <S.RowItem>{item.serasaLoja.nomRazaoSocial}</S.RowItem>
                          {['HOLDING', 'APROVADOR HOLDING', 'COBRANÇA CORPORATIVA'].includes(profile) && (
                            <>
                              <S.RowItem>{item.nomGrupoCobranca}</S.RowItem>
                              <S.RowItem>{item.nomPortifolio}</S.RowItem>
                            </>
                          )}
                          <S.RowItem>{item.luc}</S.RowItem>
                          <S.RowItem>{item.datVencimento}</S.RowItem>
                          <S.RowItem>{item.numBoleto}</S.RowItem>
                          <S.RowItem>
                            {item.valTotalVencido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                          </S.RowItem>
                          <S.RowItem>{item.qtdNegativacao}</S.RowItem>
                          <S.RowItem>{item.quantidadeExcecao}</S.RowItem>
                          <S.RowItem>
                            <Select state={[getFiadores(item), () => null]} options={getFiadores(item)} />
                          </S.RowItem>
                          <S.RowItem>
                            <SingleSelect
                              state={[flatNegativar(item.idBoleto), () => onChangeNegativados(item.idBoleto)]}
                              options={T.DATA_MULTISELECT}
                            />
                          </S.RowItem>
                          <S.RowItem>
                            <IconButton
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              color="primary"
                              component="span"
                              onClick={() => setIdBoleto(item.idBoleto)}
                            >
                              <MoreIcon />
                            </IconButton>
                          </S.RowItem>
                        </S.TableRow>

                        <S.TableRow className="noted" mod>
                          {extraRowRender(item) && (
                            <>
                              <S.RowItem colSpan={6}>
                                <b>Justificativa: </b>
                                {item.serasaJustificatica?.txtJustificativa}
                              </S.RowItem>
                              <S.RowItem colSpan={3}>
                                <b>Nr. do chamado: </b>
                                {item.numChamado}
                              </S.RowItem>
                              <S.RowItem colSpan={3}>
                                <b>Observação: </b>
                                {item.txtObservacao}
                              </S.RowItem>
                            </>
                          )}
                        </S.TableRow>
                      </>
                    );
                  })}
                </tbody>
              </S.CustTable>
            </S.TableContainer>
            <S.PaginationWrapper>
              <Pagination
                count={Math.ceil(data.length / 20)}
                page={page}
                onChange={(e, i) => setPage(i)}
                variant="outlined"
                shape="rounded"
                siblingCount={2}
              />
            </S.PaginationWrapper>
            <S.Section>
              <Button text="Exportar" onClick={download} />
              <Button
                text={title === 'Aprovar' ? 'Aprovar Negativação' : 'Submeter Recomendação'}
                onClick={() => setConfirmOpen(true)}
              />
            </S.Section>
          </>
        )}
      </S.Container>
      {open && (
        <Modal
          onClose={() => setIdBoleto(-1)}
          onSubmit={onModalSubmit}
          limparObservacoes={limparObservacoes}
          values={values.find(e => e.id === idBoleto)!}
          justificativas={justificativas}
        />
      )}
      <Confirmation onClose={() => setConfirmOpen(false)} open={confirmOpen} onConfirm={submitNegative} />
    </>
  );
};
