import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CircularProgress, Dialog, DialogContent, Grid, IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from '../../../_core/_components/confirmation-modal';
import { AuthContext } from '../../../_main/contexts/auth';
import api from '../../../_core/api';
import { handleErrors, success } from '../../../_core/services/toast';
import { Form, Input, Button, SingleSelect, Option } from '../../../_core/_components';
import Table from '../../../_core/_components/table';
import { LoadingContainer } from './styles';

type Props = {
  shoppings: Option[];
  idGrupoCobranca: number;
  onClose: () => void;
};

export default function Modal({ shoppings, idGrupoCobranca, onClose }: Props): JSX.Element {
  const { id } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [luc, setLuc] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [values, setValues] = useState<any[]>([]);
  const [isConfirmationLojistaOpen, setIsConfirmationLojistaOpen] = useState(-1);

  const getData = async (payload: any) => {
    try {
      setLoading(true);
      const { data } = await api.post('/grupo-cobranca/grupo/buscar/associados', payload);
      setValues(
        data.content.map((item: any) => ({
          idGurpoCobrancaLojista: item.idGurpoCobrancaLojista,
          idShopping: item.shopping.idShopping,
          numCpfcnpj: item.lojista.numCpfcnpj,
          luc: item.lojista.luc,
          nomFantasia: item.lojista.nomFantasia,
        })),
      );
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idGrupoCobranca) {
      getData({ idGrupoCobranca });
    }
  }, []);

  const add = () => {
    const arr = [...values];
    arr.unshift({ idShopping: 0, numCpfcnpj: '', luc: '', nomFantasia: '' });
    setValues(arr);
  };

  const maskCPF = (value: string) => {
    return value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const maskCNPJ = (value: string) => {
    return value
      .replace(/\D/g, '')
      .slice(0, 14)
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  };

  const maskValue = (value: string) => {
    if (value.replace(/[^\d]/g, '').length > 11) {
      return maskCNPJ(value);
    }
    return maskCPF(value);
  };

  const onChange = (index: number, type: string, value: string) => {
    if (value.length === 19) return;
    const arr = [...values];
    arr[index][type] = value;
    setValues(arr);
  };

  const associate = async (params: any) => {
    setLoading(true);
    try {
      await api.post('/grupo-cobranca/grupo/associar', {
        ...params,
        luc: params.luc.trim(),
        nomFantasia: params.nomFantasia.trim(),
        idGrupoCobranca,
        idUsuario: id,
      });
      const { data } = await api.post('/grupo-cobranca/grupo/buscar/associados', {
        idGrupoCobranca,
        luc: luc.length > 0 ? luc : undefined,
        nomFantasia: nomeFantasia.length > 0 ? nomeFantasia : undefined,
      });
      setValues(
        data.content.map((item: any) => ({
          idGurpoCobrancaLojista: item.idGurpoCobrancaLojista,
          idShopping: item.shopping.idShopping,
          numCpfcnpj: item.lojista.numCpfcnpj,
          luc: item.lojista.luc.trim(),
          nomFantasia: item.lojista.nomFantasia.trim(),
        })),
      );
      success('Associado com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const deassociate = async () => {
    try {
      const index = isConfirmationLojistaOpen;
      setLoading(true);
      setIsConfirmationLojistaOpen(-1);
      if (values[index].idGurpoCobrancaLojista) {
        await api.post('/grupo-cobranca/grupo/excluir/associados', {
          idGrupoCobranca,
          numCpfcnpj: values[index].numCpfcnpj,
          luc: values[index].luc,
          idShopping: values[index].idShopping,
        });
      }
      const arr = [...values];
      arr.splice(index, 1);
      setValues(arr);
      success('Removido com sucesso.');
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const isAddDisabled = useMemo(() => {
    return values.some(item => item.idGurpoCobrancaLojista === undefined);
  }, [values]);

  const renderedData = useMemo(() => {
    if (values.length > 0) {
      const data = values.map((item, i) => ({
        shopping: (
          <SingleSelect
            options={shoppings}
            state={[
              shoppings.find(e => e.value === item.idShopping),
              e => onChange(i, 'idShopping', e.value as string),
            ]}
            disabled={item.idGurpoCobrancaLojista !== undefined}
          />
        ),
        cpfCnpj: (
          <TextField
            disabled={item.idShopping === 0 || item.idGurpoCobrancaLojista !== undefined}
            value={maskValue(item.numCpfcnpj)}
            onChange={(e: any) => onChange(i, 'numCpfcnpj', e.target.value)}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              style: {
                borderRadius: 30,
              },
            }}
          />
        ),
        luc: (
          <TextField
            disabled={item.idShopping === 0 || item.idGurpoCobrancaLojista !== undefined}
            value={item.luc}
            onChange={(e: any) => onChange(i, 'luc', e.target.value)}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              style: {
                borderRadius: 30,
              },
            }}
          />
        ),
        nomeFantasia: (
          <TextField
            disabled={item.idShopping === 0 || item.idGurpoCobrancaLojista !== undefined}
            value={item.nomFantasia}
            onChange={(e: any) => onChange(i, 'nomFantasia', e.target.value)}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              style: {
                borderRadius: 30,
              },
            }}
          />
        ),
        salvar: (
          <Button
            disabled={item.idShopping === 0 || item.idGurpoCobrancaLojista !== undefined}
            text="Salvar"
            onClick={() => associate(item)}
          />
        ),
        excluir: (
          <IconButton onClick={() => setIsConfirmationLojistaOpen(i)}>
            <DeleteIcon />
          </IconButton>
        ),
      }));
      return (
        <Table
          columns={[
            { label: 'Shopping', key: 'shopping' },
            { label: 'CPF/CNPJ', key: 'cpfCnpj' },
            { label: 'LUC', key: 'luc' },
            { label: 'Nome fantasia', key: 'nomeFantasia' },
            { label: 'Salvar', key: 'salvar' },
            { label: 'Excluir', key: 'excluir' },
          ]}
          data={data}
        />
      );
    }
    return <></>;
  }, [values]);

  return (
    <>
      <Dialog open onClose={onClose} fullWidth maxWidth="lg">
        <DialogContent>
          <span
            style={{
              color: '#00959c',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            Grupo de Cobrança
          </span>
          <Grid>
            <Form
              items={[
                <Input state={[luc, setLuc]} label="LUC" />,
                <Input state={[nomeFantasia, setNomeFantasia]} label="Nome fantasia" />,
                <Button text="Adicionar" onClick={add} disabled={isAddDisabled} />,
              ]}
              submitButton={
                <Button
                  text="Pesquisar"
                  onClick={() =>
                    getData({
                      idGrupoCobranca,
                      luc: luc.length > 0 ? luc : undefined,
                      nomFantasia: nomeFantasia.length > 0 ? nomeFantasia : undefined,
                    })
                  }
                />
              }
            />
            {loading ? (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) : (
              renderedData
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <ConfirmationModal
        open={isConfirmationLojistaOpen >= 0}
        text="Deseja excluir o registro?"
        handleCancel={() => setIsConfirmationLojistaOpen(-1)}
        handleOk={deassociate}
      />
    </>
  );
}
