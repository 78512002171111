import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import * as S from './styles';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import Table from '../../../_core/_components/table';
import api from '../../../_core/api';
import { failure, handleErrors, success } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';
import { Form, Select, Button, Input, Option, Checkbox } from '../../../_core/_components';
import Modal from './modal';
import { AuthContext } from '../../../_main/contexts/auth';

type IShopping = {
  shoppingId: number;
  shoppingName: string;
};

type StatusSerasaDTO = {
  content: any[];
  datetime: string;
  error: boolean;
  message: string;
};

export const ReenvioRetornoErro: React.FC = () => {
  const { id: idUsuario } = useContext(AuthContext);
  const { response: responseShoppings, isLoading } = useCache({
    key: 'getShoppings',
    fn: requests.get('getShoppings'),
  });
  const [loading, setLoading] = useState(false);
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [ciclo, setCiclo] = useState('');
  const [luc, setLuc] = useState('');
  const [nrBoleto, setNrBoleto] = useState('');
  const [result, setResult] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [details, setDetails] = useState<any>();

  useEffect(() => {
    if (responseShoppings !== undefined) {
      const listShopping: Option[] = [];
      responseShoppings.data.map((item: IShopping) => {
        listShopping.push({
          value: item.shoppingId,
          label: item.shoppingName,
        });
        return null;
      });
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppings(listShopping);
      setSelectedShoppings(listShopping);
    }
  }, [responseShoppings]);

  const getStatusSerasa = async () => {
    setLoading(true);
    setResult([]);
    try {
      const { data } = await api.post<StatusSerasaDTO>(`/workflow/serasa/serasa-negativacao/buscar/erro-negativados`, {
        idShopping: selectedShoppings.map(e => e.value),
        luc: luc.length > 0 ? luc : undefined,
        ciclo,
        numBoleto: nrBoleto,
      });
      setResult(data.content);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const handleDate = (value: string) => {
    if (value) {
      return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    return '';
  };

  const checkItemSelection = (idSerasaBoletoIdPessoa: string) => selectedData.includes(idSerasaBoletoIdPessoa);

  const clickRow = (idSerasaBoletoIdPessoa: string) => {
    const isSelected = checkItemSelection(idSerasaBoletoIdPessoa);

    if (!isSelected) {
      setSelectedData([...selectedData, idSerasaBoletoIdPessoa]);
    } else {
      setSelectedData(selectedData.filter(id => id !== idSerasaBoletoIdPessoa));
    }
  };

  const bToggleAll = useMemo(() => {
    const allBoletosIds = result.map(e => `${e.serasaBoleto.idSerasaBoleto}_${e.idPessoa}`);
    return allBoletosIds.every(e => selectedData.includes(e));
  }, [selectedData, result]);

  const onToggleAll = () => {
    if (bToggleAll) {
      setSelectedData([]);
    } else {
      setSelectedData(result.map(e => `${e.serasaBoleto.idSerasaBoleto}_${e.idPessoa}`));
    }
  };

  const renderedData = useMemo(() => {
    if (result.length > 0) {
      return result.map(item => ({
        idSerasaBoleto: item.serasaBoleto.idSerasaBoleto,
        idPessoa: item.idPessoa,
        toggle: (
          <Checkbox
            checked={checkItemSelection(`${item.serasaBoleto.idSerasaBoleto}_${item.idPessoa}`)}
            onClick={() => clickRow(`${item.serasaBoleto.idSerasaBoleto}_${item.idPessoa}`)}
          />
        ),
        nmShopping: item.serasaBoleto.serasaLoja.shopping.nmShopping,
        nomFantasia: item.serasaBoleto.serasaLoja.nomFantasia,
        nomRazaoSocial: item.serasaBoleto.serasaLoja.nomRazaoSocial,
        luc: item.serasaBoleto.serasaLoja.luc,
        numBoleto: item.serasaBoleto.numBoleto,
        datVencimento: handleDate(item.serasaBoleto.datVencimento),
        valTotalVencido: item.serasaBoleto.valTotalVencido?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
        documentoDevedor: item.documentoDevedor,
        tipoPessoa: item.tipoPessoa,
        operacao: item.operacao,
        operacaoNome: item.operacao === 'EXCLUSAO' ? 'BAIXA' : 'NEGATIVAÇÃO',
        id: item.serasaBoleto.cicloNegativacao.id,
        nome: item.statusWorkflowSerasa.nome,
        status: item.retornoSerasa.status,
        dataRetornoSerasa: handleDate(item.retornoSerasa.dataRetornoSerasa),
        detalhes: item.retornoSerasa.erros?.length ? (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            onClick={() => setDetails(item)}
          >
            <MoreIcon />
          </IconButton>
        ) : null,
      }));
    }
    return [];
  }, [result, selectedData]);

  // const exportFile = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await api.post(
  //       `/workflow/serasa/serasa-negativacao/download/boletos-negativados`,
  //       {
  //         idShopping: selectedShoppings.map(e => e.value),
  //         luc: luc.length > 0 ? luc : undefined,
  //         ciclo,
  //         numBoleto: nrBoleto,
  //       },
  //       {
  //         responseType: 'arraybuffer',
  //       },
  //     );
  //     downloadXls(response);
  //     success('Arquivo baixado! Confira no gerenciador de arquivos de seu navegador.');
  //   } catch (error) {
  //     failure('Houve um erro ao tentar baixar o arquivo.');
  //   }
  //   setLoading(false);
  // };

  const enviarNegativacao = async () => {
    setLoading(true);
    try {
      const boletos = renderedData
        .filter(item => selectedData.includes(`${item.idSerasaBoleto}_${item.idPessoa}`))
        .map(item => ({
          idSerasaBoleto: item.idSerasaBoleto,
          idPessoa: item.idPessoa,
          tipoPessoa: item.tipoPessoa,
          operacao: item.operacao,
        }));
      const payload = { idUsuario, boletos };
      await api.post('/workflow/serasa/serasa-negativacao/reenviar-boletos', payload);
      const { data } = await api.post<StatusSerasaDTO>(`/workflow/serasa/serasa-negativacao/buscar/erro-negativados`, {
        idShopping: selectedShoppings.map(e => e.value),
        luc: luc.length > 0 ? luc : undefined,
        ciclo,
        numBoleto: nrBoleto,
      });
      setSelectedData([]);
      setResult(data.content);
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading || isLoading} />
      <S.Container>
        <h2>Reenvio retorno do Serasa</h2>
        <Form
          items={[
            <Select placeholder="Shoppings" options={shoppings} state={[selectedShoppings, setSelectedShoppings]} />,
            <Input label="LUC" state={[luc, setLuc]} />,
            <Input label="Ciclo" state={[ciclo, setCiclo]} />,
            <Input label="Número do boleto" state={[nrBoleto, setNrBoleto]} />,
          ]}
          submitButton={<Button onClick={getStatusSerasa} text="Pesquisar" />}
        />

        {result.length > 0 && (
          <>
            <Table
              columns={[
                { label: <Checkbox type="secondary" checked={bToggleAll} onClick={onToggleAll} />, key: 'toggle' },
                { label: 'Shopping', key: 'nmShopping', orderable: true },
                { label: 'Nome fantasia', key: 'nomFantasia', orderable: true },
                { label: 'Razão Social', key: 'nomRazaoSocial', orderable: true },
                { label: 'LUC', key: 'luc', orderable: true },
                { label: 'Número do boleto', key: 'numBoleto', orderable: true },
                { label: 'Vencimento do boleto', key: 'datVencimento', orderable: true },
                { label: 'Valor boleto', key: 'valTotalVencido', orderable: true },
                { label: 'Tipo pessoa', key: 'tipoPessoa', orderable: true },
                { label: 'Operação', key: 'operacaoNome', orderable: true },
                { label: 'Data retorno', key: 'dataRetornoSerasa', orderable: true },
                { label: 'Detalhes', key: 'detalhes' },
              ]}
              data={renderedData}
            />
            <S.Actions>
              {/* <Button text="Exportar" onClick={exportFile} /> */}
              <Button text="Enviar para negativação" onClick={enviarNegativacao} />
            </S.Actions>
            {details && <Modal details={details} onClose={() => setDetails(undefined)} />}
          </>
        )}
      </S.Container>
    </>
  );
};
