import React, { useEffect, useState, useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import FileSaver from 'file-saver';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Draggable from 'react-draggable';
import MaskedInput from 'react-maskedinput';
import { PriorizacaoContext } from '../../../_main/contexts/priorizacao';
import { AuthContext } from '../../../_main/contexts/auth';
import {
  FilterBar,
  TabMenu,
  TabItem,
  TabContent,
  Search,
  SelectArea,
  Dpicker,
  Label,
  DateArea,
  InputArea,
  RadioArea,
  Flex,
  Pagination,
  TableContainer,
  TH,
  Tick,
  PaginationWrapper,
  SearchPriorization,
} from './styles';
import Loader from '../../../assets/loader';
import iconSearch from '../../../assets/Icon_search.svg';
import api from '../../../_core/api';
import Modal from '../components/modal';
import * as ModalModules from '../components/modal/styles';
import 'react-datepicker/dist/react-datepicker.css';
import iconArrow from '../../../assets/apple-keyboard-control.svg';
import Select from '../components/deprecated-select';
import CheckboxWIthChecked from './checkbox-with-checked';

import { Multiselect, IOption } from '../components/select';
import Welcome from './welcome';
import { failure, warning } from '../../../_core/services/toast';
import { Form } from '../../../_core/_components/form';
import { Button } from '../../../_core/_components';

interface IShoppingItem {
  shoppingId: number;
  shoppingName: string;
  active: boolean;
}

export const Priorizacao: React.FC = () => {
  const history = useHistory();
  const {
    listShopping,
    tendenciasAlteradas,
    listPortifolio,
    listTendencia,
    selectedShopping,
    selectedShoppingTendencia,
    portifolioSelected,
    isTabActive,
    setListTendencia,
    setTendenciasAlteradas,
    setListShopping,
    setListPortifolio,
    setSelectedShopping,
    setSelectedShoppingTendencia,
    setPortifolioSelected,
    setIsTabActive,
  } = useContext(PriorizacaoContext);
  const { profile, shoppingProfileName, shoppingProfileId } = useContext(AuthContext);
  const [lucState, setLucState] = useState('');
  const [listTenCount, setListTenCount] = useState(0);
  const [companyState, setCompanyState] = useState('');
  const [contractState, setContractState] = useState('');
  const [billingGroupState, setBillingGroupState] = useState('');
  const [tendencieStateYes, setTendencieStateYes] = useState(true);
  const [tendencieStateNo, setTendencieStateNo] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [startDateFrom, setStartDateFrom] = useState(new Date());
  const [startDateTo, setStartDateTo] = useState(new Date());
  const [maxDate] = useState(new Date());
  const [billingGroups, setBillingGroups] = useState<OptionType[]>([]);
  const [pageShopping, setPageShopping] = useState([0, 0, 0, 0]);
  const [pagePortifolio, setPagePortifolio] = useState([0, 0, 0, 0]);
  const [pageTendencia, setPageTendencia] = useState(1);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(true);
  const [loading, setLoading] = useState(Boolean);
  /** new set state */
  const [shoppingList, setShoppingList] = useState<IOption[]>([]);
  const [portifolioList, setPortifolioList] = useState<IOption[]>([]);
  const [allTendencia, setAllTendencia] = useState<boolean>(true);
  const [hasCorporateBilling, setHasCorporateBilling] = useState<boolean>(true);
  const [contractList] = useState<OptionType[]>([
    { value: '', label: '' },
    { value: 'LOJA', label: 'LOJA' },
    { value: 'MALL', label: 'MALL' },
  ]);

  const objetoOrdenacaoShoppingZerado = {
    customerName: 0,
    brand: 0,
    cpfCnpj: 0,
    overdueBalanceInMonth: 0,
    totalOverdueBalance: 0,
    dueBalanceInMonth: 0,
    totalDueBalance: 0,
  };
  const [ordenacaoShopping0, setOrdenacaoShopping0] = useState<any>(objetoOrdenacaoShoppingZerado);
  const [ordenacaoShopping1, setOrdenacaoShopping1] = useState<any>(objetoOrdenacaoShoppingZerado);
  const [ordenacaoShopping2, setOrdenacaoShopping2] = useState<any>(objetoOrdenacaoShoppingZerado);
  const [ordenacaoShopping3, setOrdenacaoShopping3] = useState<any>(objetoOrdenacaoShoppingZerado);
  const [listTendenciaTrue, setListTendenciaTrue] = useState<any[]>([]);
  const [listTendenciaAlteradasTrue, setListTendenciaAlteradasTrue] = useState<any[]>([]);

  const objetoOrdenacaoTendenciaZerado = {
    shopping: 0,
    companyName: 0,
    cpfCnpj: 0,
    luc: 0,
    brand: 0,
    slipNumber: 0, // padron
    slipDueDateNegociation: 0,
  };
  const [ordenacaoTendencia, setOrdenacaoTendencia] = useState<any>(objetoOrdenacaoTendenciaZerado);

  const objetoOrdenacaoPortifolioZerado = {
    billingGroup: 0,
    numberOfLucs: 0,
    portfolio: 0,
    overdueBalanceInMonth: 0,
    totalOverdueBalance: 0,
    dueBalanceInMonth: 0,
    totalDueBalance: 0,
  };
  const [ordenacaoPortifolio0, setOrdenacaoPortifolio0] = useState<any>(objetoOrdenacaoPortifolioZerado);
  const [ordenacaoPortifolio1, setOrdenacaoPortifolio1] = useState<any>(objetoOrdenacaoPortifolioZerado);
  const [ordenacaoPortifolio2, setOrdenacaoPortifolio2] = useState<any>(objetoOrdenacaoPortifolioZerado);
  const [ordenacaoPortifolio3, setOrdenacaoPortifolio3] = useState<any>(objetoOrdenacaoPortifolioZerado);
  const [bgReturn, setbgReturn] = useState('');

  const [modalActive, setModalActive] = useState<boolean | undefined>(false);
  const [inputFilter, setInputFilter] = useState(['', '', '', '']);
  /** temp interfaces */
  type OptionType = {
    value: string;
    label: string;
  };

  interface IPortifolio {
    portifolioId: number;
    portifolioOwnerName: string;
  }

  /** loading data */

  useEffect(() => {}, [inputFilter, loading]);

  useEffect(() => {
    getShoppings();
    getPortifolios();
    getBillingGroups();
    getDate();
    keepClose();
    if ((listShopping.length == 0 && profile === 'COBRANÇA SHOPPING') || profile === 'APROVADOR SHOPPING') {
      getPriorizationByShopping();
    }
  }, []);

  /** new methods */
  const getShoppings = () => {
    const listItems: IOption[] = [];
    api.get('/charge-legal-information/shoppings').then(response => {
      response.data.map((item: IShoppingItem) => {
        listItems.push({
          id: item.shoppingId,
          label: item.shoppingName,
        });
      });
      listItems.sort((item1, item2) => ordemCrescente(item1, item2, 'label'));
      if (selectedShopping.length == 0) {
        setSelectedShopping([listItems[0]]);
      }
      if (selectedShoppingTendencia.length == 0) {
        setSelectedShoppingTendencia([listItems[0]]);
      }
      setShoppingList(listItems);
    });
  };

  function getDate() {
    const wm = localStorage.getItem('welcome_message_seen_siscob');
    if (!wm && moment().isAfter('2022-04-14') && moment().isBefore('2023-01-01')) {
      setIsWelcomeModalOpen(true);
    }
  }

  function ordemCrescente(item1: any, item2: any, prop: string) {
    if (item1[prop] > item2[prop]) return 1;
    if (item1[prop] < item2[prop]) return -1;
    return 0;
  }

  function keepClose() {
    const wm = localStorage.getItem('welcome_message_seen_siscob');
    if (wm !== null) {
      setIsWelcomeModalOpen(false);
    }
  }

  const closeWelcomeModal = () => {
    localStorage.setItem('welcome_message_seen_siscob', 'true');
    setIsWelcomeModalOpen(false);
  };

  function ordemDecrescente(item1: any, item2: any, prop: string) {
    if (item1[prop] < item2[prop]) return 1;
    if (item1[prop] > item2[prop]) return -1;
    return 0;
  }

  // ordem /--/ 1 = crescente /--/ 0= sem ordenação /--/ -1 = decrescente
  function ordernarShopping(nomeCampo: string, index: number) {
    let valorCampo = 0;
    switch (index) {
      case 0:
        valorCampo = ordenacaoShopping0[nomeCampo];
        break;
      case 1:
        valorCampo = ordenacaoShopping1[nomeCampo];
        break;
      case 2:
        valorCampo = ordenacaoShopping2[nomeCampo];
        break;
      case 3:
        valorCampo = ordenacaoShopping3[nomeCampo];
        break;
      default:
        break;
    }

    const listTempShopping = listShopping;
    if (valorCampo == 0) {
      listTempShopping[index].data.sort((item1: any, item2: any) => ordemCrescente(item1, item2, nomeCampo));
      setListShopping(listTempShopping);

      switch (index) {
        case 0:
          setOrdenacaoShopping0({ ...objetoOrdenacaoShoppingZerado, [nomeCampo]: 1 });
          break;
        case 1:
          setOrdenacaoShopping1({ ...objetoOrdenacaoShoppingZerado, [nomeCampo]: 1 });
          break;
        case 2:
          setOrdenacaoShopping2({ ...objetoOrdenacaoShoppingZerado, [nomeCampo]: 1 });
          break;
        case 3:
          setOrdenacaoShopping3({ ...objetoOrdenacaoShoppingZerado, [nomeCampo]: 1 });
          break;
        default:
          break;
      }
    } else if (valorCampo == 1) {
      listTempShopping[index].data.sort((item1: any, item2: any) => ordemDecrescente(item1, item2, nomeCampo));
      setListShopping(listTempShopping);

      switch (index) {
        case 0:
          setOrdenacaoShopping0({ ...objetoOrdenacaoShoppingZerado, [nomeCampo]: -1 });
          break;
        case 1:
          setOrdenacaoShopping1({ ...objetoOrdenacaoShoppingZerado, [nomeCampo]: -1 });
          break;
        case 2:
          setOrdenacaoShopping2({ ...objetoOrdenacaoShoppingZerado, [nomeCampo]: -1 });
          break;
        case 3:
          setOrdenacaoShopping3({ ...objetoOrdenacaoShoppingZerado, [nomeCampo]: -1 });
          break;
        default:
          break;
      }
    } else {
      listTempShopping[index].data.sort((item1: any, item2: any) => ordemCrescente(item1, item2, 'priority'));
      setListShopping(listTempShopping);

      switch (index) {
        case 0:
          setOrdenacaoShopping0({ ...objetoOrdenacaoShoppingZerado, [nomeCampo]: 0 });
          break;
        case 1:
          setOrdenacaoShopping1({ ...objetoOrdenacaoShoppingZerado, [nomeCampo]: 0 });
          break;
        case 2:
          setOrdenacaoShopping2({ ...objetoOrdenacaoShoppingZerado, [nomeCampo]: 0 });
          break;
        case 3:
          setOrdenacaoShopping3({ ...objetoOrdenacaoShoppingZerado, [nomeCampo]: 0 });
          break;
        default:
          break;
      }
    }
  }

  function testarOrdenacaoShopping(nomeCampo: string, index: number): string {
    let valorCampo = 0;
    switch (index) {
      case 0:
        valorCampo = ordenacaoShopping0[nomeCampo];
        break;
      case 1:
        valorCampo = ordenacaoShopping1[nomeCampo];
        break;
      case 2:
        valorCampo = ordenacaoShopping2[nomeCampo];
        break;
      case 3:
        valorCampo = ordenacaoShopping3[nomeCampo];
        break;
      default:
        break;
    }
    if (valorCampo === 0) {
      return 'decrescente';
    }
    if (valorCampo === 1) {
      return 'crescente';
    }

    return 'decrescente';
  }

  function ordenarPortifolio(nomeCampo: string, index: number) {
    let valorCampo = 0;
    switch (index) {
      case 0:
        valorCampo = ordenacaoPortifolio0[nomeCampo];
        break;
      case 1:
        valorCampo = ordenacaoPortifolio1[nomeCampo];
        break;
      case 2:
        valorCampo = ordenacaoPortifolio2[nomeCampo];
        break;
      case 3:
        valorCampo = ordenacaoPortifolio3[nomeCampo];
        break;
      default:
        break;
    }

    const listTempPortifolio = listPortifolio;
    if (valorCampo == 0) {
      listTempPortifolio[index].data.sort((item1: any, item2: any) => ordemCrescente(item1, item2, nomeCampo));
      setListPortifolio(listTempPortifolio);

      switch (index) {
        case 0:
          setOrdenacaoPortifolio0({ ...objetoOrdenacaoPortifolioZerado, [nomeCampo]: 1 });
          break;
        case 1:
          setOrdenacaoPortifolio1({ ...objetoOrdenacaoPortifolioZerado, [nomeCampo]: 1 });
          break;
        case 2:
          setOrdenacaoPortifolio2({ ...objetoOrdenacaoPortifolioZerado, [nomeCampo]: 1 });
          break;
        case 3:
          setOrdenacaoPortifolio3({ ...objetoOrdenacaoPortifolioZerado, [nomeCampo]: 1 });
          break;
        default:
          break;
      }
    } else if (valorCampo == 1) {
      listTempPortifolio[index].data.sort((item1: any, item2: any) => ordemDecrescente(item1, item2, nomeCampo));
      setListPortifolio(listTempPortifolio);

      switch (index) {
        case 0:
          setOrdenacaoPortifolio0({ ...objetoOrdenacaoPortifolioZerado, [nomeCampo]: -1 });
          break;
        case 1:
          setOrdenacaoPortifolio1({ ...objetoOrdenacaoPortifolioZerado, [nomeCampo]: -1 });
          break;
        case 2:
          setOrdenacaoPortifolio2({ ...objetoOrdenacaoPortifolioZerado, [nomeCampo]: -1 });
          break;
        case 3:
          setOrdenacaoPortifolio3({ ...objetoOrdenacaoPortifolioZerado, [nomeCampo]: -1 });
          break;
        default:
          break;
      }
    } else {
      listTempPortifolio[index].data.sort((item1: any, item2: any) => ordemCrescente(item1, item2, 'priority'));
      setListPortifolio(listTempPortifolio);

      switch (index) {
        case 0:
          setOrdenacaoPortifolio0({ ...objetoOrdenacaoPortifolioZerado, [nomeCampo]: 0 });
          break;
        case 1:
          setOrdenacaoPortifolio1({ ...objetoOrdenacaoPortifolioZerado, [nomeCampo]: 0 });
          break;
        case 2:
          setOrdenacaoPortifolio2({ ...objetoOrdenacaoPortifolioZerado, [nomeCampo]: 0 });
          break;
        case 3:
          setOrdenacaoPortifolio3({ ...objetoOrdenacaoPortifolioZerado, [nomeCampo]: 0 });
          break;
        default:
          break;
      }
    }
  }

  function testarOrdenacaoPortifolio(nomeCampo: string, index: number): string {
    let valorCampo = 0;
    switch (index) {
      case 0:
        valorCampo = ordenacaoPortifolio0[nomeCampo];
        break;
      case 1:
        valorCampo = ordenacaoPortifolio1[nomeCampo];
        break;
      case 2:
        valorCampo = ordenacaoPortifolio2[nomeCampo];
        break;
      case 3:
        valorCampo = ordenacaoPortifolio3[nomeCampo];
        break;
      default:
        break;
    }
    if (valorCampo === 0) {
      return 'decrescente';
    }
    if (valorCampo === 1) {
      return 'crescente';
    }

    return 'decrescente';
  }

  const getPortifolios = () => {
    const listItems: IOption[] = [];
    api.get('/filters/portifolio').then(response => {
      response.data.map((item: IPortifolio) => {
        listItems.push({
          id: item.portifolioId,
          label: item.portifolioOwnerName,
        });
      });
      setPortifolioList(listItems);
    });
  };

  const getBillingGroups = async () => {
    const billingGroup_list: OptionType[] = [];
    billingGroup_list.push({
      value: '',
      label: '',
    });
    api.get(`/filters/billing-groups`).then(response => {
      response.data.map((item: any) => {
        if (item.federationGroupName != null) {
          billingGroup_list.push({
            value: item.federationGroupId,
            label: item.federationGroupName,
          });
        }
      });
      setBillingGroups(billingGroup_list);
    });
  };

  function changeStartDateFrom(date: any) {
    setStartDateFrom(date);
    if (startDateTo < date) {
      setStartDateTo(date);
    }
  }

  function formatarData(data: string) {
    // 2020-05-02 para 02-05-2020
    try {
      return `${data.substring(8, 10)}/${data.substring(5, 7)}/${data.substring(0, 4)}`;
    } catch (error) {
      return '';
    }
  }

  /** old methods */
  const handleOnClickItem = (id: number, blockStart: string, blockEnd: string) => {
    history.push(
      `/boletos?shopping=${getShoppingName()}&id=${id}&blockStart=${blockStart}&blockEnd=${blockEnd}&shoppingId=${getShoppingId()}`,
    );
  };

  const handleOnClickItemBillingGroup = (id: number, blockStart: string, blockEnd: string) => {
    history.push(`/boletos?id=${id}&blockStart=${blockStart}&blockEnd=${blockEnd}`);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, tipo: string, value: number, index: number) => {
    if (tipo === 'shopping') {
      const newPage = [...pageShopping];
      newPage[index] = value - 1;
      setPageShopping(newPage);
    }
    if (tipo === 'portifolio') {
      const newPage = [...pagePortifolio];
      newPage[index] = value - 1;
      setPagePortifolio(newPage);
    }
    if (tipo === 'tendencia') {
      if (value === 1 && pageTendencia >= 2) {
        setPageTendencia(value);
      } else {
        setPageTendencia(value);
      }
    }
  };
  useEffect(() => {
    const page = pageTendencia;
    if (page >= 2) {
      reqTend();
    }
    if (listTendencia.length > 0 && page === 1) {
      reqTend();
    }
  }, [pageTendencia]);

  const countPages = (
    total: number,
    roundPerPage: number,
    index = 0,
    paginacaoDirente = false,
    roundPerPage2 = roundPerPage,
  ) => {
    if (index > 0 && paginacaoDirente === true) {
      const pages2 = total / roundPerPage2;
      return Math.ceil(pages2);
    }
    const pages = total / roundPerPage;
    return Math.ceil(pages);
  };

  const handleTab = (item: number) => {
    setIsTabActive(item);
    setStartDate(new Date());
  };

  function getShoppingName() {
    if (profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING') {
      return shoppingProfileName;
    }
    return selectedShopping[0].label;
  }

  function getShoppingNameTendencia() {
    if (profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING') {
      return shoppingProfileName;
    }
    return selectedShoppingTendencia[0].label;
  }

  function getShoppingId() {
    if (profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING') {
      return shoppingProfileId;
    }
    return selectedShopping[0].id;
  }

  function limpar(tipo: string) {
    setInputFilter(['', '', '', '']);
    if (tipo === 'shopping') {
      setPageShopping([0, 0, 0, 0]);
      setOrdenacaoShopping0(objetoOrdenacaoShoppingZerado);
      setOrdenacaoShopping1(objetoOrdenacaoShoppingZerado);
      setOrdenacaoShopping2(objetoOrdenacaoShoppingZerado);
      setOrdenacaoShopping3(objetoOrdenacaoShoppingZerado);
    }
    if (tipo === 'portifolio') {
      setPagePortifolio([0, 0, 0, 0]);
      setOrdenacaoPortifolio0(objetoOrdenacaoPortifolioZerado);
      setOrdenacaoPortifolio1(objetoOrdenacaoPortifolioZerado);
      setOrdenacaoPortifolio2(objetoOrdenacaoPortifolioZerado);
      setOrdenacaoPortifolio3(objetoOrdenacaoPortifolioZerado);
    }
    if (tipo === 'tendencia') {
      setOrdenacaoTendencia(objetoOrdenacaoTendenciaZerado);
    }
  }

  const getPriorizationByShopping = async () => {
    setLoading(true);
    limpar('shopping');

    const month = `${startDate.getMonth() + 1}`;
    const year = `${startDate.getFullYear()}`;

    api.get(`/prioritization/shopping/${getShoppingName()}?month=${month}&year=${year}&showFourthBlock=true`).then(
      response => {
        const resposta = response.data;

        if (resposta.length == 4) {
          resposta[3].month = '4º Bloco';
        }
        filtrarListShopping(resposta);
        setLoading(false);
      },
      error => {
        setListShopping([]);
        setLoading(false);
      },
    );
  };

  function handleHasCorporateBilling() {
    setHasCorporateBilling(!hasCorporateBilling);
  }

  function handleHasTendencySearch(valor: boolean) {
    if (valor) {
      setTendencieStateYes(!tendencieStateYes);
    } else {
      setTendencieStateNo(!tendencieStateNo);
    }
  }

  function filtrarListShopping(listaShopping: any[]) {
    if (hasCorporateBilling == true) {
      setListShopping(listaShopping);
    } else {
      const listTemp = listaShopping;
      listaShopping.map((list: any, index: number) => {
        const listFilter = list.data.filter((cliente: any) => cliente.hasCorporateBilling == false);
        listTemp[index].data = listFilter;
      });
      setListShopping(listaShopping);
    }
  }

  const reqApiCorp = async () => {
    setLoading(true);
    limpar('portifolio');

    let idSelected = '';
    portifolioSelected.map((portifolio: IOption) => {
      idSelected = `${idSelected + portifolio.id.toString()},`;
    });

    if (idSelected.length > 0) {
      idSelected = idSelected.substring(0, idSelected.length - 1);

      api.get(`filters/billing-groups?portifolioIds=${idSelected}`).then(response1 => {
        let cobrancasRetornadas = '';
        response1.data.map((gCobranca: any) => {
          if (gCobranca.federationGroupId != null) {
            cobrancasRetornadas = `${cobrancasRetornadas + gCobranca.federationGroupId.toString()},`;
            setbgReturn(cobrancasRetornadas);
          }
        });
        try {
          cobrancasRetornadas = cobrancasRetornadas.substring(0, cobrancasRetornadas.length - 1);
          setbgReturn(cobrancasRetornadas);
        } catch {
          cobrancasRetornadas = '';
          setbgReturn(cobrancasRetornadas);
        }

        const month = `${startDate.getMonth() + 1}`;
        const year = `${startDate.getFullYear()}`;

        api
          .get(
            `/prioritization/billing-group?groupIds=${cobrancasRetornadas}&month=${month}&year=${year}&showFourthBlock=true`,
          )
          .then(
            response2 => {
              const resposta = response2.data;

              if (resposta.length == 4) {
                resposta[3].month = '4º Bloco';
              }
              setListPortifolio(response2.data);
              setLoading(false);
            },
            error => {
              setLoading(false);
              setListPortifolio([]);
            },
          );
      });
    } else {
      setLoading(false);
      failure('Selecione pelo menos um item');
    }
  };

  const showItemsPage = (page: number, itemsPerPage: any, data: any) => {
    const indexofLastResult = (page + 1) * itemsPerPage;
    const indexOfFirstResult = indexofLastResult - itemsPerPage;
    const curretTodos = data?.slice(indexOfFirstResult, indexofLastResult);

    if (page === 0) {
      return curretTodos.length;
    }
    if (curretTodos.length !== itemsPerPage) {
      return data.length;
    }
    return curretTodos.length * (page + 1);
  };

  const showItemsPageStart = (page: number, itemsPerPage: any, data: any) => {
    const indexofLastResult = (page + 1) * itemsPerPage;
    const indexOfFirstResult = indexofLastResult - itemsPerPage;
    const curretTodos = data?.slice(indexOfFirstResult, indexofLastResult);

    if (page === 0 && itemsPerPage === 20) {
      return curretTodos.length / curretTodos.length;
    }
    if (page === 0 && itemsPerPage === 10) {
      return curretTodos.length / curretTodos.length;
    }
    if (curretTodos.length !== itemsPerPage) {
      return curretTodos.length - curretTodos.length + 1;
    }
    if (curretTodos.length === 20) {
      return curretTodos.length * (page + 1) - 19;
    }
    if (curretTodos.length === 10) {
      return curretTodos.length * (page + 1) - 9;
    }
  };

  const reqTend = async () => {
    setLoading(true);
    limpar('tendencia');

    const dayFrom = `0${startDateFrom.getDate()}`.slice(-2);
    const monthFrom = `0${startDateFrom.getMonth() + 1}`.slice(-2);
    const yearFrom = `${startDateFrom.getFullYear()}`;

    const dayTo = `0${startDateTo.getDate()}`.slice(-2);
    const monthTo = `0${startDateTo.getMonth() + 1}`.slice(-2);
    const yearTo = `${startDateTo.getFullYear()}`;

    const company = companyState ? `&companyName=${companyState}` : '';
    const luc = lucState ? `&LUC=${lucState}` : '';
    const contract = contractState ? `&contractType=${contractState}` : '';
    const billingGroupId = billingGroupState ? `&billingGroupIds=${billingGroupState}` : '';
    const tendencie = () => {
      if (tendencieStateYes && tendencieStateNo) {
        return '';
      }
      if (tendencieStateYes) {
        return `&tendencies=true`;
      }
      if (tendencieStateNo) {
        return `&tendencies=false`;
      }

      return '';
    };

    api
      .get(
        `/prioritization/slip-tendency?shoppingName=${getShoppingNameTendencia()}&dateFrom=${yearFrom}-${monthFrom}-${dayFrom}&dateTo=${yearTo}-${monthTo}-${dayTo}${company}${luc}${contract}${billingGroupId}${tendencie()}&page=${pageTendencia}`,
      )
      .then(
        response => {
          if (response.status == 200) {
            const resposta = response.data;

            resposta.data.sort((item1: any, item2: any) => ordemCrescente(item1, item2, 'slipNumber'));
            setOrdenacaoTendencia({
              ...objetoOrdenacaoTendenciaZerado,
              slipNumber: 1,
            });

            setListTendencia(resposta.data);
            setLoading(false);
            setTendenciasAlteradas([]);
            setPageTendencia(response.data.pageInfo.currentPage);
            setListTenCount(response.data.pageInfo.totalItems);
          } else {
            setLoading(false);
          }
        },
        error => {
          setLoading(false);
          setListTendencia([]);
          setTendenciasAlteradas([]);
        },
      );
  };

  const pegarTendenciasSelecionadas = () => {
    return listTendencia.filter(t => t.tendency == false || t.tendency == null);
  };

  const pegarTendenciasSelecionadasTrue = () => {
    return listTendenciaTrue.filter(t => t.tendency == true);
  };

  const toggleSelection = (tendencia: any, index: number) => {
    const tempList = tendenciasAlteradas;
    const today = new Date();

    const listTendenciaTemp = listTendencia.map((t, idx) => {
      if (index === idx) {
        if (tendencia === true) {
          tempList.push(index);
          t.tendency = false;
          setTendenciasAlteradas(tempList);
        } else {
          const itemOf = tendenciasAlteradas.indexOf(index);
          if (new Date(t.slipDueDateNegociation) >= today) {
            tempList.push(index);
            t.tendency = true;
            setListTendenciaAlteradasTrue(tempList);
          } else {
            warning('Boleto vencido!');
          }
        }
      }
      return t;
    });

    setListTendencia(listTendenciaTemp);
    setListTendenciaTrue(listTendenciaTemp);
  };

  const allToggleSelection = () => {
    if (allTendencia) {
      setAllTendencia(false);
      mudarSelectionAll(false);
    } else {
      setAllTendencia(true);
      mudarSelectionAll(true);
    }
  };

  function mudarSelectionAll(boolean: boolean) {
    const tempList = tendenciasAlteradas;
    const listTendenciaTemp = listTendencia.map((t, index) => {
      if (t.tendency == true && boolean == false) {
        tempList.push(index);
        t.tendency = false;
      } else if (t.tendency == true && boolean == true) {
      } else if (t.tendency == false && boolean == false) {
      } else {
        const itemOf = tendenciasAlteradas.indexOf(index);
        if (itemOf >= 0) {
          tempList.splice(itemOf, 1);
          t.tendency = true;
        }
      }
      return t;
    });
    setTendenciasAlteradas(tempList);
    setListTendencia(listTendenciaTemp);
  }

  function transformProtity(priority: any) {
    const str = `${priority}`;
    const pad = '000';
    return pad.substring(0, pad.length - str.length) + str;
  }

  // ordem /--/ 1 = crescente /--/ 0= sem ordenação /--/ -1 = decrescente
  function ordernarTendencia(nomeCampo: string) {
    const valorCampo = ordenacaoTendencia[nomeCampo];

    const listTempTendencia = listTendencia;
    if (valorCampo == 0) {
      listTempTendencia.sort((item1: any, item2: any) => ordemCrescente(item1, item2, nomeCampo));
      setListTendencia(listTempTendencia);

      setOrdenacaoTendencia({
        ...objetoOrdenacaoTendenciaZerado,
        [nomeCampo]: 1,
      });
    } else if (valorCampo == 1) {
      listTempTendencia.sort((item1: any, item2: any) => ordemDecrescente(item1, item2, nomeCampo));
      setListTendencia(listTempTendencia);

      setOrdenacaoTendencia({
        ...objetoOrdenacaoTendenciaZerado,
        [nomeCampo]: -1,
      });
    } else if (nomeCampo == 'slipNumber' && valorCampo == 1) {
      listTempTendencia.sort((item1: any, item2: any) => ordemDecrescente(item1, item2, 'slipNumber'));
      setListTendencia(listTempTendencia);

      setOrdenacaoTendencia({
        ...objetoOrdenacaoTendenciaZerado,
        slipNumber: -1,
      });
    } else {
      listTempTendencia.sort((item1: any, item2: any) => ordemCrescente(item1, item2, 'slipNumber'));
      setListTendencia(listTempTendencia);

      setOrdenacaoTendencia({
        ...objetoOrdenacaoTendenciaZerado,
        slipNumber: 1,
      });
    }
  }

  function testarOrdenacaoTendendia(nomeCampo: string): string {
    const valorCampo = ordenacaoTendencia[nomeCampo];
    if (valorCampo == 0) {
      return 'decrescente';
    }
    if (valorCampo == 1) {
      return 'crescente';
    }

    return 'decrescente';
  }

  function formatarRazaoSocial(nome: any) {
    try {
      if (nome.length <= 15) {
        return nome;
      }
      if (nome.charAt(14) == ' ' || nome.charAt(14) == ',') {
        return `${nome.substring(0, 14)}...`;
      }
      return `${nome.substring(0, 15)}...`;
    } catch {
      return '';
    }
  }

  function formatarNomeFantasia(nome: any, limitar = false) {
    try {
      if (limitar) {
        const nomeTemp = formatarRazaoSocial(nome);
        return nomeTemp.replaceAll(',', ', ');
      }
      return nome.replaceAll(',', ', ');
    } catch {
      return '';
    }
  }

  function retornarPaginacao(index: number) {
    if (index > 0) {
      return 10;
    }
    return 20;
  }

  function filtrar(bankSlip: any, value: any) {
    if (bankSlip.priority?.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1 && bankSlip.priority != null) {
      return true;
    }
    if (
      bankSlip.customerName?.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1 &&
      bankSlip.customerName != null
    ) {
      return true;
    }
    if (bankSlip.brand?.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1 && bankSlip.brand != null) {
      return true;
    }
    if (bankSlip.cpfCnpj?.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1 && bankSlip.cpfCnpj != null) {
      return true;
    }
    if (bankSlip.status?.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1 && bankSlip.status != null) {
      return true;
    }
    if (
      bankSlip.overdueBalanceInMonth?.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1 &&
      bankSlip.overdueBalanceInMonth !== null
    ) {
      return true;
    }
    if (
      bankSlip.totalOverdueBalance?.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1 &&
      bankSlip.totalOverdueBalance !== null
    ) {
      return true;
    }
    if (
      bankSlip.dueBalanceInMonth?.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1 &&
      bankSlip.dueBalanceInMonth != null
    ) {
      return true;
    }
    if (
      bankSlip.totalDueBalance?.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1 &&
      bankSlip.totalDueBalance != null
    ) {
      return true;
    }
    if (
      bankSlip.lastContactStatus?.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1 &&
      bankSlip.lastContactStatus != null
    ) {
      return true;
    }
    if (
      bankSlip.billingGroup?.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1 &&
      bankSlip.billingGroup != null
    ) {
      return true;
    }
    return false;
  }

  const handleChangeInputFilter = (e: any, index: number) => {
    const tmpInputFilter = [...inputFilter];
    tmpInputFilter[index] = e.target.value;
    setInputFilter(tmpInputFilter);
    const newPage = [...pageShopping];
    newPage[index] = 0;
    setPageShopping(newPage);
  };

  const handleChangeInputFilterPortifolio = (e: any, index: number) => {
    const tmpInputFilter = [...inputFilter];
    tmpInputFilter[index] = e.target.value;
    setInputFilter(tmpInputFilter);
    const newPage = [...pagePortifolio];
    newPage[index] = 0;
    setPagePortifolio(newPage);
  };

  function changeTendencyFlag() {
    const tendSelecionados = pegarTendenciasSelecionadas();
    const slipNumber: any[] = [];

    if (tendSelecionados.length > 0) {
      tendSelecionados.map(item => {
        slipNumber.push(parseFloat(item.slipNumber));
      });

      const objetoEnvio = {
        slipNumber,
        tendencyFlag: false,
      };

      api
        .patch('/prioritization/tendency-flag', objetoEnvio, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(
          response => {
            alert('Tendências salvas com sucesso');

            reqTend();
          },
          error => {
            alert('Não foi possível salvar as tendências');

            reqTend();
          },
        );
    }
  }

  function changeTendencyFlagTrue() {
    const tendSelecionados = pegarTendenciasSelecionadasTrue();
    const slipNumber: any[] = [];

    if (tendSelecionados.length > 0) {
      tendSelecionados.map(item => {
        slipNumber.push(parseFloat(item.slipNumber));
      });

      const objetoEnvio = {
        slipNumber,
        tendencyFlag: true,
      };

      api
        .patch('/prioritization/tendency-flag', objetoEnvio, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(
          response => {
            alert('Tendências salvas com sucesso');

            reqTend();
          },
          error => {
            alert('Não foi possível salvar as tendências');

            reqTend();
          },
        );
    }
  }

  function changeTendency() {
    setLoading(true);
    handleCloseModal();
    if (listTendenciaAlteradasTrue.length > 0) {
      changeTendencyFlagTrue();
    }
    if (tendenciasAlteradas.length > 0) {
      changeTendencyFlag();
    }
    setLoading(false);
  }

  const handleCloseModal = () => {
    setModalActive(false);
  };

  const handleOpenModal = () => {
    setModalActive(true);
    window.scrollTo(0, 0);
  };

  const handleTendencyExport = async () => {
    const dayFrom = `0${startDateFrom.getDate()}`.slice(-2);
    const monthFrom = `0${startDateFrom.getMonth() + 1}`.slice(-2);
    const yearFrom = `${startDateFrom.getFullYear()}`;

    const dayTo = `0${startDateTo.getDate()}`.slice(-2);
    const monthTo = `0${startDateTo.getMonth() + 1}`.slice(-2);
    const yearTo = `${startDateTo.getFullYear()}`;

    api
      .get(
        `/prioritization/slip-tendency/xls?shoppingName=${selectedShoppingTendencia[0].label}&dateFrom=${yearFrom}-${monthFrom}-${dayFrom}&dateTo=${yearTo}-${monthTo}-${dayTo}`,
        { responseType: 'arraybuffer' },
      )
      .then(response => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(blob, response.headers.filename);
      });
  };

  const handleExportShopping = async (index: number) => {
    const monthFrom = `${startDateFrom.getMonth() + 1}`.slice(-2);
    const yearFrom = `${startDateFrom.getFullYear()}`;

    api
      .get(
        `/prioritization/shopping/xls/${selectedShopping[0].label}?month=${monthFrom}&year=${yearFrom}&blockNumber=${index}`,
        { responseType: 'arraybuffer' },
      )
      .then(response => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(blob, response.headers.filename);
      });
  };

  const handleExportBG = async (index: number) => {
    const monthFrom = `${startDateFrom.getMonth() + 1}`.slice(-2);
    const yearFrom = `${startDateFrom.getFullYear()}`;

    api
      .get(
        `/prioritization/billing-group/xls?groupIds=${bgReturn}&month=${monthFrom}&year=${yearFrom}&blockNumber=${index}`,
        { responseType: 'arraybuffer' },
      )
      .then(response => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(blob, response.headers.filename);
      });
  };

  return (
    <>
      <Modal active={modalActive}>
        <Draggable>
          <ModalModules.Box>
            <ModalModules.Content style={{ textAlign: 'center' }}>
              <Label style={{ textAlign: 'center' }}>Tem certeza que irá tirar as têndencias?</Label>
              <ModalModules.Button type="button" onClick={() => handleCloseModal()}>
                Não
              </ModalModules.Button>
              <ModalModules.Button filled type="button" onClick={() => changeTendency()}>
                Sim
              </ModalModules.Button>
            </ModalModules.Content>
          </ModalModules.Box>
        </Draggable>
      </Modal>

      {/* <Welcome isOpen={isWelcomeModalOpen} closeWelcome={() => closeWelcomeModal()} /> */}
      <FilterBar>
        <TabMenu>
          <TabItem
            onClick={() => handleTab(1)}
            active={isTabActive === 1}
            noActive={profile === 'COBRANÇA CORPORATIVA'}
          >
            {profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING' ? shoppingProfileName : 'Por Shopping'}
          </TabItem>
          <TabItem
            onClick={() => handleTab(2)}
            active={isTabActive === 2}
            noActive={profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING'}
          >
            Por portifólio
          </TabItem>
          <TabItem onClick={() => handleTab(3)} active={isTabActive === 3}>
            Ajuste de tendência
          </TabItem>
        </TabMenu>
        <TabContent active={isTabActive === 1}>
          <Search>
            <SelectArea noActive={profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING'}>
              <Label>Shoppings</Label>
              <Multiselect
                selectedFromParent={selectedShopping}
                className="tipoShopping"
                onPick={(opcoes: IOption[]) => setSelectedShopping(opcoes)}
                search
                options={shoppingList}
                singleSelect
              />
            </SelectArea>
            <DateArea noActive={profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING'}>
              <Label>Mês e ano de inadimplência</Label>
              <Dpicker
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                maxDate={maxDate}
                customInput={<MaskedInput mask="11/1111" placeholder="MM/yyyy" />}
              />
            </DateArea>
          </Search>
          <Search>
            <CheckboxWIthChecked
              name="hasCorporateBilling"
              onChange={() => {
                handleHasCorporateBilling();
              }}
              checked={hasCorporateBilling}
            />
            <Label style={{ margin: '0', marginLeft: '6px' }}>Visualizar clientes corporativos</Label>
          </Search>
          <Form items={[<Button text="Consultar" onClick={getPriorizationByShopping} />]} />
        </TabContent>
        <TabContent active={isTabActive === 2}>
          <Search>
            <SelectArea>
              <Label>Portifólio de cobrança</Label>
              <Multiselect
                selectedFromParent={[]}
                className="tipoPortifolio"
                onPick={(opcoes: IOption[]) => setPortifolioSelected(opcoes)}
                search
                options={portifolioList}
                todos
                allSelectedPadrao
              />
            </SelectArea>
            <DateArea>
              <Label>Mês e ano de inadimplência</Label>
              <Dpicker
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                maxDate={maxDate}
                customInput={<MaskedInput mask="11/1111" placeholder="MM/yyyy" />}
              />
            </DateArea>
          </Search>
          <Form items={[<Button onClick={reqApiCorp} text="Consultar" />]} />
        </TabContent>

        <TabContent active={isTabActive === 3}>
          <Search>
            <SelectArea noActive={profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING'}>
              <Label>Shopping</Label>
              <Multiselect
                className="tipoShopping"
                selectedFromParent={selectedShoppingTendencia}
                onPick={(opcoes: IOption[]) => setSelectedShoppingTendencia(opcoes)}
                search
                options={shoppingList}
                singleSelect
              />
            </SelectArea>
            <DateArea noActive={profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING'}>
              <Label>Período (Data da negociação para pagamento)</Label>
              <Flex>
                <div className="div1">
                  <span>De</span>
                  <Dpicker
                    id="de"
                    name="de"
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={startDateFrom}
                    onChange={(date: any) => changeStartDateFrom(date)}
                    customInput={<MaskedInput mask="11/11/1111" placeholder="dd/MM/yyyy" />}
                  />
                </div>
                <div className="div2">
                  <span>Até</span>
                  <Dpicker
                    id="ate"
                    name="ate"
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={startDateTo}
                    minDate={startDateFrom}
                    onChange={(date: any) => setStartDateTo(date)}
                    customInput={<MaskedInput mask="11/11/1111" placeholder="dd/MM/yyyy" />}
                  />
                </div>
              </Flex>
            </DateArea>
            <SelectArea>
              <Label>Grupo de cobrança</Label>
              <Select
                name="billingGroup"
                helperClass="tipoPortifolio"
                options={billingGroups}
                onChange={e => {
                  setBillingGroupState(e.target.value);
                }}
              />
            </SelectArea>
          </Search>
          <Search className="noMarginBottom">
            <InputArea>
              <Label>Razão Social</Label>
              <input
                name="razao"
                placeholder="Digite a razão social."
                onChange={e => setCompanyState(e.target.value.toUpperCase())}
              />
            </InputArea>
            <InputArea>
              <Label>LUC</Label>
              <input
                name="luc"
                id="luc"
                placeholder="Digite a LUC"
                onChange={e => setLucState(e.target.value.toUpperCase())}
              />
            </InputArea>
            <SelectArea>
              <Label>Tipo de contrato</Label>
              <Select
                name="contract"
                helperClass="tipoContrato"
                options={contractList}
                onChange={e => {
                  setContractState(e.target.value);
                }}
              />
            </SelectArea>
            <RadioArea>
              <Label>Tendência</Label>
              <div>
                <Label>Sim</Label>
                <CheckboxWIthChecked
                  name="yes"
                  onChange={() => {
                    handleHasTendencySearch(true);
                  }}
                  checked={tendencieStateYes}
                />
                <Label>Não</Label>
                <CheckboxWIthChecked
                  name="no"
                  onChange={() => {
                    handleHasTendencySearch(false);
                  }}
                  checked={tendencieStateNo}
                />
              </div>
            </RadioArea>
            <Button onClick={reqTend} text="Consultar" />
          </Search>
        </TabContent>
      </FilterBar>

      {loading && (
        <div className="loaderUser">
          <Loader />
        </div>
      )}

      {!loading && isTabActive === 1 && listShopping.length > 0 && (
        <TableContainer>
          {listShopping.map((item, index) => (
            <div key={index}>
              <h1>
                {item.month} {item.month === '4º Bloco' ? ' ' : `de ${new Date(item.blockEndingDate).getFullYear()}`}
              </h1>
              <SearchPriorization>
                <img src={iconSearch} />
                <input onChange={e => handleChangeInputFilter(e, index)} />
              </SearchPriorization>
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '66px' }}>Prioridade</th>
                    <th style={{ width: '66px' }}>Corporativo</th>
                    <th style={{ width: '141px' }} onClick={() => ordernarShopping('customerName', index)}>
                      Razão Social
                      <img alt="" src={iconArrow} className={testarOrdenacaoShopping('customerName', index)} />
                    </th>
                    <th style={{ width: '182px' }} onClick={() => ordernarShopping('brand', index)}>
                      Nome fantasia
                      <img alt="" src={iconArrow} className={testarOrdenacaoShopping('brand', index)} />
                    </th>
                    <th style={{ width: '67px' }} onClick={() => ordernarShopping('cpfCnpj', index)}>
                      CPF/CNPJ
                      <img alt="" src={iconArrow} className={testarOrdenacaoShopping('cpfCnpj', index)} />
                    </th>
                    <th style={{ width: '132px' }}> </th>
                    <th
                      style={{ width: '86px' }}
                      onClick={() => ordernarShopping('overdueBalanceInMonth', index)}
                      data-tip
                      data-for="overdueBalanceInMonth"
                    >
                      Saldo vencido
                      <br />
                      no mês/ano
                      <img alt="" src={iconArrow} className={testarOrdenacaoShopping('overdueBalanceInMonth', index)} />
                      <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="overdueBalanceInMonth">
                        <span>
                          Soma dos valores dos boletos com vencimentos negociados, se vazio, considerar o original,
                          entre o 1º dia e o último dia
                          <br />
                          do mês selecionado no campo “Mês Inadimplência”, anteriores a D-2 úteis da data de consulta
                        </span>
                      </ReactTooltip>
                    </th>
                    <th
                      style={{ width: '118px' }}
                      onClick={() => ordernarShopping('totalOverdueBalance', index)}
                      data-tip
                      data-for="totalOverdueBalance"
                    >
                      Saldo vencido
                      <br />
                      total
                      <img alt="" src={iconArrow} className={testarOrdenacaoShopping('totalOverdueBalance', index)} />
                      <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="totalOverdueBalance">
                        <span>
                          Soma dos valores dos boletos com vencimentos negociados, se vazio, considerar original,
                          anteriores a D-2 da data de consulta
                        </span>
                      </ReactTooltip>
                    </th>
                    <th
                      style={{ width: '90px' }}
                      onClick={() => ordernarShopping('dueBalanceInMonth', index)}
                      data-tip
                      data-for="dueBalanceInMonth"
                    >
                      Saldo a vencer
                      <br />
                      no mês/ano
                      <img alt="" src={iconArrow} className={testarOrdenacaoShopping('dueBalanceInMonth', index)} />
                      <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="dueBalanceInMonth">
                        <span>
                          Soma dos valores dos boletos com vencimentos negociados, se vazio, considerar original, a
                          partir da data de consulta,
                          <br />
                          entre o 1º dia e o último dia do mês selecionado no campo “Mês Inadimplência”
                        </span>
                      </ReactTooltip>
                    </th>
                    <th
                      style={{ width: '105px' }}
                      onClick={() => ordernarShopping('totalDueBalance', index)}
                      data-tip
                      data-for="totalDueBalance"
                    >
                      Saldo a vencer
                      <br />
                      total
                      <img alt="" src={iconArrow} className={testarOrdenacaoShopping('totalDueBalance', index)} />
                      <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="totalDueBalance">
                        <span>
                          Valores dos boletos com vencimentos negociados, se vazio, considerar original, a partir da
                          data de consulta
                        </span>
                      </ReactTooltip>
                    </th>
                    <th style={{ width: '24px' }}> </th>
                    <th style={{ width: '216px' }}>Último contato</th>
                    <th style={{ width: '27px' }}> </th>
                  </tr>
                </thead>
                <tbody>
                  {[...item.data]
                    .filter(bankSlip => filtrar(bankSlip, inputFilter[index]))
                    .slice(
                      pageShopping[index] * retornarPaginacao(index),
                      pageShopping[index] * retornarPaginacao(index) + retornarPaginacao(index),
                    )
                    .map(d => (
                      <tr
                        key={d.priority}
                        onClick={() => handleOnClickItem(d.customer, item.blockStartingDate, item.blockEndingDate)}
                      >
                        <td>{transformProtity(d.priority)}</td>
                        <td>{d.hasCorporateBilling ? 'SIM' : 'NÃO'}</td>
                        <td
                          style={{ whiteSpace: 'nowrap' }}
                          data-tip
                          data-for={d.priority.toString().concat('shopping')}
                        >
                          {formatarRazaoSocial(d.customerName)}
                          <ReactTooltip
                            backgroundColor="#31C6D3"
                            className="tooltip"
                            id={d.priority.toString().concat('shopping')}
                          >
                            <span>{d.customerName}</span>
                          </ReactTooltip>
                        </td>
                        <td
                          style={{ whiteSpace: 'nowrap' }}
                          data-tip
                          data-for={d.priority.toString().concat('fantasia')}
                        >
                          {formatarNomeFantasia(d.brand, true)}
                          <ReactTooltip
                            backgroundColor="#31C6D3"
                            className="tooltip"
                            id={d.priority.toString().concat('fantasia')}
                          >
                            <span>{formatarNomeFantasia(d.brand)}</span>
                          </ReactTooltip>
                        </td>
                        <td>{d.cpfCnpj}</td>
                        <td>{d.status}</td>

                        <td>
                          {d.overdueBalanceInMonth.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td>
                          {d.totalOverdueBalance.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td>
                          {d.dueBalanceInMonth.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td>
                          {d.totalDueBalance.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td />
                        <td>{d.lastContactStatus}</td>
                        <td />
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2}>
                      <button onClick={() => handleExportShopping(index + 1)} type="button">
                        Exportar
                      </button>
                    </td>
                    <td>
                      <p style={{ textAlign: 'left' }}>
                        {'Total de clientes: '}
                        {/* {[...item.data].filter(bankSlip => filtrar(bankSlip, inputFilter[index])).slice(pageShopping[index] * retornarPaginacao(index), (pageShopping[index] * retornarPaginacao(index)) + retornarPaginacao(index)).length}  */}
                        {/* {console.log([...item.data].filter(bankSlip => filtrar(bankSlip, inputFilter[index])).slice(pageShopping[index] * retornarPaginacao(index), (pageShopping[index] * retornarPaginacao(index)) + retornarPaginacao(index)).length)} */}
                        {showItemsPageStart(pageShopping[index], retornarPaginacao(index), [...item.data])}
                        {' a '}
                        {showItemsPage(pageShopping[index], retornarPaginacao(index), [...item.data])}
                        {' de '}
                        {[...item.data].filter(bankSlip => filtrar(bankSlip, inputFilter[index])).length}
                      </p>
                    </td>
                    <td />
                    <td>
                      <p>Valores totais:</p>
                    </td>
                    <td>
                      {[...item.data]
                        .filter(bankSlip => filtrar(bankSlip, inputFilter[index]))
                        .reduce((acc, v) => acc + v.overdueBalanceInMonth, 0)
                        .toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </td>
                    <td>
                      {[...item.data]
                        .filter(bankSlip => filtrar(bankSlip, inputFilter[index]))
                        .reduce((acc, v) => acc + v.totalOverdueBalance, 0)
                        .toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </td>
                    <td>
                      {[...item.data]
                        .filter(bankSlip => filtrar(bankSlip, inputFilter[index]))
                        .reduce((acc, v) => acc + v.dueBalanceInMonth, 0)
                        .toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </td>
                    <td>
                      {[...item.data]
                        .filter(bankSlip => filtrar(bankSlip, inputFilter[index]))
                        .reduce((acc, v) => acc + v.totalDueBalance, 0)
                        .toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </td>

                    <td colSpan={3}>
                      <PaginationWrapper>
                        <Pagination
                          count={countPages(
                            [...item.data].filter(bankSlip => filtrar(bankSlip, inputFilter[index])).length,
                            20,
                            index,
                            true,
                            10,
                          )}
                          page={pageShopping[index] + 1}
                          onChange={(e, page) => handleChange(e, 'shopping', page, index)}
                        />
                      </PaginationWrapper>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          ))}
        </TableContainer>
      )}

      {!loading && isTabActive === 2 && listPortifolio.length > 0 && (
        <TableContainer>
          {listPortifolio.map((item, index) => (
            <div key={index}>
              <h1>
                {item.month} {item.month === '4º Bloco' ? ' ' : `de ${new Date(item.blockEndingDate).getFullYear()}`}
              </h1>
              <SearchPriorization>
                <img src={iconSearch} alt="icon search" />
                <input onChange={e => handleChangeInputFilterPortifolio(e, index)} />
              </SearchPriorization>
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '84px' }}>Prioridade</th>
                    <th style={{ width: '123px' }} onClick={() => ordenarPortifolio('billingGroup', index)}>
                      Nome do grupo
                      <img alt="" src={iconArrow} className={testarOrdenacaoPortifolio('billingGroup', index)} />
                    </th>
                    <th style={{ width: '107px' }} onClick={() => ordenarPortifolio('numberOfLucs', index)}>
                      Total de LUCs
                      <img alt="" src={iconArrow} className={testarOrdenacaoPortifolio('numberOfLucs', index)} />
                    </th>
                    <th style={{ width: '157px' }} onClick={() => ordenarPortifolio('portfolio', index)}>
                      Usuário responsável
                      <img alt="" src={iconArrow} className={testarOrdenacaoPortifolio('portfolio', index)} />
                    </th>
                    <th style={{ width: '164px' }} />
                    <th
                      style={{ width: '109px' }}
                      onClick={() => ordenarPortifolio('overdueBalanceInMonth', index)}
                      data-tip
                      data-for="overdueBalanceInMonth"
                    >
                      Saldo vencido
                      <br />
                      no mês/ano
                      <img
                        alt=""
                        src={iconArrow}
                        className={testarOrdenacaoPortifolio('overdueBalanceInMonth', index)}
                      />
                      <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="overdueBalanceInMonth">
                        <span>
                          Soma dos valores dos boletos com vencimentos negociados, se vazio, considerar o original,
                          entre o 1º dia e o último dia <br />
                          do mês selecionado no campo “Mês Inadimplência”, anteriores a D-2 úteis da data de consulta
                        </span>
                      </ReactTooltip>
                    </th>
                    <th
                      style={{ width: '148px' }}
                      onClick={() => ordenarPortifolio('totalOverdueBalance', index)}
                      data-tip
                      data-for="totalOverdueBalance"
                    >
                      Saldo vencido
                      <br />
                      total
                      <img alt="" src={iconArrow} className={testarOrdenacaoPortifolio('totalOverdueBalance', index)} />
                      <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="totalOverdueBalance">
                        <span>
                          Soma dos valores dos boletos com vencimentos negociados, se vazio, considerar original,
                          anteriores a D-2 da data de consulta
                        </span>
                      </ReactTooltip>
                    </th>
                    <th
                      style={{ width: '114px' }}
                      onClick={() => ordenarPortifolio('dueBalanceInMonth', index)}
                      data-tip
                      data-for="dueBalanceInMonth"
                    >
                      Saldo a vencer
                      <br />
                      no mês/ano
                      <img alt="" src={iconArrow} className={testarOrdenacaoPortifolio('dueBalanceInMonth', index)} />
                      <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="dueBalanceInMonth">
                        <span>
                          Soma dos valores dos boletos com vencimentos negociados, se vazio, considerar original, a
                          partir da data de consulta, <br />
                          entre o 1º dia e o último dia do mês selecionado no campo “Mês Inadimplência”
                        </span>
                      </ReactTooltip>
                    </th>
                    <th
                      style={{ width: '121px' }}
                      onClick={() => ordenarPortifolio('totalDueBalance', index)}
                      data-tip
                      data-for="totalDueBalance"
                    >
                      Saldo a vencer
                      <br />
                      total
                      <img alt="" src={iconArrow} className={testarOrdenacaoPortifolio('totalDueBalance', index)} />
                      <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="totalDueBalance">
                        <span>
                          Valores dos boletos com vencimentos negociados, se vazio, considerar original, a partir da
                          data de consulta
                        </span>
                      </ReactTooltip>
                    </th>
                    <th style={{ width: '6px' }} />
                    <th style={{ width: '141px' }}>Status Marca</th>
                  </tr>
                </thead>
                <tbody>
                  {[...item.data]
                    .filter(bankSlip => filtrar(bankSlip, inputFilter[index]))
                    .slice(
                      pagePortifolio[index] * retornarPaginacao(index),
                      pagePortifolio[index] * retornarPaginacao(index) + retornarPaginacao(index),
                    )
                    .map(d => (
                      <tr
                        key={d.priority}
                        onClick={() =>
                          handleOnClickItemBillingGroup(d.billingGroupId, item.blockStartingDate, item.blockEndingDate)
                        }
                      >
                        <td>{transformProtity(d.priority)}</td>
                        <td>{d.billingGroup}</td>
                        <td>{d.numberOfLucs}</td>
                        <td>{d.portfolio}</td>
                        <td />
                        <td>
                          {d.overdueBalanceInMonth.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td>
                          {d.totalOverdueBalance.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td>
                          {d.dueBalanceInMonth.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td>
                          {d.totalDueBalance.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td />
                        <td>{d.brandStatus}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2}>
                      <button type="button" onClick={() => handleExportBG(index + 1)}>
                        Exportar
                      </button>
                    </td>
                    <td colSpan={2}>
                      <p style={{ textAlign: 'left' }}>
                        {'Total de clientes: '}
                        {
                          [...item.data]
                            .filter(bankSlip => filtrar(bankSlip, inputFilter[index]))
                            .slice(
                              pagePortifolio[index] * retornarPaginacao(index),
                              pagePortifolio[index] * retornarPaginacao(index) + retornarPaginacao(index),
                            ).length
                        }
                        {' de '}
                        {[...item.data].filter(bankSlip => filtrar(bankSlip, inputFilter[index])).length}
                      </p>
                    </td>
                    <td>
                      <p>Valores totais:</p>
                    </td>
                    <td>
                      {[...item.data]
                        .filter(bankSlip => filtrar(bankSlip, inputFilter[index]))
                        .reduce((acc, v) => acc + v.overdueBalanceInMonth, 0)
                        .toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </td>
                    <td>
                      {[...item.data]
                        .filter(bankSlip => filtrar(bankSlip, inputFilter[index]))
                        .reduce((acc, v) => acc + v.totalOverdueBalance, 0)
                        .toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </td>
                    <td>
                      {[...item.data]
                        .filter(bankSlip => filtrar(bankSlip, inputFilter[index]))
                        .reduce((acc, v) => acc + v.dueBalanceInMonth, 0)
                        .toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </td>
                    <td>
                      {[...item.data]
                        .filter(bankSlip => filtrar(bankSlip, inputFilter[index]))
                        .reduce((acc, v) => acc + v.totalDueBalance, 0)
                        .toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </td>
                    <td colSpan={2}>
                      <PaginationWrapper>
                        <Pagination
                          count={countPages(
                            [...item.data].filter(bankSlip => filtrar(bankSlip, inputFilter[index])).length,
                            20,
                            index,
                            true,
                            10,
                          )}
                          page={pagePortifolio[index] + 1}
                          onChange={(e, page) => handleChange(e, 'portifolio', page, index)}
                        />
                      </PaginationWrapper>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          ))}
        </TableContainer>
      )}

      {!loading && isTabActive === 3 && listTendencia.length > 0 && (
        <TableContainer>
          <div>
            <table>
              <thead>
                <tr>
                  <th onClick={() => ordernarTendencia('shopping')}>
                    Shopping
                    <img src={iconArrow} className={testarOrdenacaoTendendia('shopping')} />
                  </th>
                  <th onClick={() => ordernarTendencia('companyName')}>
                    Razão Social
                    <img src={iconArrow} className={testarOrdenacaoTendendia('companyName')} />
                  </th>
                  <th onClick={() => ordernarTendencia('cpfCnpj')}>
                    CPF/CNPJ
                    <img src={iconArrow} className={testarOrdenacaoTendendia('cpfCnpj')} />
                  </th>
                  <th onClick={() => ordernarTendencia('luc')}>
                    LUC
                    <img src={iconArrow} className={testarOrdenacaoTendendia('luc')} />
                  </th>
                  <th onClick={() => ordernarTendencia('brand')}>
                    Nome Fantasia
                    <img src={iconArrow} className={testarOrdenacaoTendendia('brand')} />
                  </th>
                  <th onClick={() => ordernarTendencia('slipNumber')}>
                    Nº Boleto
                    <img src={iconArrow} className={testarOrdenacaoTendendia('slipNumber')} />
                  </th>
                  <th onClick={() => ordernarTendencia('slipDueDateNegociation')}>
                    Data de venc.
                    <br />
                    Negociado
                    <img src={iconArrow} className={testarOrdenacaoTendendia('slipDueDateNegociation')} />
                  </th>
                  <th>Tipo Contrato</th>
                  <th>Valor negociado</th>
                  <TH>
                    <p>Tendência</p>

                    <br />
                    <Tick
                      className={allTendencia === true ? 'selected' : ''}
                      onClick={() => {
                        allToggleSelection();
                      }}
                    />
                  </TH>
                </tr>
              </thead>
              <tbody>
                {listTendencia.map((item, index) => (
                  <tr key={index}>
                    <td>{item.shopping}</td>
                    <td style={{ whiteSpace: 'nowrap' }} data-tip data-for={index.toString().concat('tendencia')}>
                      {formatarRazaoSocial(item.companyName)}
                      <ReactTooltip
                        backgroundColor="#31C6D3"
                        className="tooltip"
                        id={index.toString().concat('tendencia')}
                      >
                        <span>{item.companyName}</span>
                      </ReactTooltip>
                    </td>
                    <td>{item.cpfCnpj}</td>
                    <td>{item.luc}</td>
                    <td>{formatarNomeFantasia(item.brand)}</td>
                    <td>{item.slipNumber}</td>
                    <td>{formatarData(item.slipDueDateNegociation)}</td>
                    <td>{item.contractType}</td>
                    <td>
                      {item.amountNegiciation.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </td>
                    <td>
                      <Tick
                        className={item.tendency === true ? 'selected' : ''}
                        onClick={() => {
                          toggleSelection(item.tendency, (pageTendencia - 1) * 10 + index);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2}>
                    <button onClick={() => handleTendencyExport()} type="button">
                      Exportar
                    </button>
                  </td>
                  <td colSpan={2}>
                    <p style={{ textAlign: 'left' }}>
                      {'Total de clientes: '}
                      {listTendencia.slice((pageTendencia - 1) * 50, (pageTendencia - 1) * 50 + 50).length}
                      {' de '}
                      {listTenCount}
                    </p>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td>
                    <p>Valor total:</p>
                  </td>
                  <td>
                    {[...listTendencia]
                      .reduce((acc, v) => acc + v.amountNegiciation, 0)
                      .toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                  </td>
                  <div style={{ marginTop: '10px' }}>
                    <Button text="Salvar Têndencia" onClick={handleOpenModal} />
                  </div>
                  <td />
                </tr>
                <tr>
                  <td colSpan={10}>
                    <PaginationWrapper>
                      <Pagination
                        count={countPages(listTenCount, 50)}
                        page={pageTendencia}
                        onChange={(e, page) => handleChange(e, 'tendencia', page, 0)}
                      />
                    </PaginationWrapper>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </TableContainer>
      )}
    </>
  );
};
