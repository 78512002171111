import styled from 'styled-components';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import Pages from '@material-ui/lab/Pagination';

type OrderableProps = {
  arrowDirection?: 'desc' | 'asc';
};

export const Container = styled.div`
  h1 {
    font-size: 20px;
    font-weight: bold;
    color: #7e9395;
    padding: 20px 0;
  }
  label {
    font-size: 14px;
    color: #7e9395;
    margin: 0 10px;
  }
`;

export const FilterBar = styled.div`
  max-width: 70%;
  background-color: white;
  height: 100px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  input {
    min-width: 100px;
    height: 40px;
    width: 100%;
    padding: 7px 15px;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 20px;
  }
  button {
    min-width: 138px;
    height: 40px;
    background: #31c6d3;
    border-radius: 25px;
    color: #fff !important;
    font-size: 16px;
    border: none;
  }
`;

export const InputDate = styled(InputMask)`
  height: 40px;
  width: 100%;
  padding: 7px 15px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
`;

export const SizedBox = styled.div`
  min-width: 32px;
`;

export const SizedBoxHeight = styled.div`
  min-height: 15px;
`;

export const RadioArea = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
  button {
    justify-content: center;
    &:first-child {
      margin-right: 20px;
    }
  }
`;

export const FileDownload = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
