import React from 'react';
import TextField from '@material-ui/core/TextField';

interface Props {
  state: [string, React.Dispatch<React.SetStateAction<string>>];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  error?: boolean;
  pattern?: (value: string) => string;
  onBlur?: () => any;
}

export function Input({
  state: [text, setText],
  label,
  placeholder,
  disabled = false,
  style = {},
  error,
  pattern,
  onBlur,
}: Props): JSX.Element {
  const onSetText = (value: string) => {
    if (pattern) {
      const newValue = pattern(value);
      setText(newValue);
      return;
    }
    setText(value);
  };

  return (
    <TextField
      value={text}
      onChange={(e: any) => onSetText(e.target.value)}
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      onBlur={onBlur}
      size="small"
      error={error}
      InputProps={{
        style: {
          borderRadius: 30,
          ...style,
        },
      }}
    />
  );
}
