import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import api from '../../../_core/api';
import { Container, Actions } from './styles';
import { handleErrors, success } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { Table } from '../../../_core/_components/table';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { Button, Checkbox } from '../../../_core/_components';

interface ICliente {
  id: number;
  shoppingId: number;
  shoppingName: string;
  evictionQuantity: number;
  executionQuantity: number;
  complaintEvictionQuantity: number;
  terminationExecutionQuantity: number;
  selected: boolean;
}

export const ContratosElegiveis: React.FC = () => {
  const [clients, setClients] = useState<ICliente[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  const getContratosElegiveis = async () => {
    setLoading(true);
    try {
      const listClientes: ICliente[] = [];
      const response = await api.get('/judgment/total-shopping');
      response.data.forEach((item: ICliente) => {
        listClientes.push({
          id: item.shoppingId,
          shoppingId: item.shoppingId,
          shoppingName: item.shoppingName,
          evictionQuantity: item.evictionQuantity,
          executionQuantity: item.executionQuantity,
          complaintEvictionQuantity: item.complaintEvictionQuantity,
          terminationExecutionQuantity: item.terminationExecutionQuantity,
          selected: false,
        });
      });
      setClients(listClientes);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getContratosElegiveis();
  }, []);

  const onSingleToggle = (id: number) => {
    const arr = [...selectedIds];
    if (arr.includes(id)) {
      setSelectedIds(arr.filter(e => e !== id));
      return;
    }
    arr.push(id);
    setSelectedIds(arr);
  };

  const allToggle = useMemo(() => {
    if (clients && selectedIds.length > 0) {
      const ids = clients.map(e => e.shoppingId);
      return _.isEqual(ids, selectedIds);
    }
    return false;
  }, [clients, selectedIds]);

  const onToggleAll = () => {
    if (allToggle) {
      setSelectedIds([]);
      return;
    }
    const ids = clients.map(e => e.shoppingId);
    setSelectedIds(ids);
  };

  const handleExportButton = async () => {
    setLoading(true);
    try {
      const names = clients.filter(item => selectedIds.includes(item.shoppingId)).map(item => item.shoppingName);
      const responseXLS = await api.get(`/judgment/total-shopping/xls?shoppings=${names.join(',')}`, {
        responseType: 'arraybuffer',
      });
      downloadXls(responseXLS);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const submit = async () => {
    setLoading(true);
    try {
      const shoppingIds = selectedIds.join(',');
      await api.put(`/workflow/ajuizamento/liberar?shoppingIds=${shoppingIds}`);
      setSelectedIds([]);
      const { data } = await api.get('workflow/ajuizamento/contratos-elegiveis');
      setClients(data);
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  function totalizador(atributo: string) {
    return clients?.reduce((acc: number, v: any) => acc + v[atributo], 0);
  }

  function total(clientes: ICliente[]) {
    let sum = 0;
    clientes.forEach((cliente: ICliente) => {
      sum =
        sum +
        cliente.complaintEvictionQuantity +
        cliente.evictionQuantity +
        cliente.executionQuantity +
        cliente.terminationExecutionQuantity;
    });
    return sum;
  }

  const renderedData = useMemo(() => {
    if (clients.length > 0) {
      return clients.map(item => ({
        ...item,
        total: total([item]),
        toggle: (
          <Checkbox checked={selectedIds.includes(item.shoppingId)} onClick={() => onSingleToggle(item.shoppingId)} />
        ),
      }));
    }
    return [];
  }, [clients, selectedIds]);
  console.log(selectedIds);
  return (
    <Container>
      <FullScreenLoading isEnabled={loading} />
      {renderedData.length > 0 && (
        <>
          <Table
            columns={[
              {
                label: <Checkbox type="secondary" checked={allToggle} onClick={onToggleAll} />,
                key: 'toggle',
              },
              { label: 'Nome do Shopping', key: 'shoppingName', orderable: true },
              { label: 'Despejo', key: 'evictionQuantity', orderable: true },
              { label: 'Execução', key: 'executionQuantity', orderable: true },
              { label: 'Denúncia/Despejo', key: 'complaintEvictionQuantity', orderable: true },
              { label: 'Denúncia/Execução', key: 'terminationExecutionQuantity', orderable: true },
              { label: 'Total', key: 'total', orderable: true },
            ]}
            data={renderedData}
            footer={[
              '',
              clients.length,
              totalizador('evictionQuantity'),
              totalizador('executionQuantity'),
              totalizador('complaintEvictionQuantity'),
              totalizador('terminationExecutionQuantity'),
              total(clients),
            ]}
          />

          <Actions>
            <Button onClick={handleExportButton} text="Exportar" />
            <Button onClick={submit} text="Submeter recomendação" />
          </Actions>
        </>
      )}
    </Container>
  );
};
