/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import * as S from './styles';
import { AuthContext } from '../../../_main/contexts/auth';
import { failure, handleErrors } from '../../../_core/services/toast';
import api from '../../../_core/api';

import { Button, Form as MaterialForm, Option, Select, Input, Checkbox } from '../../../_core/_components';
import { requests } from '../../../requests';
import { useCache } from '../../../_core/cache';

type Shoppping = {
  shoppingId: number;
  shoppingName: string;
};

type Props = {
  status: string;
  submit: (payload: string) => Promise<void>;
};

export function Form({ status, submit }: Props): JSX.Element {
  const { response: responseTipoAcao } = useCache({
    key: 'getTipoAcao',
    fn: requests.get('getTipoAcao'),
  });

  const { shoppings: profileShoppings } = useContext(AuthContext);

  const [shoppingOptions, setShoppingOptions] = useState<Option[]>([]);
  const [gruposCobranca, setGruposCobranca] = useState<Option[]>([]);
  const [portfolios, setPortfolios] = useState<Option[]>([]);
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [marca, setMarca] = useState<string>('');
  const [luc, setLuc] = useState<string>('');
  const [selectedTipoAcao, setSelectedTipoAcao] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [selectedPortfolios, setSelectedPortfolios] = useState<Option[]>([]);
  const [selectedGruposCobranca, setSelectedGruposCobranca] = useState<Option[]>([]);
  const [numBoleto, setNumBoleto] = useState<string>('');

  const tipoAcaoOptions = useMemo(() => {
    if (responseTipoAcao) {
      return responseTipoAcao.data.data.map((e: any) => ({
        value: e.id,
        label: e.nome,
      }));
    }
    return [];
  }, [responseTipoAcao]);

  function convertPayloadToQueryString(payload: any) {
    const params = [];

    for (const key in payload) {
      const value = payload[key];
      if (value !== undefined && value !== null && value !== '') {
        if (Array.isArray(value)) {
          value.forEach(item => params.push(`${key}=${encodeURIComponent(item)}`));
        } else {
          params.push(`${key}=${encodeURIComponent(value)}`);
        }
      }
    }

    return params.join('&');
  }

  const onClickSubmit = () => {
    let shoppingIds;
    let portfolioId;
    let billingGroupId;
    if (['APROVACAO_CORPORATIVA', 'ANALISE_CORPORATIVA'].includes(status)) {
      portfolioId = selectedPortfolios.map((s: Option) => s.value).join(',');
      billingGroupId = selectedGruposCobranca.map((s: Option) => s.value).join(',');
    } else {
      shoppingIds = selectedShoppings.map((s: Option) => s.value).join(',');
    }
    const payload = {
      stepWorkflowCode: status,
      shoppingIds,
      portfolioId,
      billingGroupId,
      cpfCnpj: cpfCnpj.length > 0 ? cpfCnpj : undefined,
      luc: luc.length > 0 ? luc : undefined,
      brand: marca.length > 0 ? marca : undefined,
      idTipoAcao: selectedTipoAcao.map((e: any) => e.value).join(','),
    };
    const queryString = convertPayloadToQueryString(payload);
    submit(queryString);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        if (['APROVACAO_CORPORATIVA', 'ANALISE_CORPORATIVA'].includes(status)) {
          const listPortfolios: Option[] = [];
          const listGrupoCobranca: Option[] = [];
          const [responseGrupoCobranca, responsePortfolios] = await Promise.all([
            api.get('grupo-cobranca/grupo/buscar/listagrupo'),
            api.get('grupo-cobranca/grupo/buscar/listaportifolio'),
          ]);
          responseGrupoCobranca.data.content.forEach((item: any) => {
            listGrupoCobranca.push({
              value: item.idGrupoCobranca,
              label: item.nome,
            });
          });
          responsePortfolios.data.content.forEach((item: string) => {
            listPortfolios.push({
              value: item,
              label: item,
            });
          });
          setPortfolios(listPortfolios);
          setGruposCobranca(listGrupoCobranca);
        } else {
          const shoppingsIds = profileShoppings ? profileShoppings?.map(e => e.id) : [];
          const shoppingsData = await api.get<Shoppping[]>('/charge-legal-information/shoppings');
          const optionsArr = shoppingsData.data.map(s => ({ value: s.shoppingId, label: s.shoppingName }));
          setShoppingOptions(optionsArr);
        }
      } catch (e) {
        handleErrors(e);
      }
    };
    getData();
  }, [status]);

  const isDisabled = useMemo(() => {
    if (['APROVACAO_CORPORATIVA', 'ANALISE_CORPORATIVA'].includes(status)) {
      return false;
    }
    return selectedShoppings.length === 0;
  }, [status, selectedShoppings]);

  const formItems = () => {
    const items: any[] = [];
    if (['APROVACAO_CORPORATIVA', 'ANALISE_CORPORATIVA'].includes(status)) {
      items.push(
        <Select state={[selectedPortfolios, setSelectedPortfolios]} options={portfolios} placeholder="Portfólios" />,
      );
      items.push(
        <Select
          state={[selectedGruposCobranca, setSelectedGruposCobranca]}
          options={gruposCobranca}
          placeholder="Grupos de Cobrança"
        />,
      );
    } else {
      items.push(
        <Select state={[selectedShoppings, setSelectedShoppings]} options={shoppingOptions} placeholder="Shoppings" />,
      );
    }

    return [
      ...items,
      <Input label="Marca" state={[marca, setMarca]} />,
      <Input label="CNPJ/CPF" state={[cpfCnpj, setCpfCnpj]} />,
      <Input label="LUC" state={[luc, setLuc]} />,
      <Input label="Número do boleto" state={[numBoleto, setNumBoleto]} />,
      <Select state={[selectedTipoAcao, setSelectedTipoAcao]} options={tipoAcaoOptions} placeholder="Tipo de ação" />,
    ];
  };

  return (
    <MaterialForm
      items={formItems()}
      submitButton={<Button onClick={onClickSubmit} text="Pesquisar" disabled={isDisabled} />}
    />
  );
}
