import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import {
  DialogContent,
  DateText,
  Title,
  LoadingArea,
  MainContent,
  TextBox,
  Label,
  ModalActions,
  FieldArea,
  TextInput,
  TopContent,
} from './styles';
import LoadingIcon from '../../../../assets/loader.jsx';
import { failure, success } from '../../../../_core/services/toast';
import { Button, SingleSelect, Option } from '../../../../_core/_components';

type Props = {
  isOpen: boolean;
  onCloseDesistencia: Function;
  tipoCancelamento: string;
};

const MOTIVOS = [
  { value: 1, label: 'Erro de Faturamento' },
  { value: 2, label: 'Perdão de Dívida' },
  { value: 3, label: 'Negociação Comercial' },
  { value: 4, label: 'Confissão de Dívida' },
  { value: 5, label: 'Depósito Judicial' },
  { value: 6, label: 'Saldo Insuficiente' },
  { value: 7, label: 'Distrato Cadastrado' },
];

const Desistencia: React.FC<Props> = ({ isOpen, onCloseDesistencia, tipoCancelamento }) => {
  const [isLoading, setLoading] = useState(false);
  const [desc, setDesc] = useState('');
  const [numeroChamado, setNumeroChamado] = useState('');
  const [selectedMotivo, setSelectedMotivo] = useState<Option>();

  const saveAction = async () => {
    setLoading(true);

    if (tipoCancelamento === 'desistencia') {
      try {
        const payload = {
          motivo: desc,
          dataDesistencia: moment().format('YYYY-MM-DD'),
        };

        // await submitDesistencia(ajuizamentoADesistir!.codigoControle, payload);
        setLoading(false);
        success('Salvo com sucesso');
      } catch (e) {
        setLoading(false);
        failure('Erro ao tentar salvar');
      }
    } else {
      if (numeroChamado === '' || selectedMotivo === undefined) {
        failure('Os campos Número do Chamado e Motivo são obrigatórios');
        setLoading(false);
        return;
      }

      const payload = {
        idMotivo: selectedMotivo.value,
        numeroChamado,
        observacao: desc,
      };

      try {
        // await submitExcecao(ajuizamentoADesistir!.codigoControle, payload);
        setLoading(false);
        success('Salvo com sucesso');
      } catch (e) {
        setLoading(false);
        failure('Erro ao tentar salvar');
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={() => onCloseDesistencia()}
      aria-labelledby="max-width-dialog-title"
    >
      {isLoading && (
        <LoadingArea>
          <LoadingIcon />
        </LoadingArea>
      )}

      <DialogContent>
        <Title>{tipoCancelamento === 'desistencia' ? 'Desistência da Ação' : 'Registrar Exceção'}</Title>

        {tipoCancelamento !== 'desistencia' && (
          <TopContent>
            <FieldArea>
              <Label>Número do Chamado</Label>
              <TextInput value={numeroChamado} onChange={e => setNumeroChamado(e.target.value)} />
            </FieldArea>

            <FieldArea>
              <Label>Motivo</Label>
              <SingleSelect state={[selectedMotivo, setSelectedMotivo]} options={MOTIVOS} />
            </FieldArea>
          </TopContent>
        )}

        <MainContent>
          {tipoCancelamento === 'desistencia' && (
            <>
              <Label>Data da Desistência</Label>
              <DateText>{moment().format('DD/MM/YYYY')}</DateText>
            </>
          )}

          <Label>Descrição do motivo</Label>
          <TextBox value={desc} onChange={e => setDesc(e.target.value)} />
        </MainContent>

        <ModalActions>
          <Button onClick={() => saveAction()} text="Salvar" />
        </ModalActions>
      </DialogContent>
    </Dialog>
  );
};

export default Desistencia;
