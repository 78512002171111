import api from '../_core/api';

export const getShoppings = () => api.get('/charge-legal-information/shoppings');

export const getFiltersShoppings = () => api.get(`/filters/siscob-filters-information/shoppings`);

export const getAjuizamentoTotalShoppings = () => api.get('/judgment/total-shopping');

export const getGruposCobranca = () => api.get(`/grupo-cobranca/grupo/buscar/listagrupo`);

export const getAjuizamentoGruposCobranca = () => api.get('/filters/billing-groups');

export const getPortfolios = () => api.get('grupo-cobranca/grupo/buscar/listaportifolio');

export const getJustificativas = () => api.get('/workflow/serasa/serasa-justificativa/buscar');

export const getAjuizamentoJustificativas = () => api.get('/parametros/ajuizamento/justificativa');

export const getStatus = () => api.get('/spredsheet/status');

export const getOrigem = () => api.get('/filters/source');

export const getPerfis = () => api.post('/perfilizacao/pefil/buscar');

export const getTipoAcao = () => api.get('/parametros/ajuizamento/tipo-acao');
