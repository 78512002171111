import styled from 'styled-components/macro';

export const Checkbox = styled.div`
  display: flex;

  /* margin-top: 2rem; */
  cursor: pointer;
  color: #7e9395;
  align-items: center;
`;

export const Mark = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid #7e9395;
  border-radius: 2px;
  margin-right: 1.5rem;

  &.selected {
    background-color: #00959c;
    border: none;
  }
`;

export const Label = styled.div``;
