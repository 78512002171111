import {
  getGruposCobranca,
  getShoppings,
  getPortfolios,
  getJustificativas,
  getAjuizamentoJustificativas,
  getFiltersShoppings,
  getStatus,
  getOrigem,
  getAjuizamentoGruposCobranca,
  getPerfis,
  getTipoAcao,
} from './endpoints';

export type Key =
  | 'getShoppings'
  | 'getGruposCobranca'
  | 'getPortfolios'
  | 'getJustificativas'
  | 'getAjuizamentoJustificativas'
  | 'getFiltersShoppings'
  | 'getStatus'
  | 'getOrigem'
  | 'getPerfis'
  | 'getAjuizamentoGruposCobranca'
  | 'getTipoAcao';

type Fn = () => Promise<any>;

const Requests = (() => {
  let instance: Map<Key, Fn>;

  function createInstance() {
    return new Map<Key, () => Promise<any>>([
      ['getShoppings', getShoppings],
      ['getGruposCobranca', getGruposCobranca],
      ['getPortfolios', getPortfolios],
      ['getJustificativas', getJustificativas],
      ['getAjuizamentoJustificativas', getAjuizamentoJustificativas],
      ['getFiltersShoppings', getFiltersShoppings],
      ['getStatus', getStatus],
      ['getOrigem', getOrigem],
      ['getPerfis', getPerfis],
      ['getAjuizamentoGruposCobranca', getAjuizamentoGruposCobranca],
      ['getTipoAcao', getTipoAcao],
    ]);
  }

  return {
    getInstance: () => {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export const requests = Requests.getInstance();
