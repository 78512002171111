import React, { ReactNode, useContext, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import { Avatar, IconButton, Popover, Hidden } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { AuthContext } from '../contexts/auth';
import logoSiscob from '../../assets/logo_siscob.svg';
import { PermanentDrawer } from './permanent-drawer';
import { PresentationDrawer } from './presentation-drawer';
import PageHeader from '../../_core/_components/page-header';

type Props = {
  children: ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'white',
    },
    avatarContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    avatarInfoContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: '0px 1rem',
      color: 'rgb(126, 147, 149)',
      fontSize: '0.8rem',
    },
    popoverPerfil: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1.5rem 2.5rem',
      fontSize: '0.8rem',
      color: 'rgb(126, 147, 149)',
    },
    popoverPerfilUsername: {
      fontSize: '0.8rem',
      fontWeight: 600,
    },
    popoverPerfilButton: {
      background: 'rgb(126, 147, 149) none no-repeat scroll 0% 0% padding-box',
      borderRadius: '1.5rem',
      opacity: 1,
      border: '0rem none',
      color: 'rgb(255, 255, 255)',
      padding: '0.5rem 1.5rem',
      marginTop: '1rem',
      width: '100%',
    },
    content: {
      flexGrow: 1,
      padding: 0,
    },
    menuButton: {
      marginRight: 36,
      color: '#00959c',
    },
  }),
);

export function Layout({ children }: Props): JSX.Element {
  const { signOut, profile, userName, name, email } = useContext(AuthContext);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={1}>
        <Toolbar>
          <Hidden xsDown>
            <img src={`${logoSiscob}`} alt="" />
          </Hidden>
          <Hidden smUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setIsDrawerOpen(true)}
              edge="start"
              className={classes.menuButton}
            >
              <Menu />
            </IconButton>
          </Hidden>
          <div className={classes.avatarContainer}>
            <div className={classes.avatarInfoContainer}>
              <p style={{ fontWeight: 'bold' }}>{name}</p> <p>{profile}</p>
            </div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={event => setAnchorEl(event.currentTarget)}
              color="inherit"
            >
              <Avatar />
            </IconButton>
            <Popover onClose={() => setAnchorEl(null)} open={open} anchorEl={anchorEl}>
              <div className={classes.popoverPerfil}>
                <Avatar style={{ height: 60, width: 60 }} />
                <span className={classes.popoverPerfilUsername}>{userName}</span>
                <p>{profile}</p>
                <p>{email}</p>
                <button className={classes.popoverPerfilButton} type="button" onClick={signOut}>
                  Sair
                </button>
              </div>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
      <Hidden xsDown>
        <PermanentDrawer />
      </Hidden>
      <Hidden smUp>
        <PresentationDrawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
      </Hidden>
      <main className={classes.content}>
        <Toolbar />
        <PageHeader />
        {children}
      </main>
    </div>
  );
}
