import React, { useMemo, useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { CpfCnpj } from './index';
import { Button, Form, Input, SingleSelect } from '../../../../_core/_components';

type Props = {
  save: (payload: any) => void;
  onClose: () => void;
  selectedRegister?: CpfCnpj | null;
  modules: any[];
};

const Modal: React.FC<Props> = ({ save, onClose, selectedRegister, modules }: Props) => {
  const modulosOptions = useMemo(() => {
    return modules.map(e => ({ value: e.id, label: e.nome }));
  }, [modules]);

  const [cpfCnpj, setCpfCnpj] = useState<string>(selectedRegister?.cpfCnpj ?? '');
  const [luc, setLuc] = useState<string>(selectedRegister?.luc ?? '');
  const [observacao, setObservacao] = useState<string>(selectedRegister?.observacao ?? '');
  const [modulo, setModulo] = useState(selectedRegister?.idModulo);

  const onSave = () => {
    save({
      cpfCnpj,
      luc,
      observacao,
      idModulo: modulo,
      ativo: true,
    });
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar
        </span>
        <Grid>
          <Form
            items={[
              <Input label="CPF/CNPJ" state={[cpfCnpj, setCpfCnpj]} />,
              <Input label="LUC" state={[luc, setLuc]} />,
              <Input label="Observação" state={[observacao, setObservacao]} />,
              <SingleSelect
                placeholder="Módulo"
                state={[modulosOptions.find(e => e.value === modulo), option => setModulo(option.value as number)]}
                options={modulosOptions}
              />,
            ]}
            submitButton={<Button onClick={onSave} text="Salvar" />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
