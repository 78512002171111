import React, { ReactNode } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';

type Props = {
  items?: ReactNode[];
  submitButton?: ReactNode;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'white',
      borderRadius: '1rem',
      padding: '0.6rem',
      margin: 0,
    },
  }),
);

export const Form: React.FC<Props> = ({ items = [], submitButton }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.root}>
      {items.map((item, i) => (
        <Grid item xs={12} sm={4} key={i}>
          {item}
        </Grid>
      ))}
      <Grid item xs={12}>
        {submitButton}
      </Grid>
    </Grid>
  );
};
