import React, { useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { BodyModalContainer, Title, UploadFileRow, UploadLabel, UploadIconImg, ModalTitle, TitleAnexo } from './styles';
import { Recomendation } from './types';
import UpIcon from '../../../assets/uploadIcon.png';
import { verificaTamanhoEmBytes } from '../../../_core/services/permissions';
import { Button, Input, SingleSelect } from '../../../_core/_components';

type ModalProps = {
  onClose: () => void;
  onSubmit: (values: any) => void;
  onClear: () => void;
  onDownloadAnexo: () => void;
  values: Recomendation;
  justificativas: any[];
};

const Modal: React.FC<ModalProps> = ({
  onClose,
  onSubmit,
  onClear,
  onDownloadAnexo,
  values,
  justificativas,
}: ModalProps) => {
  const ref = useRef<any>();
  const [justificativa, setJustificativa] = useState(values.justificativaId);
  const [observacao, setObservacao] = useState(values.observacao ?? '');
  const [nrChamado, setNrChamado] = useState(values.numChamado ?? '');
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileSizeError, setFileSizeError] = useState(false);

  const disabled = useMemo(() => false, []);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const blob = e.target.files ? e.target.files[0] : undefined;
    if (blob === undefined) return;
    if (verificaTamanhoEmBytes(blob!.size)) {
      setFileSizeError(true);
      setFile(undefined);
      return;
    }
    setFileSizeError(false);
    setFile(blob);
  };

  const onClickReset = () => {
    ref.current.value = '';
    setFile(undefined);
  };

  const justificativasOptions = useMemo(() => {
    return justificativas.map(e => ({ ...e, value: e.id, label: e.descricao }));
  }, [justificativas]);

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <ModalTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </ModalTitle>
      <DialogContent>
        <BodyModalContainer>
          <Title>
            <span
              style={{
                color: '#00959c',
                fontSize: '24px',
                fontWeight: 'bold',
              }}
            >
              JUSTIFICATIVA
            </span>
            <ReactTooltip place="bottom" type="dark" effect="float" />
          </Title>
          <SingleSelect
            state={[
              justificativasOptions.find(e => e.value === justificativa),
              option => setJustificativa(option.value as string),
            ]}
            options={justificativasOptions}
            disabled={disabled}
            placeholder="Justificativa"
          />
          <Input label="OBSERVAÇÃO" state={[observacao, setObservacao]} disabled={disabled} />
          <Input label="NR. CHAMADO / NEG CRM" state={[nrChamado, setNrChamado]} disabled={disabled} />
          <Title>Anexo</Title>
          {values.nomeAnexo && (
            <TitleAnexo onClick={onDownloadAnexo}>
              Anexo atual:
              {values.nomeAnexo}
            </TitleAnexo>
          )}
          <UploadLabel htmlFor="selecao-arquivo-input" id="selecao-arquivo-label">
            Selecionar um arquivo
            <UploadIconImg src={UpIcon} />
          </UploadLabel>
          <input id="selecao-arquivo-input" type="file" onChange={onFileChange} ref={ref} hidden disabled={disabled} />
          {fileSizeError && (
            <UploadFileRow>
              <Title>O arquivo não pode exceder 1MB.</Title>
            </UploadFileRow>
          )}
          {file && !fileSizeError && (
            <UploadFileRow>
              <Title>{file?.name}</Title>
              <Title id="remove-span" onClick={onClickReset}>
                x
              </Title>
            </UploadFileRow>
          )}
        </BodyModalContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClear} text="Limpar" disabled={!values.justificativaId} />
        <Button
          disabled={fileSizeError || !justificativa}
          onClick={() => onSubmit({ justificativa, observacao, file, nrChamado })}
          text="Salvar"
        />
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
